import { createContext, useState, useEffect, useContext } from 'react'
import { devLog, getLocalStorage, renderLog, _useEffectLog } from '../util/utilFunctions'
import { UserStatsContextTypes } from './UserMasterStatsContext'
import { UserSettingsContext } from './UserSettingsContext'

// SECTION: Types
export interface UserLsBookStats {
	[bookTitle: string]: {
		[chapterIndex: number]: {
			[pageIndex: number]: { wpm: number; acc: number }
		}
	}
}

// SECTION: Context
export const UserLsStatsContext = createContext<UserStatsContextTypes>({
	userStatsObj: {} as UserLsBookStats,
	loadingDbStats: false,
	userStatsError: '',
	updateUserLsDbStats: (
		bookTitle: string,
		chapterIndex: number,
		pageIndex: number,
		wpm: number,
		acc: number,
		wordsTyped?: number
	) => {},
	fetchUserStatsFromLocalStorage: () => {
		return {} as UserLsBookStats
	},
	saveUserStatsToLocalStorage: () => {},
	getPrevPageWpm: (bookTitle: string, chapterIndex: number, pageIndex: number) => null,
	getCurrentPageWpm: (bookTitle: string, chapterIndex: number, pageIndex: number) => null,
	getPrevPageAcc: (bookTitle: string, chapterIndex: number, pageIndex: number) => null,
	getCurrentPageAcc: (bookTitle: string, chapterIndex: number, pageIndex: number) => null,
	getNumChapterPagesComplete: (bookTitle: string, chapterIndex: number) => 0,
	getChapterWpm: (bookTitle: string, chapterIndex: number) => 0,
	getChapterAcc: (bookTitle: string, chapterIndex: number) => 0,
	setUserStatsObj: (userStatsObj: UserLsBookStats) => null, // Unused outside LS (but used by DB)
	setLoadingDbStats: (mode: boolean) => {},
	// Unused
	getLevel: () => 0,
	getTotalWordsTyped: () => 0,
	clearLocalStatsObj: () => {}
})

// SECTION: Provider
export const UserLsStatsProvider = (props: any) => {
	// SECTION: Hooks
	const { getReadingModeEnabled } = useContext(UserSettingsContext)

	const [userStatsObj, setUserStatsObj] = useState({} as UserLsBookStats)
	const [loadingDbStats, setLoadingDbStats] = useState(false) //NOTE: Used by UserDBStatsContext
	const [userStatsError, setUserStatsError] = useState('')

	// USE EFFECT: Set initial LS userStatsObj
	useEffect(() => {
		_useEffectLog('LS STATS CONTEXT useEffect 1')
		setUserStatsObj(fetchUserStatsFromLocalStorage())
	}, [])

	// SECTION: Local Storage Getters / Setters
	const fetchUserStatsFromLocalStorage = () => {
		let userStats = getLocalStorage()?.getItem('typelit-userstats') //Need to grab them all because object in local storage stored as strings

		if (userStats) {
			userStats = JSON.parse(userStats)
			if (!userStats) return {} as UserLsBookStats // makes typescript happy
			const newUserStats = removePunctuationFromLSKeys(userStats)
			return newUserStats
		} else {
			return {} as UserLsBookStats
		}
	}

	// Book titles shouldn't have punctuation (hyphen and round brackets kept anyway) -- this removes punctuation from LS Stats
	// NOTE: Didn't bother doing this for LS bookmarks or checkpoints
	const removePunctuationFromLSKeys = (userStats: any) => {
		const newUserStats = userStats
		const punctuation = ['.', "'", ':']
		for (let [key, value] of Object.entries(newUserStats)) {
			punctuation.map((punc) => {
				if (key.includes(punc)) {
					newUserStats[key.split(punc).join('')] = newUserStats[key]
					delete newUserStats[key]
				}
			})
		}
		return newUserStats
	}

	// SECTION: Local Obj Getters / Setters
	const updateUserLsDbStats = (
		bookTitle: string,
		chapterIndex: number,
		pageIndex: number,
		wpm: number,
		acc: number,
		wordsTyped?: number, // Optional args only used in db version of this context
		maxPageIndex?: number,
		updateTotalWordsPagesTyped?: boolean
	) => {
		// Creates a bookTitle key if none exists (chaining non-existing properties to create them all doesn't work)
		if (!userStatsObj[bookTitle]) {
			userStatsObj[bookTitle] = {}
		}

		// Creates a chapterIndex key if none exists
		if (!userStatsObj[bookTitle][chapterIndex]) {
			userStatsObj[bookTitle][chapterIndex] = {}
		}

		userStatsObj[bookTitle][chapterIndex][pageIndex] = { wpm, acc }
		saveUserStatsToLocalStorage()
	}

	const saveUserStatsToLocalStorage = () => {
		if (!getReadingModeEnabled()) {
			getLocalStorage()?.setItem(`typelit-userstats`, JSON.stringify(userStatsObj))
		}
	}

	const getPrevPageWpm = (bookTitle: string, chapterIndex: number, pageIndex: number) => {
		return userStatsObj?.[bookTitle]?.[chapterIndex]?.[pageIndex - 1]?.wpm
	}

	const getCurrentPageWpm = (bookTitle: string, chapterIndex: number, pageIndex: number) => {
		return userStatsObj?.[bookTitle]?.[chapterIndex]?.[pageIndex]?.wpm
	}

	const getPrevPageAcc = (bookTitle: string, chapterIndex: number, pageIndex: number) => {
		return userStatsObj?.[bookTitle]?.[chapterIndex]?.[pageIndex - 1]?.acc
	}

	const getCurrentPageAcc = (bookTitle: string, chapterIndex: number, pageIndex: number) => {
		return userStatsObj?.[bookTitle]?.[chapterIndex]?.[pageIndex]?.acc
	}

	const getNumChapterPagesComplete = (bookTitle: string, chapterIndex: number) => {
		return userStatsObj?.[bookTitle]?.[chapterIndex] ? Object.keys(userStatsObj?.[bookTitle]?.[chapterIndex]).length : 0
	}

	const getChapterWpm = (bookTitle: string, chapterIndex: number) => {
		const chapterStats = userStatsObj?.[bookTitle]?.[chapterIndex]
		const wpmArr = chapterStats
			? Object.keys(chapterStats).map((pageIndex: any, statObj) => {
					return chapterStats[pageIndex].wpm
			  })
			: [0]
		return Math.round(wpmArr.reduce((a, b) => a + b, 0) / wpmArr.length)
	}

	const getChapterAcc = (bookTitle: string, chapterIndex: number) => {
		const chapterStats = userStatsObj?.[bookTitle]?.[chapterIndex]
		const accArr = chapterStats
			? Object.keys(chapterStats).map((pageIndex: any, statObj) => {
					return chapterStats[pageIndex].acc
			  })
			: [0]
		return Math.round((accArr.reduce((a, b) => a + b, 0) / accArr.length) * 10) / 10
	}

	// SECTION: Unused
	// Keep since this and UserLsStatsContext need the same interface as UserDbBookStats
	const getLevel = () => 0

	const getTotalWordsTyped = () => 0

	const clearLocalStatsObj = () => {}

	// NOTE: Don't put useMemo here or it will inexplicitly break the ability to navigate between pages + puts key tracking a render behind KeystrokeTrackerContext
	return (
		<UserLsStatsContext.Provider
			value={{
				userStatsObj,
				loadingDbStats,
				userStatsError,
				updateUserLsDbStats,
				getPrevPageWpm,
				getCurrentPageWpm,
				getPrevPageAcc,
				getCurrentPageAcc,
				getNumChapterPagesComplete,
				getChapterWpm,
				getChapterAcc,
				getLevel,
				getTotalWordsTyped,
				clearLocalStatsObj,
				setUserStatsObj,
				setLoadingDbStats,
				fetchUserStatsFromLocalStorage,
				saveUserStatsToLocalStorage
			}}>
			{props.children}
			{renderLog('USER LS STATS CONTEXT')}
		</UserLsStatsContext.Provider>
	)
}
