// SECTION: Premium Palette Vars
// Timber
export const theme003 = {
	consoleMain: '#606c38',
	untyped: '#283618',
	typed: '#fefae0',
	cursor: '#dda15e',
	menuMain: '#BC6C25',
	menuText: '#fefae0'
}

// Smoothie
export const theme004 = {
	consoleMain: '#2A9D8F',
	untyped: '#264653',
	typed: '#E9C46A',
	cursor: '#E76F51',
	menuMain: '#F4A261',
	menuText: '#FDEEE2'
}

// NOTE: For low contrast themes, you'll need to customize manually. Use the contast checker and luminescence slider in coolers.co for this
// Bubblegum
export const theme005 = {
	consoleMain: '#FFC8DD',
	untyped: '#A77CC0',
	typed: '#DA0B57',
	cursor: '#80C1FF',
	menuMain: '#BDE0FE',
	menuText: '#036FCE'
}

// Cinder
export const theme006 = {
	consoleMain: '#03071E',
	untyped: '#84030c',
	typed: '#d00000',
	menuMain: '#370617',
	cursor: '#F48C06',
	menuText: '#fefae0'
}

// Ocean
export const theme007 = {
	consoleMain: '#03045E',
	untyped: '#0096c7',
	typed: '#48cae4',
	cursor: '#ade8f4',
	menuMain: '#90E0EF',
	menuText: '#023E8A'
}

// Bumblebee
export const theme008 = {
	consoleMain: '#FCA311',
	untyped: '#14213D',
	typed: '#050505',
	cursor: '#ffde70',
	menuMain: '#050505',
	menuText: '#FCA311'
}

// Pulse
export const theme009 = {
	consoleMain: '#8338EC',
	untyped: '#ffbe0baa',
	typed: '#ffbe0b',
	cursor: '#3A86FF',
	menuMain: '#ffbe0b',
	menuText: '#FF006E'
}

// Lagoon
export const theme010 = {
	consoleMain: '#3A0CA3',
	untyped: '#f72585aa',
	typed: '#f72585',
	cursor: '#7209b7',
	menuMain: '#3F37C9',
	menuText: '#4CC9F0'
}

// Mellow
export const theme011 = {
	consoleMain: '#CB997E',
	untyped: '#FFF1E6aa',
	typed: '#FFF1E6',
	cursor: '#B7B7A4',
	menuMain: '#B7B7A4',
	menuText: '#F0EFEB'
}

// Surf
export const theme012 = {
	consoleMain: '#72EFDD',
	untyped: '#5390d9',
	typed: '#7400b8',
	cursor: '#56cfe1',
	menuMain: '#7400B8',
	menuText: '#80FFDB'
}

// Newsprint
export const theme013 = {
	consoleMain: '#CED4DA',
	untyped: '#6c757d',
	typed: '#212529',
	cursor: '#212529',
	menuMain: '#343A40',
	menuText: '#E9ECEF'
}

// Beachside
export const theme014 = {
	consoleMain: '#ECE4DB',
	untyped: '#ff523377',
	typed: '#ff9d47',
	cursor: '#d8e2dc',
	menuMain: '#e8e8e4',
	menuText: '#696E6B'
}

// Terracotta
export const theme015 = {
	consoleMain: '#E5989B',
	untyped: '#6d6875aa',
	typed: '#6d6875',
	cursor: '#ffb4a2',
	menuMain: '#6d6875',
	menuText: '#ffcdb2'
}

// Canopy
export const theme016 = {
	consoleMain: '#081C15',
	untyped: '#40916c',
	typed: '#95d5b2',
	cursor: '#b7e4c7',
	menuMain: '#1B4332',
	menuText: '#B7E4C7'
}

// Platoon
export const theme017 = {
	consoleMain: '#333D29',
	untyped: '#8a6846',
	typed: '#b6ad90',
	cursor: '#a4ac86',
	menuMain: '#414833',
	menuText: '#C2C5AA'
}

// export const templateDocs = {
// 	consoleMain: '#', // main background
// 	untyped: '#', // untyped text / console nav arrows
// 	typed: '#', // typed text / Breadcrumbs / icon hover
// 	inactiveBreadcrumbs: '#', // Breadcrumbs stats when nothing typed (on coolers -- 20 points of brightness more/less than typed OR use transparant version of typed)
// 	cursor: '#', // Cursor color in cursor select
// 	menuMain: '#', // Menu background / Alt main background (used for popups instead of mainBackground (non-classic themes)))
// 	menuText: '#', // Menu text / Switches / Info Button, hover / Scrollbar thumb
// 	menuTextDark: '#', // Info Button, no hover / Scrollbar track (can be transparant version of menuText)
// 	menuBrightTransparant: '#', // Curser select button selected / dropdown hover
// }

// export const template = {
// 	consoleMain: '#',
// 	untyped: '#',
// 	typed: '#',
// 	cursor: '#',
// 	menuMain: '#',
// 	menuText: '#',
// }

// SECTION: Premium Palette Template
export const assembleConsolePalette = ({ consoleMain, untyped, typed, menuMain, cursor, menuText }: any) => {
	return {
		mainBackgroundColor: consoleMain,
		text1: menuText,
		consoleNavArrows: untyped, // Should be same as untyped text
		iconHover0: typed, // Should be same as text1 (althought that's not the case for dark and light themes)

		// Menu Colors - maybe stick to light/dark for this
		border2: '#00000000', // used in some popups -- only use on super dark themes
		link1: menuText, // Info button, hover
		link3: `${menuText}aa`,
		background4: menuMain,
		altMainBackgroundColor: menuMain,
		switch1: menuText,
		//
		charUntyped: untyped,
		charTyped: typed,
		charActiveBoxText: untyped,
		charActiveBoxBg: cursor,
		charActiveLineText: untyped,
		charActiveLineBoxShadow: cursor,
		cardRipple: cursor,
		buttonSelected0: '#fefae033',
		///
		dropdownBackground2: menuMain,
		dropdownHover2: `${menuText}11`,
		//
		background3: menuMain, // Signin and error alerts
		border11: '#00000000', // login success alert border
		//
		loadingOverlayText: typed,
		text5: typed, // Breadcrumbs
		text2: `${typed}bb`, // breadcrumbs untyped
		//
		readingModeText: typed,
		//
		scrollbarThumb: menuText,
		scrollbarTrack: `${menuText}aa`
	}
}
