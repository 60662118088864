import { useSession } from 'next-auth/react'
import { createContext, useContext, useEffect, useState } from 'react'
import { devLog, getLocalStorage, renderLog, _useEffectLog } from '../util/utilFunctions'
import { UtilContext } from './UtilContext'
import { UserSettingsContext } from './UserSettingsContext'
import { CssVarName, JsVarName } from '../components/ConsolePage/consoleSettings/ThemeEditor'
import * as premiumConsoleThemes from '../util/palette/premiumConsoleThemes'
import { ConsoleTheme, ThemeMenuSection, getThemeProps } from '../util/palette/premiumConsoleThemesMapsAndTypes'
import premiumThemePalettes from '../util/palette/charObjThemeStyles/premiumThemePalette.module.css'

// SECTION: Types
interface CustomThemesObj {
	[themeName: string]: {
		consoleMain?: string
		untyped?: string
		typed?: string
		cursor?: string
		error?: string
		menuMain?: string
		menuText?: string
	}
}

export interface InitialColorsObj {
	[themeName: string]: {
		initialConsoleMain?: string
		initialUntypedColor?: string
		initialTypedColor?: string
		initialCursorColor?: string
		initialErrorColor?: string
		initialMenuMain?: string
		initialMenuText?: string
	}
}

// SECTION: Context
export const UserThemesAndFontsContext = createContext({
	customThemesObj: {},
	initialColorsObj: {},
	themeMenuScrollPositionObj: { position: 0 },
	fontMenuScrollPositionObj: { position: 0 },
	updateAndOrApplyEditedCssThemeClasses: (
		newCustomThemesObj: CustomThemesObj,
		updateCssVarName?: CssVarName,
		updateStyleValue?: string
	) => {},
	updateAndApplyEditedJsThemeRule: (property: JsVarName, value?: string) => {},
	updateCustomThemePropertyInStateAndLocalStorage: (property: JsVarName, value: string) => {},
	removeCustomThemePropertyFromStateAndLocalStorage: (property: JsVarName) => {},
	removeAllCustomThemesFromStateAndLocalStorage: () => {},
	getCustomStyleValueOfCurrentTheme: (varName: JsVarName): string | undefined => '',
	getCustomStyleValueOfSelectTheme: (themeName: ConsoleTheme, varName: JsVarName): string | undefined => '',
	getPremiumStyleDefaultLabel: (consoleTheme: ConsoleTheme): string | undefined => ''
})

// SECTION: Provider
export const UserThemesAndFontsProvider = (props: any) => {
	// SECTION: Hooks
	const [customThemesObj, setCustomThemesObj] = useState({} as CustomThemesObj)
	const [initialColorsObj, setInitialColors] = useState({} as InitialColorsObj)
	const [themeMenuScrollPositionObj] = useState({ position: 0 }) // obj so that scrolling doesn't cause rerender
	const [fontMenuScrollPositionObj] = useState({ position: 0 })

	const { userId } = useContext(UtilContext)
	const { userSettingsObj, getConsoleTheme, reRender } = useContext(UserSettingsContext)

	const { data: session, status } = useSession()

	// USE EFFECT: Initial load from local storage + apply custom js and css styles
	useEffect(() => {
		_useEffectLog('USER THEMES AND FONTS CONTEXT useEffect 1')

		if (userId) {
			const newCustomThemesObj = fetchCustomThemesFromLocalStorage()
			setCustomThemesObj(newCustomThemesObj)

			// If no custom themes empty, initialize local storage key with empty object
			if (Object.keys(newCustomThemesObj).length === 0) {
				setAndSaveCustomThemesToStateAndLocalStorage(newCustomThemesObj)
			}

			// Apply custom theme css styles
			if (newCustomThemesObj[getConsoleTheme()]) {
				applyEditedJsThemeClasses(newCustomThemesObj)
				updateAndOrApplyEditedCssThemeClasses(newCustomThemesObj)
			}
		}
	}, [session, userId])

	// USE EFFECT: load initial colors
	useEffect(() => {
		_useEffectLog('USER THEMES AND FONTS CONTEXT useEffect 2')

		if (Object.keys(initialColorsObj).length === 0 && userSettingsObj.consoleTheme) {
			initInitialColors()
		}
	}, [userSettingsObj.consoleTheme])

	// HELPERS
	// NOTE: This should only run once, otherwise it may overwrite the initial colors if user customized theme
	const initInitialColors = () => {
		if (!getConsoleTheme()) return

		const newDefaultColorsObj = {} as InitialColorsObj

		Object.entries(premiumThemePalettes).forEach(([themeName, themeProperties]) => {
			// CSS only colors
			const element = document.createElement('div')
			element.classList.add(themeProperties)
			document.body.appendChild(element)
			const computedStyle = window.getComputedStyle(element)
			const initialUntypedColor = computedStyle.getPropertyValue(CssVarName.UNTYPED)
			const initialTypedColor = computedStyle.getPropertyValue(CssVarName.TYPED)
			const initialCursorColor = computedStyle.getPropertyValue(CssVarName.CURSOR)
			const initialErrorColor = computedStyle.getPropertyValue(CssVarName.ERROR)
			document.body.removeChild(element)

			// Remaining JS colors
			// NOTE: Deep copy to remove reference to orig file and avoid mutating original theme object
			// NOTE: ie, with stuff like: premiumConsoleThemes[getConsoleTheme()][property] = value
			const themeDeepCopy = JSON.parse(JSON.stringify(premiumConsoleThemes[themeName]))
			const { consoleMain, menuMain, menuText } = themeDeepCopy

			newDefaultColorsObj[themeName] = {
				initialConsoleMain: consoleMain,
				initialUntypedColor,
				initialTypedColor,
				initialCursorColor,
				initialErrorColor,
				initialMenuMain: menuMain,
				initialMenuText: menuText
			}
		})

		setInitialColors(newDefaultColorsObj)
	}

	// USE EFFECT: Apply js and css styles on theme change
	useEffect(() => {
		_useEffectLog('USER THEMES AND FONTS CONTEXT useEffect 3')

		// Remove style element from head on theme change (clears edits)
		const styleElement = document.querySelector('#edited-theme-class') as HTMLStyleElement
		if (styleElement) {
			styleElement.remove()
		}

		applyEditedJsThemeClasses(customThemesObj)
		updateAndOrApplyEditedCssThemeClasses(customThemesObj)
	}, [getConsoleTheme()])

	// SECTION: Style Appliers and Updaters
	const applyEditedJsThemeClasses = (customThemesObj: CustomThemesObj) => {
		if (customThemesObj[getConsoleTheme()]) {
			Object.entries(customThemesObj[getConsoleTheme()]).forEach(([key, value]) => {
				premiumConsoleThemes[getConsoleTheme()][key] = value
			})

			reRender()
		}
	}

	const updateAndApplyEditedJsThemeRule = (property: JsVarName, value?: string) => {
		if (value) {
			premiumConsoleThemes[getConsoleTheme()][property] = value
			reRender()
		}
	}

	const updateAndOrApplyEditedCssThemeClasses = (
		_customThemesObj: CustomThemesObj,
		updateCssVarName?: CssVarName,
		updateStyleValue?: string
	) => {
		// Query <style> element in head with edited theme class
		let style = document.querySelector('#edited-theme-class') as HTMLStyleElement

		// If no style element in head, create and append with edited theme class
		if (!style) {
			style = document.createElement('style')
			style.id = 'edited-theme-class'
			document.head.appendChild(style)
		}

		// Create css class object with old values + new values if passed in
		if (!_customThemesObj[getConsoleTheme()]) return

		const { untyped, typed, cursor, error } = _customThemesObj[getConsoleTheme()]

		const cssClassObj = {
			'--untyped': untyped,
			'--typed': typed,
			'--cursor': cursor,
			'--error': error,
			...(updateCssVarName && updateStyleValue ? { [updateCssVarName]: updateStyleValue } : {})
		}

		// Remove undefined values and make values valid css
		for (let [key, value] of Object.entries(cssClassObj)) {
			if (value === undefined) {
				delete cssClassObj[key]
			} else {
				cssClassObj[key] = `${value};`
			}
		}

		// Update style class in the head
		style.innerHTML = `
			.editedThemeClass ${JSON.stringify(cssClassObj).replace(/['"\,]+/g, '')}
		`
	}

	// SECTION: Local Storage Getters
	const fetchCustomThemesFromLocalStorage = () => {
		const customThemes = getLocalStorage()?.getItem(`typelit-usercustomthemes-${userId}`)

		// If no custom themes in local storage, return empty object
		return customThemes && Object.keys(JSON.parse(customThemes)).length > 0
			? (JSON.parse(customThemes) as CustomThemesObj)
			: ({} as CustomThemesObj)
	}

	// SECTION: Local Storage and State Setters

	const setAndSaveCustomThemesToStateAndLocalStorage = (newCustomThemesObj: CustomThemesObj) => {
		if (userId) {
			setCustomThemesObj(newCustomThemesObj)
			getLocalStorage()?.setItem(`typelit-usercustomthemes-${userId}`, JSON.stringify(newCustomThemesObj))
		}
	}

	const updateCustomThemePropertyInStateAndLocalStorage = (property: JsVarName, value: string) => {
		setAndSaveCustomThemesToStateAndLocalStorage({
			...customThemesObj,
			[getConsoleTheme()]: {
				...customThemesObj?.[getConsoleTheme()],
				[property]: value
			}
		})
	}

	const removeCustomThemePropertyFromStateAndLocalStorage = (property: JsVarName) => {
		if (customThemesObj?.[getConsoleTheme()]?.[property]) {
			delete customThemesObj[getConsoleTheme()][property]

			if (Object.keys(customThemesObj[getConsoleTheme()]).length === 0) {
				delete customThemesObj[getConsoleTheme()]
			}

			setAndSaveCustomThemesToStateAndLocalStorage(customThemesObj)
		}
	}

	const removeAllCustomThemesFromStateAndLocalStorage = () => {
		setAndSaveCustomThemesToStateAndLocalStorage({})
		window.location.reload()
	}

	// SECTION: State Getters
	const getCustomStyleValueOfCurrentTheme = (varName: JsVarName) => {
		return customThemesObj?.[getConsoleTheme()]?.[varName]
	}

	const getCustomStyleValueOfSelectTheme = (themeName: ConsoleTheme, varName: JsVarName) => {
		return customThemesObj?.[themeName]?.[varName]
	}

	// SECTION: Default Value Getters
	const getPremiumStyleDefaultLabel = (consoleTheme: ConsoleTheme) => {
		return getThemeProps(ThemeMenuSection.PREMIUM_THEMES).find((themeMenuPropsObj) => {
			return consoleTheme === themeMenuPropsObj.theme
		})?.defaultLabel
	}

	return (
		<UserThemesAndFontsContext.Provider
			value={{
				customThemesObj,
				initialColorsObj,
				themeMenuScrollPositionObj,
				fontMenuScrollPositionObj,
				updateAndApplyEditedJsThemeRule,
				updateAndOrApplyEditedCssThemeClasses,
				updateCustomThemePropertyInStateAndLocalStorage,
				removeCustomThemePropertyFromStateAndLocalStorage,
				removeAllCustomThemesFromStateAndLocalStorage,
				getCustomStyleValueOfCurrentTheme,
				getCustomStyleValueOfSelectTheme,
				getPremiumStyleDefaultLabel
			}}>
			{props.children}
			{renderLog('CUSTOM THEMES CONTEXT')}
		</UserThemesAndFontsContext.Provider>
	)
}
