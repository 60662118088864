import getUserLocale from 'get-user-locale'
import { useEffect, useState, createContext } from 'react'
import { BookDisplayTab } from '../components/BookSelectPage/BooksDisplayTabs'
import { gdprCountryCodes, getUserCountryCode } from '../util/countryCodes'
import { devLog, renderLog, _useEffectLog, windowExists, getLocalStorage } from '../util/utilFunctions'

// SECTION: Types
interface UserLayoutStateObj {
	activeBookDisplayTab: BookDisplayTab
	showCookiesBanner: boolean
}

// SECTION: Context
export const UserLayoutStateContext = createContext({
	userLayoutStateObj: {} as UserLayoutStateObj,
	getActiveBookDisplayTab: (): any => {},
	setActiveBookDisplayTab: (activeBookDisplayTab: BookDisplayTab) => {},
	getShowCookiesBanner: (): any => {},
	setShowCookiesBanner: (mode: boolean) => {},
	getAllowAnalytics: (): any => {},
	setAllowAnalytics: (mode: 'true' | 'false') => {}
})

// SECTION: Provider
export const UserLayoutStateProvider = (props: any) => {
	// SECTION: Hooks
	const [userLayoutStateObj, setUserLayoutStateObj] = useState({} as UserLayoutStateObj)

	const defaultUserLayoutStateObj = {
		activeBookDisplayTab: BookDisplayTab.CLASSICS, // Only ever set to CUSTOM if user has a session
		showCookiesBanner: false
	}

	// USE EFFECT: Loads layout state from local storage or saves layout state to local storage whenever they change
	useEffect(() => {
		_useEffectLog('USER LAYOUT STATE CONTEXT useEffect 1')
		if (Object.keys(userLayoutStateObj).length === 0) {
			loadUserLayoutState()
		} else {
			saveUserLayoutStateToLocalStorage() // updates local storage whenever UserLayoutStateObj changes
		}
	})

	// HELPERS
	// Sets UserLayoutStateObj to that in local storage + adds any missing keys, setting vals to default
	const loadUserLayoutState = () => {
		const newUserLayoutStateObj = fetchUserLayoutStateFromLocalStorage()
		if (Object.keys(newUserLayoutStateObj).length > 0) {
			// If layout state obj already exists this sets any non-existing keys to their default val
			for (const [key, layoutState] of Object.entries(defaultUserLayoutStateObj)) {
				if (!(key in newUserLayoutStateObj)) {
					newUserLayoutStateObj[key] = layoutState
				}
			}
			setUserLayoutStateObj(newUserLayoutStateObj)
		} else {
			// sets a new layout state obj with default values for all keys
			setUserLayoutStateObj(defaultUserLayoutStateObj)
		}
		// saves the new obj
		saveUserLayoutStateToLocalStorage()
	}

	// USE EFFECT: Validates allowAnalytics and cookieBanner localStorage entries
	useEffect(() => {
		_useEffectLog('USER LAYOUT STATE CONTEXT useEffect 2')
		if (!windowExists()) return

		// Only do this if all keys are loaded (or may overwrite and remove keys unintentionally)
		if (Object.keys(userLayoutStateObj).length === Object.keys(defaultUserLayoutStateObj).length) {
			let allowAnalytics = getAllowAnalytics()

			// if allow Analytics key doesn't exist, default it to true until user disables it in cookiesBanner
			// NOTE: If user refreshes page without pressing a banner button, it wont show next time
			if (allowAnalytics === null) {
				setAllowAnalytics('true')

				const userCountryCode = getUserCountryCode()
				if (!userCountryCode || gdprCountryCodes.includes(userCountryCode)) {
					setShowCookiesBanner(true)
				}
			}
		}
	}, [userLayoutStateObj])

	// SECTION: local Storage Getters/Stetters
	const saveUserLayoutStateToLocalStorage = () => {
		getLocalStorage()?.setItem(`typelit-userlayoutstate`, JSON.stringify(userLayoutStateObj))
	}

	const fetchUserLayoutStateFromLocalStorage = () => {
		const userLayoutStates = getLocalStorage()?.getItem('typelit-userlayoutstate')
		return userLayoutStates ? JSON.parse(userLayoutStates) : ({} as UserLayoutStateObj)
	}

	// SECTION: Local Obj Getters/Setters
	const getActiveBookDisplayTab = () => {
		return userLayoutStateObj.activeBookDisplayTab
	}

	const setActiveBookDisplayTab = (activeBookDisplayTab: BookDisplayTab) => {
		setUserLayoutStateObj({ ...userLayoutStateObj, activeBookDisplayTab })
	}

	const getShowCookiesBanner = () => {
		return userLayoutStateObj.showCookiesBanner
	}

	const setShowCookiesBanner = (mode: boolean) => {
		setUserLayoutStateObj({ ...userLayoutStateObj, showCookiesBanner: mode })
	}

	// SECTION: Misc
	// SUBSECTION: allowAnalytics localStorage access
	const getAllowAnalytics = () => {
		if (!windowExists()) return undefined // QUIRK: not null because null may be returned by below function, and used by calling function

		return getLocalStorage()?.getItem(`typelit-allowanalytics`)
	}

	// QUIRK: Always string because this entry is on it's own and not part of userLayoutStateObj
	const setAllowAnalytics = (mode: 'true' | 'false') => {
		getLocalStorage()?.setItem(`typelit-allowanalytics`, mode)
	}

	// SECTION: Render Context Provider
	return (
		<UserLayoutStateContext.Provider
			value={{
				userLayoutStateObj,
				getActiveBookDisplayTab,
				setActiveBookDisplayTab,
				getShowCookiesBanner,
				setShowCookiesBanner,
				getAllowAnalytics,
				setAllowAnalytics
			}}>
			{props.children}
			{renderLog('USER LAYOUT STATE CONTEXT')}
		</UserLayoutStateContext.Provider>
	)
}
