import { createContext, useState } from 'react'
import { devLog, renderLog } from '../util/utilFunctions'
import axios from 'axios'
import { useSession } from 'next-auth/react'
import { handleErrorOnFrontend } from '../util/errorHandlers'
import { UserStatsContextTypes } from './UserMasterStatsContext'

// SECTION: Types
// NOTE: This is the same as the one in userStatsModel -- that one extends mongoose.Document
export interface UserDbBookStats {
	email: string
	level: number
	rank: string
	totalWordsTyped: number
	totalPagesTyped: number
	totalWpm: number
	totalAcc: number
	totalBooksTyped: number
	bookWpm: {
		[bookTitle: string]: number
	}
	bookAcc: {
		[bookTitle: string]: number
	}
	bookPagesTyped: {
		[bookTitle: string]: number
	}
	booksCompleted: {
		[bookTitle: string]: boolean
	}
	chapterWpm: {
		[bookTitle: string]: {
			[chapterIndex: number]: number
		}
	}
	chapterAcc: {
		[bookTitle: string]: {
			[chapterIndex: number]: number
		}
	}
	chapterPagesTyped: {
		[bookTitle: string]: {
			[chapterIndex: number]: number
		}
	}
	pages: {
		[bookTitle: string]: {
			[chapterIndex: number]: {
				[pageIndex: number]: {
					wpm: number
					acc: number
				}
			}
		}
	}
}

export const userDbStatsContextDefaults = {
	userStatsObj: {} as UserDbBookStats,
	loadingDbStats: false,
	userStatsError: '',
	updateUserLsDbStats: (
		bookTitle: string,
		chapterIndex: number,
		pageIndex: number,
		wpm: number,
		acc: number,
		wordsTyped: number,
		maxPageIndex: number,
		updateTotalWordsPagesTyped: boolean
	) => {},
	getLevel: () => 0,
	getTotalWordsTyped: () => 0,
	getPrevPageWpm: (bookTitle: string, chapterIndex: number, pageIndex: number) => null,
	getCurrentPageWpm: (bookTitle: string, chapterIndex: number, pageIndex: number) => null,
	getPrevPageAcc: (bookTitle: string, chapterIndex: number, pageIndex: number) => null,
	getCurrentPageAcc: (bookTitle: string, chapterIndex: number, pageIndex: number) => null,
	getNumChapterPagesComplete: (bookTitle: string, chapterIndex: number) => 0,
	getChapterWpm: (bookTitle: string, chapterIndex: number) => 0,
	getChapterAcc: (bookTitle: string, chapterIndex: number) => 0,
	clearLocalStatsObj: () => {},
	setUserStatsObj: (userStatsObj: UserDbBookStats) => {},
	setLoadingDbStats: (mode: boolean) => {},
	// Unused
	fetchUserStatsFromLocalStorage: () => {},
	saveUserStatsToLocalStorage: () => {}
}

// SECTION: Context
export const UserDbStatsContext = createContext<UserStatsContextTypes>(userDbStatsContextDefaults)

// SECTION: Provider
export const UserDbStatsProvider = (props: any) => {
	// SECTION: Hooks
	const [userStatsObj, setUserStatsObj] = useState({} as UserDbBookStats)
	const [loadingDbStats, setLoadingDbStats] = useState(false)
	const [userStatsError, setUserStatsError] = useState('')

	const { data: session, status } = useSession()

	// SECTION: Local Obj Setter
	const updateUserLsDbStats = async (
		bookTitle: string,
		chapterIndex: number,
		pageIndex: number,
		wpm: number,
		acc: number,
		wordsTyped: number,
		maxPageIndex: number,
		updateTotalWordsPagesTyped: boolean
	) => {
		if (session) {
			setLoadingDbStats(true)
			try {
				const newUserDbStatsObjResult = await axios.put('/api/userDbStats/updateUserStatsOnPageComplete', {
					bookTitle,
					chapterIndex,
					pageIndex,
					maxPageIndex,
					wpm,
					acc,
					wordsTyped,
					updateTotalWordsPagesTyped
				})
				// devLog('SAVING: ' + JSON.stringify(newUserDbStatsObjResult.data))
				setUserStatsObj(newUserDbStatsObjResult.data)
			} catch (err) {
				handleErrorOnFrontend(err, 'Something went wrong. Page progress could not be saved.', setUserStatsError)
			}
			setLoadingDbStats(false)
		}
	}

	// SECTION: Local Obj Getters (DB context only)
	const getLevel = () => {
		return userStatsObj.level
	}

	const getTotalWordsTyped = () => {
		return userStatsObj.totalWordsTyped
	}

	// SECTION: Local Obj Getters (Shared with LS context)
	const getPrevPageWpm = (bookTitle: string, chapterIndex: number, pageIndex: number) => {
		return userStatsObj?.pages?.[bookTitle]?.[chapterIndex]?.[pageIndex - 1]?.wpm
	}

	const getCurrentPageWpm = (bookTitle: string, chapterIndex: number, pageIndex: number) => {
		return userStatsObj?.pages?.[bookTitle]?.[chapterIndex]?.[pageIndex]?.wpm
	}

	const getPrevPageAcc = (bookTitle: string, chapterIndex: number, pageIndex: number) => {
		return userStatsObj?.pages?.[bookTitle]?.[chapterIndex]?.[pageIndex - 1]?.acc
	}

	const getCurrentPageAcc = (bookTitle: string, chapterIndex: number, pageIndex: number) => {
		return userStatsObj?.pages?.[bookTitle]?.[chapterIndex]?.[pageIndex]?.acc
	}

	const getNumChapterPagesComplete = (bookTitle: string, chapterIndex: number) => {
		return userStatsObj?.chapterPagesTyped?.[bookTitle]?.[chapterIndex]
			? userStatsObj.chapterPagesTyped?.[bookTitle]?.[chapterIndex]
			: 0
	}

	const getChapterWpm = (bookTitle: string, chapterIndex: number) => {
		return userStatsObj?.chapterWpm?.[bookTitle]?.[chapterIndex]
			? userStatsObj?.chapterWpm?.[bookTitle]?.[chapterIndex]
			: 0
	}

	const getChapterAcc = (bookTitle: string, chapterIndex: number) => {
		return userStatsObj?.chapterAcc?.[bookTitle]?.[chapterIndex]
			? userStatsObj?.chapterAcc?.[bookTitle]?.[chapterIndex]
			: 0
	}

	const clearLocalStatsObj = () => {
		setUserStatsObj({} as UserDbBookStats)
	}

	// SECTION: Unused
	// Keep since this and UserDbStatsContext need the same interface as UserDbBookStats
	const fetchUserStatsFromLocalStorage = () => {}

	const saveUserStatsToLocalStorage = () => {}

	// NOTE: Don't put useMemo here or it will inexplicitly break the ability to navigate between pages + puts key tracking a render behind KeystrokeTrackerContext
	return (
		<UserDbStatsContext.Provider
			value={{
				userStatsObj,
				loadingDbStats,
				userStatsError,
				updateUserLsDbStats,
				getPrevPageWpm,
				getCurrentPageWpm,
				getPrevPageAcc,
				getCurrentPageAcc,
				getNumChapterPagesComplete,
				getChapterWpm,
				getChapterAcc,
				getLevel,
				getTotalWordsTyped,
				clearLocalStatsObj,
				setUserStatsObj,
				setLoadingDbStats,
				fetchUserStatsFromLocalStorage,
				saveUserStatsToLocalStorage
			}}>
			{props.children}
			{renderLog('USER DB STATS CONTEXT')}
		</UserDbStatsContext.Provider>
	)
}
