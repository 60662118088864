import getUserLocale from 'get-user-locale'
// NOTE: npm package get-user-local uses the browser (and possibly other ie, system, etc) language to deterimine locale -- test by changing language in Chrome.
// NOTE: code sources: https://www.andiamo.co.uk/resources/iso-language-codes/

// SECTION: Shared country code functions
export const getUserCountryCode = () => {
	const userLocale = getUserLocale()
	return userLocale?.split('-')?.[1]
}

// SECTION: Amazon and Ebay Affiliate Codes & Maps
// QUIRK: Affiliate links not currently used -- keep commented-out in case you want to support them in the future
// NOTE: Currently only supported countries are below: https://affiliate-program.amazon.com/resource-center/receive-your-international-affiliate-earnings-in-your-local-bank/
// NOTE: Ebay might allow other EU countries but unconfirmed -- don't bother. Just use confirmed Amazon countries below (since they probably work for Ebay too).

// export const amazonAffiliateCountryCodes = [
// 	'US', // United States
// 	'CA', // Canada
// 	// 'HI', // India (Hindi? 43% speak it -- don't bother with others since you have US?) (need bank in india -- no bank address available yet)
// 	// 'CN', // People's Republic of China (Mandarin)
// 	// 'HK', // Hong Kong (Cantonese)

// 	// ----------------------
// 	'GB', // United Kingdom (Great Britain)
// 	'FR', // France
// 	'DE', // Germany
// 	'IT', // Italy
// 	'ES' // Spain
// ]

// export const userCountryCodeDomainSuffixMap: { [key: string]: string } = {
// 	US: 'com',
// 	GB: 'co.uk',
// 	CA: 'ca',
// 	FR: 'fr',
// 	DE: 'de',
// 	IT: 'it',
// 	ES: 'es'
// }

// export const userCountryCodeAmazonAffiliateTagMap: { [key: string]: string } = {
// 	US: 'typelitio-20',
// 	GB: 'typelitio-21',
// 	CA: 'typelitio09-20',
// 	FR: 'typelitio05-21',
// 	DE: 'typelitio02-21',
// 	IT: 'typelitio04-21',
// 	ES: 'typelitio0c-21'
// }

// SECTION: GDPR Codes
export const gdprCountryCodes = [
	// -----[ EU 28 ]-----
	'AT', // Austria
	'BE', // Belgium
	'BG', // Bulgaria
	'HR', // Croatia
	'CY', // Cyprus
	'CZ', // Czech Republic
	'DK', // Denmark
	'EE', // Estonia
	'FI', // Finland
	'FR', // France
	'DE', // Germany
	'GR', // Greece
	'HU', // Hungary
	'IE', // Ireland, Republic of (EIRE)
	'IT', // Italy
	'LV', // Latvia
	'LT', // Lithuania
	'LU', // Luxembourg
	'MT', // Malta
	'NL', // Netherlands
	'PL', // Poland
	'PT', // Portugal
	'RO', // Romania
	'SK', // Slovakia
	'SI', // Slovenia
	'ES', // Spain
	'SE', // Sweden
	'GB', // United Kingdom (Great Britain)

	// -----[ Outermost Regions (OMR) ]------
	'GF', // French Guiana
	'GP', // Guadeloupe
	'MQ', // Martinique
	'ME', // Montenegro
	'YT', // Mayotte
	'RE', // Réunion
	'MF', // Saint Martin

	// -----[ Special Cases: Part of EU ]-----
	'GI', // Gibraltar
	'AX', // Åland Islands

	// -----[ Overseas Countries and Territories (OCT) ]-----
	'PM', // Saint Pierre and Miquelon
	'GL', // Greenland
	'BL', // Saint Bartelemey
	'SX', // Sint Maarten
	'AW', // Aruba
	'CW', // Curacao
	'WF', // Wallis and Futuna
	'PF', // French Polynesia
	'NC', // New Caledonia
	'TF', // French Southern Territories
	'AI', // Anguilla
	'BM', // Bermuda
	'IO', // British Indian Ocean Territory
	'VG', // Virgin Islands, British
	'KY', // Cayman Islands
	'FK', // Falkland Islands (Malvinas)
	'MS', // Montserrat
	'PN', // Pitcairn
	'SH', // Saint Helena
	'GS', // South Georgia and the South Sandwich Islands
	'TC', // Turks and Caicos Islands

	// -----[ Microstates ]-----
	'AD', // Andorra
	'LI', // Liechtenstein
	'MC', // Monaco
	'SM', // San Marino
	'VA', // Vatican City

	// -----[ Other ]-----
	'JE', // Jersey
	'GG' // Guernsey
]
