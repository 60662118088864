import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'
import { UserNotesContext } from '../../../contexts/UserNotesContext'
import { renderLog, _useEffectLog } from '../../../util/utilFunctions'
import DefaultAlert from './DefaultAlert'

const ErrorAlert = () => {
	const { error, setError } = useContext(UserNotesContext)

	const router = useRouter()

	// USE EFFECT: Ensures error in NotesContext is empty on page change
	useEffect(() => {
		_useEffectLog('ERROR ALERT useEffect CLEANUP')
		setError('')
	}, [router.asPath])

	return error ? (
		<>
			{renderLog('ERROR ALERT')}
			<DefaultAlert autoHide={false} severity='warning' text={error} reOpenableCb={setError} />
		</>
	) : null // NOTE: _app likes this null to be here
}

export default ErrorAlert
