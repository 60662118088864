import { MutableRefObject, ReactElement, useContext, useEffect, useRef } from 'react'
import { FormControlLabel, FormControl, FormGroup, TextField } from '@mui/material'
import { Palette } from '../../../util/palette/Palette'
import { devLog, renderLog, _useEffectLog } from '../../../util/utilFunctions'
import { FormatOptionsSection, OrigFileType } from './FormatOptionsModule'
import { FormatCustomBookContext, SwitchRadioInputOptionsProps } from '../../../contexts/FormatCustomBookContext'
import { BookOptionsSection } from './BookOptionsModule'
import {
	numberFieldValidators,
	textFieldValidators,
	validateNumberBlurredInput,
	validateNumberInput,
	validateTextBlurredInput,
	validateTextInput
} from '../../../functions/sharedFunctions/sharedInputValidators'
import useOptionsStyles from './OptionsStyles'
import DefaultTooltip from '../../sharedComponents/tooltips/DefaultTooltip'

// SECTION: Props
// Props for the inputs themselves -- not the InputOptions component
interface InputOptionsProps {
	label?: string | ReactElement
	innerLabel?: string
	name: string
	inputType: string
	defaultValue?: string | number
	helperText?: string
	inputRef: MutableRefObject<any>
	className: string
	required?: boolean
	disabled: boolean
}

export const InputOptions = ({
	optionsSection,
	origFileType,
	origLongestLineLength,
	origDisplayTitle
}: SwitchRadioInputOptionsProps) => {
	// SECTION: Hooks -- Embedded input refs passed in from RadioOptions
	const {
		formatOptionsObj,
		bookOptionsObj,
		maxLineLengthOptionDisabled,
		setBookOptionsObj,
		setFormatOptionsObj,
		setFormatOption
	} = useContext(FormatCustomBookContext)

	const displayTitleRef = useRef<any>(null)
	const maxLineLengthRef = useRef<any>(null)

	const { classes } = useOptionsStyles(Palette.getPalette())()

	// USE EFFECT: On first load, stick these derived values from book convertion into the optionsObj
	useEffect(() => {
		_useEffectLog('INPUT OPTIONS useEffect 0')
		if (origDisplayTitle) {
			setBookOptionsObj({ ...bookOptionsObj, displayTitle: origDisplayTitle })
		}

		if (origLongestLineLength) {
			setFormatOptionsObj({ ...formatOptionsObj, maxLineLength: origLongestLineLength })
		}
	}, [])

	// USE EFFECT: Revert format option input ref values to default after reset defaults button pressed
	useEffect(() => {
		_useEffectLog('INPUT OPTIONS useEffect 1')

		if (maxLineLengthRef.current) {
			maxLineLengthRef.current.value = formatOptionsObj.maxLineLength
		}
	}, [formatOptionsObj])

	// SECTION: Get Option Props
	const getOptionsInputsProps = (optionsSection: FormatOptionsSection | BookOptionsSection) => {
		const props: { [key: string]: InputOptionsProps } = {
			INPUT_DISPLAY_TITLE: {
				innerLabel: 'Book Title',
				name: 'displayTitle',
				inputType: 'text',
				defaultValue: origDisplayTitle,
				inputRef: displayTitleRef,
				className: `${classes.input} ${classes.displayTitleInputExtra}`,
				disabled: false
			},

			INPUT_MAX_LINE_LENGTH: {
				label: (
					<div className={classes.labelWithTooltip}>
						Max Line Length&nbsp;
						{origFileType !== OrigFileType.EPUB && (
							<DefaultTooltip label={'Changing this may cause minor formatting errors in PDF and TXT files.'} />
						)}
					</div>
				),
				name: 'maxLineLength',
				inputType: 'number',
				defaultValue: origLongestLineLength ? origLongestLineLength : formatOptionsObj.maxLineLength,
				helperText: 'Range: 50 - 150',
				inputRef: maxLineLengthRef,
				className: classes.input,
				disabled: maxLineLengthOptionDisabled
			}
		}
		return props[optionsSection]
	}

	// SECTION: Master Validators --  embedded input has their own version of these
	// NOTE: Only updates formatOptionsObj on blur -- or when displayTitle input is empty or has 1 character
	const validateInput = (inputRef: MutableRefObject<any>) => {
		if (Object.keys(numberFieldValidators).includes(inputRef.current.name)) {
			validateNumberInput(inputRef)
		} else if (Object.keys(textFieldValidators).includes(inputRef.current.name)) {
			validateTextInput(inputRef)
		}
	}

	const validateBlurredInput = (inputRef: MutableRefObject<any>) => {
		if (Object.keys(numberFieldValidators).includes(inputRef.current.name)) {
			validateNumberBlurredInput(inputRef)
		} else if (Object.keys(textFieldValidators).includes(inputRef.current.name)) {
			validateTextBlurredInput(inputRef) // Doesn't currently do anything -- keep and fill out the function if you need this
		}

		setFormatOption(inputRef.current.name, inputRef.current.value)
	}

	const optionsInputProps = getOptionsInputsProps(optionsSection)
	// SECTION: Render UI
	// QUIRK: useMemo messes up the styling here
	return (
		<>
			{optionsInputProps && (
				<FormControl component='fieldset'>
					<FormGroup>
						{renderLog('INPUT OPTIONS')}
						<FormControlLabel
							className={classes.inputOptionLabel}
							control={
								<TextField
									className={optionsInputProps.className}
									size={'small'}
									variant='outlined'
									margin='dense'
									autoComplete='off'
									label={optionsInputProps.innerLabel}
									name={optionsInputProps.name}
									type={optionsInputProps.inputType}
									defaultValue={optionsInputProps.defaultValue}
									inputRef={optionsInputProps.inputRef}
									onChange={() => validateInput(optionsInputProps.inputRef)}
									onBlur={() => validateBlurredInput(optionsInputProps.inputRef)}
									helperText={optionsInputProps.helperText}
									disabled={optionsInputProps.disabled}
								/>
							}
							disabled={optionsInputProps.disabled}
							label={optionsInputProps.label ?? ''}
							key={optionsInputProps.name}
						/>
					</FormGroup>
				</FormControl>
			)}
		</>
	)
}
