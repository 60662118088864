// ABOUT: Tooltip that can trigger on disabled components. Used when feature disabled via feature flag.

import React from 'react'
import { Tooltip } from '@mui/material'
import { makeStyles } from '../../../makeStyles'
import { Palette, PaletteTheme } from '../../../util/palette/Palette'
import { devLog } from '../../../util/utilFunctions'

const useStyles = (palette: PaletteTheme) => {
	const { disabled1 } = palette

	return makeStyles()({
		disabledElement: {
			width: 'fit-content',
			'& *': {
				pointerEvents: 'none', // Only disable pointer events on children, so that tooltip still gets triggered
				color: `${disabled1} !important`,
				borderColor: `${disabled1} !important`
			}
		},
		_fullChildWidth: {
			width: '100%'
		}
	})
}

type TooltipPlacement =
	| 'bottom-start'
	| 'bottom'
	| 'left-end'
	| 'left-start'
	| 'left'
	| 'right-end'
	| 'right-start'
	| 'right'
	| 'top-end'
	| 'top-start'
	| 'top'

interface DisabledFeatureToolTipProps {
	placement?: TooltipPlacement
	disabled: boolean
	forceFullChildWidth?: boolean
	children: any
}

// NOTE: Span needed for tooltip to work on disabled child component
// NOTE: Remember that disabled refers to this tooltip being disabled -- not the feature it's wrapping being disabled
// QUIRK: QuickConvert breadcrumbs -- disables link and applies styles, but tooltip doesn't appear
const DisabledFeatureTooltip = ({
	placement,
	disabled,
	forceFullChildWidth,
	children
}: DisabledFeatureToolTipProps) => {
	const { classes, cx } = useStyles(Palette.getPalette())()

	return (
		<Tooltip
			title={disabled ? '' : 'Temporarily Unavailable'} // Make sure Aria doesn't see this title unless enabled
			placement={placement}
			disableFocusListener={disabled}
			disableHoverListener={disabled}
			disableTouchListener={disabled}
			aria-disabled={disabled}>
			<span
				className={cx(
					!disabled && classes.disabledElement,
					forceFullChildWidth && classes._fullChildWidth // Prevents AddNewCustombookCard from collapsing when child of this component (applies even if tooltip disabled)
				)}>
				{children}
			</span>
		</Tooltip>
	)
}

export default DisabledFeatureTooltip
