import localFont from 'next/font/local'

// NOTE: Load this using next/font so that it's included in the JS bundle

const mulish = localFont({
	src: './baseFontFiles/Mulish-VariableFont_wght.ttf'
})

/* Just a subset for Comic Sans text on front page updates */
const comicSansMediumSubset = localFont({
	src: './baseFontFiles/LDFComicSans-Medium-subset.ttf'
})

export const mulishFont = mulish.style.fontFamily
export const comicSansMediumSubsetFont = comicSansMediumSubset.style.fontFamily
