import { MutableRefObject, ReactElement, useContext, useState } from 'react'
import { devLog, renderLog } from '../../../util/utilFunctions'
import { FormatCustomBookContext } from '../../../contexts/FormatCustomBookContext'
import { FormGroup, TextField, FormControl } from '@mui/material'
import {
	numberFieldValidators,
	validateNumberInput,
	validateNumberBlurredInput
} from '../../../functions/sharedFunctions/sharedInputValidators'
import { Palette } from '../../../util/palette/Palette'
import useOptionsStyles from './OptionsStyles'

// SECTION: Props
interface EmbeddedInputOptionsProps {
	name: string
	textPart1: string
	textPart2: string | ReactElement
	textPart3?: string
	helperText: string
	defaultValue?: number
	inputRef: MutableRefObject<any>
	linkedFormatOption: string
	linkedFormatEnum: any
}

export const EmbeddedInputOptions = ({
	name,
	textPart1,
	textPart2,
	textPart3,
	helperText,
	defaultValue,
	inputRef,
	linkedFormatOption,
	linkedFormatEnum
}: EmbeddedInputOptionsProps) => {
	// SECTION: Hooks
	const { formatOptionsObj, setFormatOption } = useContext(FormatCustomBookContext)

	const [renderVar, setRenderVar] = useState(false)

	const { classes, cx } = useOptionsStyles(Palette.getPalette())()

	// SECTION: Functionality
	const reRender = () => {
		setRenderVar(!renderVar)
	}

	const validateEmbeddedInput = (inputRef: MutableRefObject<any>) => {
		if (Object.keys(numberFieldValidators).includes(inputRef.current.name)) {
			validateNumberInput(inputRef)
		}
	}

	const validateBlurredEmbeddedInput = (inputRef: MutableRefObject<any>) => {
		if (Object.keys(numberFieldValidators).includes(inputRef.current.name)) {
			validateNumberBlurredInput(inputRef)
			setFormatOption(inputRef.current.name, inputRef.current.value)
			reRender()
		}
	}

	const getDisabled = () => {
		const formatOptionsObjStringAccess: { [key: string]: any } = formatOptionsObj
		return formatOptionsObjStringAccess[linkedFormatOption] !== linkedFormatEnum
	}

	// SECTION: Render UI
	return (
		<div className={classes.embeddedInputContainer}>
			{renderLog('EMBEDDED INPUT OPTIONS')}
			<span>{textPart1}</span>
			<FormControl component='fieldset'>
				<FormGroup>
					<TextField
						className={classes.input}
						size={'small'}
						variant='outlined'
						margin='dense'
						type='number'
						name={name}
						defaultValue={defaultValue}
						inputRef={inputRef}
						onChange={() => validateEmbeddedInput(inputRef)}
						onBlur={() => validateBlurredEmbeddedInput(inputRef)}
						helperText={helperText}
						disabled={getDisabled()}
					/>
				</FormGroup>
			</FormControl>
			<div className={cx(classes.embeddedInputLabelContainer, textPart3 && classes._multiLineInputLabelContainer)}>
				<span>{textPart2}</span>
				{textPart3 && <span>{textPart3}</span>}
			</div>
		</div>
	)
}
