'use strict'

function _typeof(obj) {
	if (typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol') {
		_typeof = function _typeof(obj) {
			return typeof obj
		}
	} else {
		_typeof = function _typeof(obj) {
			return obj && typeof Symbol === 'function' && obj.constructor === Symbol && obj !== Symbol.prototype
				? 'symbol'
				: typeof obj
		}
	}
	return _typeof(obj)
}

Object.defineProperty(exports, '__esModule', {
	value: true
})
exports.default = void 0

var _react = _interopRequireDefault(require('react'))

var _styledComponents = _interopRequireWildcard(require('styled-components'))

var _style = require('./style')

function _getRequireWildcardCache() {
	if (typeof WeakMap !== 'function') return null
	var cache = new WeakMap()
	_getRequireWildcardCache = function _getRequireWildcardCache() {
		return cache
	}
	return cache
}

function _interopRequireWildcard(obj) {
	if (obj && obj.__esModule) {
		return obj
	}
	if (obj === null || (_typeof(obj) !== 'object' && typeof obj !== 'function')) {
		return { default: obj }
	}
	var cache = _getRequireWildcardCache()
	if (cache && cache.has(obj)) {
		return cache.get(obj)
	}
	var newObj = {}
	var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor
	for (var key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null
			if (desc && (desc.get || desc.set)) {
				Object.defineProperty(newObj, key, desc)
			} else {
				newObj[key] = obj[key]
			}
		}
	}
	newObj.default = obj
	if (cache) {
		cache.set(obj, newObj)
	}
	return newObj
}

function _interopRequireDefault(obj) {
	return obj && obj.__esModule ? obj : { default: obj }
}

function _templateObject3() {
	var data = _taggedTemplateLiteral([
		'\n  box-sizing: border-box;\n  border-radius: 50%;\n  position: absolute;\n  border: calc(',
		'px * 0.05) solid transparent;\n  border-top-color: ',
		';\n  border-left-color: ',
		';\n'
	])

	_templateObject3 = function _templateObject3() {
		return data
	}

	return data
}

function _templateObject2() {
	var data = _taggedTemplateLiteral([
		'\n  width: ',
		'px;\n  height: ',
		'px;\n  position: relative;\n  /* overflow: hidden; */\n  > div:nth-child(1) {\n    height: calc(',
		'px - ',
		'px * 0.2 * 0);\n    width: calc(',
		'px - ',
		'px * 0.2 * 0);\n    top: calc(',
		'px * 0.1 * 0);\n    left: calc(',
		'px * 0.1 * 0);\n    animation: ',
		' ',
		's infinite;\n    animation-delay: calc(',
		's * 0.1 * 4);\n    z-index: 5;\n  }\n  > div:nth-child(2) {\n    height: calc(',
		'px - ',
		'px * 0.2 * 1);\n    width: calc(',
		'px - ',
		'px * 0.2 * 1);\n    top: calc(',
		'px * 0.1 * 1);\n    left: calc(',
		'px * 0.1 * 1);\n    animation: ',
		' ',
		's infinite;\n    animation-delay: calc(',
		's * 0.1 * 3);\n    z-index: 4;\n  }\n  > div:nth-child(3) {\n    height: calc(',
		'px - ',
		'px * 0.2 * 2);\n    width: calc(',
		'px - ',
		'px * 0.2 * 2);\n    top: calc(',
		'px * 0.1 * 2);\n    left: calc(',
		'px * 0.1 * 2);\n    animation: ',
		' ',
		's infinite;\n    animation-delay: calc(',
		's * 0.1 * 2);\n    z-index: 3;\n  }\n  > div:nth-child(4) {\n    height: calc(',
		'px - ',
		'px * 0.2 * 3);\n    width: calc(',
		'px - ',
		'px * 0.2 * 3);\n    top: calc(',
		'px * 0.1 * 3);\n    left: calc(',
		'px * 0.1 * 3);\n    animation: ',
		' ',
		's infinite;\n    animation-delay: calc(',
		's * 0.1 * 1);\n    z-index: 2;\n  }\n  > div:nth-child(5) {\n    height: calc(',
		'px - ',
		'px * 0.2 * 4);\n    width: calc(',
		'px - ',
		'px * 0.2 * 4);\n    top: calc(',
		'px * 0.1 * 4);\n    left: calc(',
		'px * 0.1 * 4);\n    animation: ',
		' ',
		's infinite;\n    animation-delay: calc(',
		's * 0.1 * 0);\n    z-index: 1;\n  }\n'
	])

	_templateObject2 = function _templateObject2() {
		return data
	}

	return data
}

function _templateObject() {
	var data = _taggedTemplateLiteral(['\n  50% {\n    transform: rotate(360deg) scale(0.7);\n  }\n'])

	_templateObject = function _templateObject() {
		return data
	}

	return data
}

function _taggedTemplateLiteral(strings, raw) {
	if (!raw) {
		raw = strings.slice(0)
	}
	return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }))
}

var rotate = (0, _styledComponents.keyframes)(_templateObject())

var LoadContainer = _styledComponents.default.div(
	_templateObject2(),
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	rotate,
	function (props) {
		return props.speed || 2
	},
	function (props) {
		return props.speed || 2
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	rotate,
	function (props) {
		return props.speed || 2
	},
	function (props) {
		return props.speed || 2
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	rotate,
	function (props) {
		return props.speed || 2
	},
	function (props) {
		return props.speed || 2
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	rotate,
	function (props) {
		return props.speed || 2
	},
	function (props) {
		return props.speed || 2
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	rotate,
	function (props) {
		return props.speed || 2
	},
	function (props) {
		return props.speed || 2
	}
)

var HalfCircle = _styledComponents.default.div(
	_templateObject3(),
	function (props) {
		return props.size === 'small' ? 40 : props.size === 'large' ? 60 : 50
	},
	function (props) {
		return props.color || '#00adb5'
	},
	function (props) {
		return props.color || '#00adb5'
	}
)

var SemipolarLoading = function SemipolarLoading(_ref) {
	var _ref$style = _ref.style,
		style = _ref$style === void 0 ? _style.commonStyle : _ref$style,
		color = _ref.color,
		speed = _ref.speed,
		_ref$size = _ref.size,
		size = _ref$size === void 0 ? 'default' : _ref$size
	return _react.default.createElement(
		LoadContainer,
		{
			style: style,
			speed: speed,
			size: size
		},
		Array.from(Array(5)).map(function (item, index) {
			return _react.default.createElement(HalfCircle, {
				color: color,
				speed: speed,
				size: size,
				key: index
			})
		})
	)
}

var _default = SemipolarLoading
exports.default = _default
