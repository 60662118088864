import { useSession } from 'next-auth/react'
import React, { useEffect, useState, createContext, ReactNode } from 'react'
import { isMobile } from 'react-device-detect'
import { devLog, getLocalStorage, renderLog, windowExists, _useEffectLog } from '../util/utilFunctions'

// NOTE: HOW TO ADD A NEW NOTE:
// Add new interface Entry
// Add new getter/setter function variables to the createContext
// Add new entry to defaultUserNotesObj
// Define new local obj getters/setters
// Add getter/setter function variables to Provider value prop

// SECTION: Types
interface UserNotesObj {
	showMobileStartupDialog: boolean
	showResetPageWarning: boolean
	showFinishedFormattingWarning: boolean
	showQuickConvertWarning: boolean
}

// SECTION: Context
export const UserNotesContext = createContext({
	userNotesObj: {} as UserNotesObj,
	signInSuccessMessage: '' as any, // should be string | null, but typescript complains
	error: '' as string | ReactNode,
	setSignInSuccessMessage: (message: string | null) => {},
	getShowMobileStartupDialog: (): any => {},
	setShowMobileStartupDialog: (mode: boolean) => {},
	getShowResetPageWarning: (): any => {},
	setShowResetPageWarning: (mode: boolean) => {},
	getShowFinishedFormattingWarning: (): any => {},
	setShowFinishedFormattingWarning: (mode: boolean) => {},
	getShowQuickConvertWarning: (): any => {},
	setShowQuickConvertWarning: (mode: boolean) => {},
	setError: (error: string | ReactNode) => {}
})

// SECTION: Provider
export const UserNotesProvider = (props: any) => {
	// SECTION: Hooks
	const [userNotesObj, setUserNotesObj] = useState({} as UserNotesObj)
	const [signInSuccessMessage, setSignInSuccessMessage] = useState('' as string | null)
	const [error, setError] = useState('' as string | ReactNode)

	const { data: session, status } = useSession()

	const defaultUserNotesObj = {
		showMobileStartupDialog: isMobile ? true : false,
		showResetPageWarning: true,
		showFinishedFormattingWarning: true,
		showQuickConvertWarning: true
	}

	// USE EFFECT: Loads notes from local storage or saves notes to local storage whenever they change
	// TODO / QUIRK: On dev React Strict mode these notes return to default on refresh. They're fine on prod so probs not worth fixing
	useEffect(() => {
		_useEffectLog('USER NOTES CONTEXT useEffect 0')
		if (Object.keys(userNotesObj).length === 0) {
			loadUserNotes()
		} else {
			saveUserNotesToLocalStorage() // updates local storage whenever UserNotesObj changes
		}
	}, [userNotesObj])

	// HELPERS
	// Sets userNotesObj to that in local storage + adds any missing keys, setting vals to default
	const loadUserNotes = () => {
		const newUserNotesObj = fetchUserNotesFromLocalStorage()
		if (Object.keys(newUserNotesObj).length > 0) {
			// If notes obj already exists this sets any non-existing keys to their default val
			for (const [key, note] of Object.entries(defaultUserNotesObj)) {
				if (!(key in newUserNotesObj)) {
					newUserNotesObj[key] = note
				}
			}
			setUserNotesObj(newUserNotesObj)
		} else {
			// sets a new notes obj with default values for all keys
			setUserNotesObj(defaultUserNotesObj)
		}
		// saves the new obj
		saveUserNotesToLocalStorage()
	}

	// USE EFFECTS: Watch for login success and trigger popup -- disables self on exit from SignInSuccessAlert
	useEffect(() => {
		_useEffectLog('USER NOTES useEffect 1')
		if (session?.user?.email && signInSuccessMessage !== null) {
			setSignInSuccessMessage(`Signed in as ${session?.user?.email}`)
		}
	}, [session])

	// USE EFFECT: Display error if cookies disabled
	useEffect(() => {
		_useEffectLog('USER NOTES useEffect 2')
		if (!windowExists()) return

		const cookieEnabled = window.navigator.cookieEnabled
		if (!cookieEnabled) {
			setError('Cookies and browser storage must be enabled for this site to function properly.')
		}
	}, [])

	// SECTION: local Storage Getters/Stetters
	const saveUserNotesToLocalStorage = () => {
		getLocalStorage()?.setItem(`typelit-usernotes`, JSON.stringify(userNotesObj))
	}

	const fetchUserNotesFromLocalStorage = () => {
		const userNotes = getLocalStorage()?.getItem('typelit-usernotes')
		return userNotes ? JSON.parse(userNotes) : ({} as UserNotesObj)
	}

	// SECTION: Local Obj Getters/Setters
	const getShowMobileStartupDialog = () => {
		return userNotesObj.showMobileStartupDialog
	}

	const setShowMobileStartupDialog = (mode: boolean) => {
		setUserNotesObj({ ...userNotesObj, showMobileStartupDialog: mode })
	}

	const getShowResetPageWarning = () => {
		return userNotesObj.showResetPageWarning
	}

	const setShowResetPageWarning = (mode: boolean) => {
		setUserNotesObj({ ...userNotesObj, showResetPageWarning: mode })
	}

	const getShowFinishedFormattingWarning = () => {
		return userNotesObj.showFinishedFormattingWarning
	}

	const setShowFinishedFormattingWarning = (mode: boolean) => {
		setUserNotesObj({ ...userNotesObj, showFinishedFormattingWarning: mode })
	}

	const getShowQuickConvertWarning = () => {
		return userNotesObj.showQuickConvertWarning
	}

	const setShowQuickConvertWarning = (mode: boolean) => {
		setUserNotesObj({ ...userNotesObj, showQuickConvertWarning: mode })
	}

	return (
		<UserNotesContext.Provider
			value={{
				userNotesObj,
				signInSuccessMessage,
				error,
				setSignInSuccessMessage,
				getShowMobileStartupDialog,
				setShowMobileStartupDialog,
				getShowResetPageWarning,
				setShowResetPageWarning,
				getShowFinishedFormattingWarning,
				setShowFinishedFormattingWarning,
				getShowQuickConvertWarning,
				setShowQuickConvertWarning,
				setError
			}}>
			{props.children}
			{renderLog('USER NOTES CONTEXT')}
		</UserNotesContext.Provider>
	)
}
