import { createTheme } from '@mui/material'
import { materialUIGlobalThemePalette } from './util/palette/Palette'
const { mainBackgroundColor, font1 } = materialUIGlobalThemePalette

// SECTION: Global Styles -- Not changable from the front-end. Only used to set body background. Use Palette.ts for anything more dynamic.
export const globalStyles = {
	body: { backgroundColor: mainBackgroundColor, backgroundSize: 'cover' } // body can only be overridden from here
}

// SECTION: Global Mui Themes -- Set Mui component defaults here. Can't change these dynamically either.
// Note: For Typography, if you don't include variant and component they default to: variant'body1' and component='p'
export const globalMuiTheme = createTheme({
	// default styles -- apply to the appropriate Typeography variant prop
	typography: {
		h1: {
			fontFamily: font1
		},
		h2: {
			fontFamily: font1
		},
		h3: {
			fontFamily: font1
		},
		h4: {
			fontFamily: font1
		},
		h5: {
			fontFamily: font1,
			fontSize: 18 // Just leave this -- variant effects line-height at letter-spacing too
		},
		h6: {
			fontFamily: font1
		},
		subtitle1: {
			fontFamily: font1
		},
		subtitle2: {
			fontFamily: font1 // used -- variant effects line-height at letter-spacing too
		},
		body1: {
			fontFamily: font1
		}
	}
})
