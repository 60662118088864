import { useEffect, useState, createContext, useContext } from 'react'
import { BookOptionsSection } from '../components/FormatPage/formatOptions/BookOptionsModule'
import { FormatOptionsSection, OrigFileType } from '../components/FormatPage/formatOptions/FormatOptionsModule'
import {
	apiLog,
	devErr,
	devLog,
	getCurrentPagePathName,
	getLocalStorage,
	getTextConverterBaseUrl,
	renderLog,
	_useEffectLog
} from '../util/utilFunctions'
import equal from 'fast-deep-equal/react'
import { saveCustomBookToIdb } from '../functions/idb/getCreateSaveCustomBookIdb'
import { useRouter } from 'next/router'
import axios from 'axios'
import { useSession } from 'next-auth/react'
import { getRandomCustomBookCoverName } from '../functions/sharedFunctions/customBookCovers'
import { handleErrorOnFrontend } from '../util/errorHandlers'
import { UserNotesContext } from './UserNotesContext'
import { PreviewObj } from '../components/FormatPage/FormatPage'
import { UtilContext } from './UtilContext'
import { unzipIt } from '../functions/sharedFunctions/zipCompression'
/////
/*
	//QUIRK: useContext for Ls access
	PROS:
		update faster -- update obj and replace old one automatically from useEffect vs pull obj from ls update and put back
		components updated according new obj automatically
		obj is accessable from all wrapped compnents -- don't need to grab from ls in parent and drill props down
	CONS:
		*might* be slower than regular ts file
*/
////

// SECTION: formatOptionsObj Types
// NOTE: Keep these enums in sync with converter server versions
export enum DetectNewChapterOn {
	EPUB_DEFAULT = 'EPUB_DEFAULT',
	MANUAL_CHAPTER_BREAK = 'MANUAL_CHAPTER_BREAK',
	EVERY_X_PAGES = 'EVERY_X_PAGES',
	NULL = '' // Used by Quick Convert
}

export enum ShortChaptersStrategy {
	REMOVE = 'REMOVE',
	MERGE_WITH_NEXT_CHAPTER = 'MERGE_WITH_NEXT_CHAPTER',
	NULL = 'NULL'
}

export interface FormatOptionsObj {
	removeLineIndents: boolean
	spaceBetweenParagraphs: boolean
	removePageNumbers: boolean
	altEpubFormatting: boolean // always reset to when importing new text -- quick-convert-text set perminently to false in text converter
	maxLineLength: number
	shortChaptersStrategy: ShortChaptersStrategy
	omitChapterList: Set<number> // A set because access by key is faster than index -- will avoid looping multiple times (or at all)
	detectNewChapterOn: DetectNewChapterOn
	maxPagesPerChapter: number
}

// SECTION bookOptionsObj types
// Changable by the user from format page or chapter select page
export interface BookOptionsObj {
	bookTitle: string // can only change from format page
	displayTitle: string
	author: string
	language: string
	synopsis: string
}

// SECTION: Switch Radio and Input props
export interface SwitchRadioInputOptionsProps {
	optionsSection: FormatOptionsSection | BookOptionsSection
	origFileType: OrigFileType
	origLongestLineLength?: number | null
	origDisplayTitle?: string
}

// SECTION: Context
export const FormatCustomBookContext = createContext({
	formatOptionsObj: {} as FormatOptionsObj,
	prevTestedFormatOptionsObj: {} as FormatOptionsObj,
	bookOptionsObj: {} as BookOptionsObj,
	finishButtonDisabled: false,
	maxLineLengthOptionDisabled: false,
	shortChaptersOptionsDisabled: false,
	previews: [] as PreviewObj[],
	processing: false,
	processingPrompt: '',
	prepFormatOptionsForInitialBookConversion: (formatOptionsObj: FormatOptionsObj, fileMimeType: string): any => {},
	setPreviews: (previews: PreviewObj[]) => {},
	setProcessing: (mode: boolean) => {},
	setFormatOptionsObj: (formatOptionsObj: FormatOptionsObj) => {},
	setBookOptionsObj: (publicCustomBookDetailsObj: BookOptionsObj) => {},
	setFormatOption: (optionName: string, value: any) => {},
	resetDefaultFormatOptions: () => {},
	setAndSaveFormatOptionsToStateAndLocalStorage: (newFormatOptionsObj: FormatOptionsObj) => {},
	noChaptersSelected: (): any => {},
	setOrigFileTypeInContext: (origFileType: OrigFileType): any => {},
	setOrigLongestLineLengthInContext: (origLongestLineLength: number | null): any => {},
	reformatTxt: () => {},
	convertTxtToRawBook: () => {},
	setMaxLineLengthOptionDisabled: (mode: boolean) => {},
	setShortChaptersOptionsDisabled: (mode: boolean) => {}
})

// SECTION: Provider
export const FormatCustomBookProvider = (props: any) => {
	// SECTION: State for default options (More hooks further down)
	// will be different if origLongestLineLengthInContext isn't null
	const [origFileTypeInContext, setOrigFileTypeInContext] = useState(OrigFileType.EPUB) // EPUB as default. Could have been anything since it should be replaced immediately
	const [origLongestLineLengthInContext, setOrigLongestLineLengthInContext] = useState(null as number | null)

	// SECTION: Default Format and Book Options
	const defaultFormatOptionsObj = {
		removeLineIndents: true,
		spaceBetweenParagraphs: true,
		removePageNumbers: false,
		altEpubFormatting: false,
		maxLineLength: origLongestLineLengthInContext ? origLongestLineLengthInContext : 70,
		shortChaptersStrategy:
			origFileTypeInContext === OrigFileType.PDF ? ShortChaptersStrategy.NULL : ShortChaptersStrategy.REMOVE, // option only visible to EPUB and TXT
		omitChapterList: new Set(),
		detectNewChapterOn:
			origFileTypeInContext === OrigFileType.EPUB ? DetectNewChapterOn.EPUB_DEFAULT : DetectNewChapterOn.EVERY_X_PAGES,
		maxPagesPerChapter: 30 // only used if detectNewChapterOnEveryXPages is enabled
	} as FormatOptionsObj

	const defaultBookOptionsObj = {
		bookTitle: '', // can only change from format page
		displayTitle: '',
		author: '',
		language: 'English',
		synopsis: ''
	}

	// SECTION: More Hooks
	const { userId } = useContext(UtilContext)
	const { error, setError } = useContext(UserNotesContext)

	const [formatOptionsObj, setFormatOptionsObj] = useState({} as FormatOptionsObj)
	const [prevTestedFormatOptionsObj, setPrevTestedFormatOptionsObj] = useState({} as FormatOptionsObj)
	const [bookOptionsObj, setBookOptionsObj] = useState(defaultBookOptionsObj as BookOptionsObj)
	const [previews, setPreviews] = useState([] as PreviewObj[]) // Need this to update previews on reformat
	const [finishButtonDisabled, setFinishButtonDisabled] = useState(false)
	const [processing, setProcessing] = useState(false)
	const [processingPrompt, setProcessingPrompt] = useState('')
	const [reRenderVar, setReRenderVar] = useState(false)
	const [maxLineLengthOptionDisabled, setMaxLineLengthOptionDisabled] = useState(false)
	const [shortChaptersOptionsDisabled, setShortChaptersOptionsDisabled] = useState(false)

	const { data: session, status } = useSession()
	const router = useRouter()

	// USE EFFECT: Loads customBook bookmarks from local storage
	useEffect(() => {
		_useEffectLog('USER BOOKMARKS CONTEXT useEffect 0')

		// If userId exists, then session must also exist
		if (userId) {
			loadFormatOptions(userId)
		}
	}, [userId])

	// HELPERS
	const loadFormatOptions = (userId: string) => {
		devLog('LOADING FORMAT OPTIONS')
		const formatOptionsObj = fetchFormatOptionsFromLocalStorage(userId)

		if (Object.keys(formatOptionsObj).length > 0) {
			// If format obj already exists this sets any non-existing keys to their default val
			for (const [key, option] of Object.entries(defaultFormatOptionsObj)) {
				if (!(key in formatOptionsObj)) {
					formatOptionsObj[key] = option
				}
			}
			setFormatOptionsObj({ ...formatOptionsObj })
			setPrevTestedFormatOptionsObj({ ...formatOptionsObj })
		} else {
			// sets a new format obj with default values for all keys
			setFormatOptionsObj({ ...defaultFormatOptionsObj })
			setPrevTestedFormatOptionsObj({ ...defaultFormatOptionsObj })
		}
	}

	// USE EFFECT: Validate when first arriving from convert page + when pressing Reset Defaults button
	useEffect(() => {
		_useEffectLog('FORMAT CUSTOM BOOK CONTEXT useEffect 1')
		validateFinishButtonDisabled()
	}, [formatOptionsObj, prevTestedFormatOptionsObj])

	// SECTION: Local Storage getters / setters
	const fetchFormatOptionsFromLocalStorage = (userId: string) => {
		const formatOptionsObj = getLocalStorage()?.getItem(`typelit-formatoptions-${userId}`)

		if (formatOptionsObj) {
			let parsedFormatOptionsObj = JSON.parse(formatOptionsObj)

			// Sets can only be stored as array in local storage, so you need to change it back to set
			parsedFormatOptionsObj.omitChapterList = new Set([...parsedFormatOptionsObj.omitChapterList])
			return parsedFormatOptionsObj
		} else {
			return {} as FormatOptionsObj
		}
	}

	const setAndSaveFormatOptionsToStateAndLocalStorage = (newFormatOptionsObj: FormatOptionsObj) => {
		setPrevTestedFormatOptionsObj({ ...newFormatOptionsObj })
		setFormatOptionsObj({ ...newFormatOptionsObj })

		// Change omitChapterList from Set to array before stringifying
		let stringifiedNewFormatOptionsObj = JSON.stringify({
			...newFormatOptionsObj,
			omitChapterList: [...newFormatOptionsObj.omitChapterList]
		})
		getLocalStorage()?.setItem(`typelit-formatoptions-${userId}`, stringifiedNewFormatOptionsObj)
	}

	// SECTION: Pre Book Conversion
	const prepFormatOptionsForInitialBookConversion = (formatOptionsObj: FormatOptionsObj, fileMimeType: string) => {
		// Reset customOptionsOrder in context so it doesn't order chapters by previous uploads order
		const _formatOptionsObj = formatOptionsObj
		_formatOptionsObj.omitChapterList = [] as unknown as Set<number> // can only send array -- not set

		// Set defaults from NULL or set NULL options not allowed on current file type
		switch (fileMimeType) {
			case 'application/epub+zip':
				if (_formatOptionsObj.shortChaptersStrategy === ShortChaptersStrategy.NULL) {
					_formatOptionsObj.shortChaptersStrategy = ShortChaptersStrategy.REMOVE
				}
				_formatOptionsObj.detectNewChapterOn = DetectNewChapterOn.EPUB_DEFAULT
				break
			case 'application/pdf':
				_formatOptionsObj.shortChaptersStrategy = ShortChaptersStrategy.NULL // PDF can only set chapter length by page increments
				_formatOptionsObj.detectNewChapterOn = DetectNewChapterOn.EVERY_X_PAGES
				break
			case 'text/plain':
				if (_formatOptionsObj.shortChaptersStrategy === ShortChaptersStrategy.NULL) {
					_formatOptionsObj.shortChaptersStrategy = ShortChaptersStrategy.REMOVE
				}
				if (_formatOptionsObj.detectNewChapterOn === DetectNewChapterOn.EPUB_DEFAULT) {
					_formatOptionsObj.detectNewChapterOn = DetectNewChapterOn.EVERY_X_PAGES
				}
				break
		}
		_formatOptionsObj.altEpubFormatting = false // always reset to false

		return _formatOptionsObj
	}

	// SECTION: Convert/Reformat Text Server Handlers
	const convertTxtToRawBook = async () => {
		try {
			setProcessingPrompt('Finalizing...')
			setProcessing(true)

			devLog(bookOptionsObj)
			devLog(formatOptionsObj.omitChapterList)

			if (!session?.user?.email) throw Error()

			// Convert to raw book
			apiLog('FORMAT CUSTOM BOOK CONTEXT: convertTxtToRawBook')
			const zipResult = await axios.post(`${getTextConverterBaseUrl()}/convertTxtToRawBook`, {
				email: session.user.email,
				userId,
				bookOptions: bookOptionsObj,
				omitChapterList: [...formatOptionsObj.omitChapterList]
			})

			// Unzip File
			let customBookString = await unzipIt(zipResult.data, 'rawBook', { base64: true })
			const customBook = JSON.parse(customBookString)

			// Extract variables
			customBook.coverName = getRandomCustomBookCoverName()

			const { bookTitle, displayTitle, chapterCount, pageCount } = customBook

			// Save custom book to idb
			await saveCustomBookToIdb(customBook)

			// Save customBookDetails to db
			// NOTE: Saving the db stuff last since if it fails, the only downside will be that an Unavailable book will not exist. Can still resurrect from .typelit file.
			await axios.put('/api/userCustomBookDetails/insertCustomBookDetails', {
				bookTitle,
				displayTitle,
				chapterCount,
				pageCount,
				isTypelitFile: false
			})

			// NOTE: Format objects don't need to be updated here, since user wouldn't be able to reach here without formatting first

			// Remove temp book data from AWS -- waiting till here to do this to ensure custom book saved to db and idb first
			try {
				apiLog('FORMAT CUSTOM BOOK CONTEXT: removeTmpTxtFromAws')
				await axios.post(`${getTextConverterBaseUrl()}/removeTmpTxtFromAws`, {
					email: session.user.email
				})
			} catch (err) {
				// Silent error -- if this fails tmp data will eventually be automatically removed anyway
				handleErrorOnFrontend(err, '', () => {})
			}

			// If user changes to different page during processing, page wont auto-chage on convertion complete
			if (getCurrentPagePathName() === '/bring-your-own-books/format') {
				router.push(`/chapters/${encodeURIComponent(bookTitle.split(' ').join('_'))}`)
			}
		} catch (err) {
			handleErrorOnFrontend(err, "Couldn't finalize text.", setError)
			setProcessing(false)
		}
	}

	const reformatTxt = async () => {
		try {
			setProcessingPrompt('Reformatting...')
			setProcessing(true)

			// If epub order method switched from Custom to something else, clear custom ordering before reformatting
			let _formatOptionsObj = formatOptionsObj

			devLog(_formatOptionsObj)

			if (!session?.user?.email) throw Error()

			apiLog('FORMAT CUSTOM BOOK CONTEXT: reformatTxt')
			const result = await axios.post(`${getTextConverterBaseUrl()}/reformatTxt`, {
				email: session.user.email,
				formatOptions: _formatOptionsObj
			})

			// Clear omitChapterList if a different number of previews come back from api
			if (result.data.previews.length !== previews.length) {
				clearOmitChapterList()
			}

			setPreviews(result.data.previews)

			// Save formatOptionsObj WITH the ordering -- only clear perminantly when different book converted
			setAndSaveFormatOptionsToStateAndLocalStorage({ ...formatOptionsObj }) // wait till here to save settings to local storage (setFormatOptionsObj needs time to finish)
		} catch (err) {
			handleErrorOnFrontend(err, "Couldn't reformat text.", setError)
		} finally {
			setProcessing(false)
		}
	}

	// SECTION: Misc Functions
	const reRender = () => {
		setReRenderVar(!reRenderVar)
	}

	const resetDefaultFormatOptions = () => {
		// Destructure default object -- otherwise you're sending a reference and setting properties will updated defaultFormatObject
		// NOTE: This resets to 70 on EPUB, so if it started as something else, you'll still need to reformat after pressing reset defaults before Finish button becomes available
		setFormatOptionsObj({
			...defaultFormatOptionsObj,
			omitChapterList: formatOptionsObj.omitChapterList // don't reset the chapters included/excluded
		})
	}

	// SECTION: OPTION SETTERS
	// SUBSECTION: Master Option Setter
	const setFormatOption = (optionName: string, value: any) => {
		// Use setters to change individual options rather than replacing the whole formatOptionsObj to avoid all other settings UI from rerendering.
		// Changes aren't saved to local storage until rerender, finalization, or another option replaced formatOptionsObj
		const optionSetter: { [name: string]: Function } = {
			omitChapter: addToOmitChapterList,
			restoreChapter: removeFromOmitChapterList,
			removeLineIndents: setRemoveLineIndents,
			spaceBetweenParagraphs: setSpaceBetweenParagraphs,
			removePageNumbers: setRemovePageNumbers,
			altEpubFormatting: setAltEpubFormatting,
			shortChaptersStrategy: setShortChaptersStrategy,
			displayTitle: setDisplayTitle,
			maxLineLength: setMaxLineLength,
			detectNewChapterOn: setDetectNewChapterOn,
			maxPagesPerChapter: setMaxPagesPerChapter
		}

		optionSetter[optionName](value)
		validateFinishButtonDisabled() // Must be validated here since changing these wont trigger useEffects (formatOptionsObj is mutated, not replaced)
	}

	const validateFinishButtonDisabled = () => {
		if (!equal(formatOptionsObj, prevTestedFormatOptionsObj) || noChaptersSelected()) {
			setFinishButtonDisabled(true)
		} else {
			setFinishButtonDisabled(false)
		}
	}

	// SUBSECTION: Options Setter Helpers
	const addToOmitChapterList = (initialChapterIndex: number) => {
		formatOptionsObj.omitChapterList.add(initialChapterIndex)
		if (noChaptersSelected()) reRender() // This and below ensures the Reformat button rerenders in these instances
	}

	const removeFromOmitChapterList = (initialChapterIndex: number) => {
		formatOptionsObj.omitChapterList.delete(initialChapterIndex)
		if (oneChapterSelected()) reRender()
	}

	const clearOmitChapterList = () => {
		formatOptionsObj.omitChapterList.clear()
		// Don't need to reRender here since you can only reformat if at least one chapter is active
	}

	// NOTE: Ensures this only ever runs on format page, otherwise will be zero on arrival
	const noChaptersSelected = () => {
		return (
			router.pathname === '/bring-your-own-books/format' &&
			previews.length - formatOptionsObj.omitChapterList?.size === 0
		)
	}

	const oneChapterSelected = () => {
		return (
			router.pathname === '/bring-your-own-books/format' &&
			previews.length - formatOptionsObj.omitChapterList?.size === 1
		)
	}

	// SUBSECTION: Switches
	const setRemoveLineIndents = (mode: boolean) => {
		formatOptionsObj.removeLineIndents = mode
	}

	const setSpaceBetweenParagraphs = (mode: boolean) => {
		formatOptionsObj.spaceBetweenParagraphs = mode
	}

	const setRemovePageNumbers = (mode: boolean) => {
		formatOptionsObj.removePageNumbers = mode
	}

	const setAltEpubFormatting = (mode: boolean) => {
		formatOptionsObj.altEpubFormatting = mode
	}

	const setShortChaptersStrategy = (mode: ShortChaptersStrategy) => {
		formatOptionsObj.shortChaptersStrategy = mode
	}

	// SUBSECTION: Inputs
	const setDisplayTitle = (displayTitle: string) => {
		bookOptionsObj.displayTitle = displayTitle
	}

	const setMaxLineLength = (maxLineLength: number) => {
		formatOptionsObj.maxLineLength = +maxLineLength
	}

	// SUBSECTION: Embedded Input Radio Buttons
	// Radio Buttons
	const setDetectNewChapterOn = (detectNewChapterOn: DetectNewChapterOn) => {
		formatOptionsObj.detectNewChapterOn = detectNewChapterOn
	}

	// Embedded Inputs
	const setMaxPagesPerChapter = (maxPagesPerChapter: number) => {
		formatOptionsObj.maxPagesPerChapter = +maxPagesPerChapter
	}

	return (
		<FormatCustomBookContext.Provider
			value={{
				formatOptionsObj,
				prevTestedFormatOptionsObj,
				bookOptionsObj,
				finishButtonDisabled,
				maxLineLengthOptionDisabled,
				shortChaptersOptionsDisabled,
				previews,
				processing,
				processingPrompt,
				prepFormatOptionsForInitialBookConversion,
				setPreviews,
				setProcessing,
				setFormatOptionsObj,
				setBookOptionsObj,
				setFormatOption,
				resetDefaultFormatOptions,
				setAndSaveFormatOptionsToStateAndLocalStorage,
				noChaptersSelected,
				setOrigFileTypeInContext,
				setOrigLongestLineLengthInContext,
				reformatTxt,
				convertTxtToRawBook,
				setMaxLineLengthOptionDisabled,
				setShortChaptersOptionsDisabled
			}}>
			{props.children}
			{renderLog('FORMAT CUSTOM BOOK CONTEXT')}
		</FormatCustomBookContext.Provider>
	)
}
