import axios from 'axios'
import { signOut, useSession } from 'next-auth/react'
import { createContext, useState, useEffect, useContext } from 'react'
import {
	devErr,
	devLog,
	getBaseUrl,
	getWindowHeight,
	getWindowWidth,
	renderLog,
	_useEffectLog,
	verifyUrl,
	windowExists,
	getCurrentPagePathName,
	getLocalStorage
} from '../util/utilFunctions'
import { setUser, setTag } from '@sentry/nextjs'
import { BookDisplayTab } from '../components/BookSelectPage/BooksDisplayTabs'
import { UserLayoutStateContext } from './UserLayoutStateContext'
import { UserNotesContext } from './UserNotesContext'
import { useRouter } from 'next/router'
import { handleErrorOnFrontend } from '../util/errorHandlers'
import { UserAccountType } from '../types'
import { stripeValidateSubscription } from '../functions/sharedFunctions/stripeFrontend'

// SECTION: Types
export enum DisplayType {
	DESKTOP_DISPLAY = 'desktopDisplay',
	TABLET_DISPLAY = 'tabletDisplay',
	MOBILE_DISPLAY = 'mobileDisplay',
	THIN_MOBILE_DISPLAY = 'thinMobileDisplay'
}

// SECTION: Context
export const UtilContext = createContext({
	windowWidth: 0,
	windowHeight: 0,
	userId: null as string | null,
	userAccountType: UserAccountType.FREE,
	currentUrl: '', // full URL -- http://localhost:3000/chapters/Animal_Farm
	baseUrl: '', // Just the start -- http://localhost:3000
	currentPathname: '', // Just the path -- /chapters/Animal_Farm
	setWindowWidth: (width: number) => {},
	setWindowHeight: (height: number) => {},
	getIsFirstVisit: (): boolean | null => false,
	setIsFirstVisitFalse: () => {},
	isDesktopDisplay: (): boolean => true,
	isMobileDisplay: (): boolean => false,
	isMobileWindowPortrait: (): boolean => false,
	isThinMobileDisplay: (): boolean => false,
	getDisplayType: (): DisplayType => DisplayType.DESKTOP_DISPLAY,
	isConsolePage: (): boolean => false,
	isPolicyPage: (): boolean => false
})

// SECTION: Provider
export const UtilProvider = (props: any) => {
	// SECTION: Hooks
	const [userId, setUserId] = useState(null)
	const [windowWidth, setWindowWidth] = useState(0)
	const [windowHeight, setWindowHeight] = useState(0)
	const [userAccountType, setUserAccountType] = useState('' as UserAccountType)
	const [currentUrl, setCurrentUrl] = useState('')
	const [baseUrl, setBaseUrl] = useState('')
	const [currentPathname, setCurrentPathname] = useState('')

	const { setActiveBookDisplayTab } = useContext(UserLayoutStateContext)
	const { setError } = useContext(UserNotesContext)

	const router = useRouter()

	const { data: session, status } = useSession()

	// SUBSECTION: Base Site Page useEffects
	// USE EFFECT: Verify Url
	useEffect(() => {
		_useEffectLog('UTIL CONTEXT useEffect 0')
		verifyUrl()
	}, [])

	// USE EFFECT: Set current url and pathname
	// NOTE: Setting this on front-end to avoid sneaky hydration errors since the server doesn't have access to the window
	useEffect(() => {
		_useEffectLog('UTIL CONTEXT useEffect 1')
		if (windowExists()) {
			setBaseUrl(getBaseUrl())
			setCurrentUrl(`${getBaseUrl()}${getCurrentPagePathName()}`)
			setCurrentPathname(getCurrentPagePathName())
		}
	}, [router?.asPath])

	// USE EFFECT: Window resize handling -- triggers UI (really any front end changes -- ie. useEffect) changes when window is resized
	// NOTE: DONT DELETE - this also triggers UserSettingsContext to load the settings no matter where the user enters the site from
	useEffect(() => {
		_useEffectLog('UTIL CONTEXT useEffect 2')

		if (windowExists()) {
			setWindowWidth(getWindowWidth())
			setWindowHeight(getWindowHeight())
			window.addEventListener('resize', () => setWindowWidth(getWindowWidth()))
			window.addEventListener('resize', () => setWindowHeight(getWindowHeight()))
		}
	}, [getWindowWidth(), getWindowHeight()])

	// SUBSECTION: Base User useEffects
	// USE EFFECT: Add user email to errors sent to Sentry -- only applies to front end errors
	// NOTE: configureScope function not needed
	useEffect(() => {
		devLog(status)
		_useEffectLog('UTIL CONTEXT useEffect 3')
		if (status !== 'loading') {
			const email = session?.user?.email || 'No Session'

			devLog('Configuring scope with: ' + email)
			setUser({ email })
			setTag('email', email || '')
		}
	}, [status])

	// USE EFFECT: Validates Subscription Status site visit on refresh (as opposed to using Stripe webhooks)
	// NOTE: Excludes Typing Console page, which checks when user goes to it with or without refresh needed
	useEffect(() => {
		_useEffectLog('UTIL CONTEXT useEffect 4')
		if (status === 'authenticated' && !getCurrentPagePathName()?.includes('typing-console')) {
			stripeValidateSubscription(setError)
		}
	}, [status])

	// USE EFFECT: Get user's account type (must be done on front-end because using getStaticProps)
	// QUIRK: Frontend variable used for UI purposes
	useEffect(() => {
		_useEffectLog('UTIL CONTEXT useEffect 5')
		// Wont run unless user logged in -- therefor, shouldn't mess up SEO (aka slow down site for google crawlers)
		if (status === 'authenticated') {
			const handleGetUserAccountType = async () => {
				try {
					// Should only return FREE or PAID or throw an error.
					const accountTypeResult = await axios.get('/api/userAccount/getUserAccountType')

					devLog('ACCOUNT TYPE')
					devLog(accountTypeResult?.data?.accountType)

					// Shouldn't be necessary but here it is anyway
					if (!accountTypeResult?.data?.accountType) {
						throw Error(`Couldn't determine subscription type - accountType: ${accountTypeResult?.data?.accountType}`)
					}

					setUserAccountType(accountTypeResult?.data?.accountType)
				} catch (err) {
					// NOTE: If error occurs, custom book cards act as if unsubscribed., but account type is checked in Chapters and Console Pages
					handleErrorOnFrontend(err, "Couldn't determine subscription type.", setError)
					setUserAccountType(UserAccountType.FREE)
				}
			}
			handleGetUserAccountType()
		}
	}, [status])

	// USE EFFECT: Load userId if any
	useEffect(() => {
		_useEffectLog('UTIL CONTEXT useEffect 6')
		if (status === 'authenticated') {
			const initSetUserId = async () => {
				// QUIRK: Be careful -- If this fails it will log the user out and send them to the error page
				try {
					const result = await axios.get('/api/userAccount/getUserId')
					setUserId(result.data.userId)
				} catch (err) {
					// NOTE: Harsh error handling necessary because userId is used by non-subscribed functionality too (ie. checkpoints, bookmarks, etc)
					const curDomain = windowExists() ? getBaseUrl() : ''
					setActiveBookDisplayTab(BookDisplayTab.CLASSICS)
					handleErrorOnFrontend(err, 'A profile error has occurred. Please sign in again.', setError)
					signOut({ callbackUrl: `${curDomain}/error?code=2` })
				}
			}
			initSetUserId()
		}
	}, [status])

	// SECTION: Display Type Checkers
	// NOTE: Desktop: Above 1279 -> Tablet -> Mobile: Below 416

	const tabletWidthBreakpoint = 1278 // QUIRK: Needs to be one pixel less than what's in the css for some reason
	const isDesktopDisplay = () => windowWidth > tabletWidthBreakpoint

	const isTabletDisplay = () => windowWidth <= tabletWidthBreakpoint && windowWidth > mobileWidthBreakpoint

	const mobileWidthBreakpoint = 550 //NOTE: 416 was original mobile, but using 550 in styling to take into account smallTablets like surface duo
	const mobileHeightBreakpoint = 416
	const isMobileDisplay = () => windowWidth <= mobileWidthBreakpoint || windowHeight <= mobileHeightBreakpoint

	const isMobileWindowPortrait = () => windowWidth <= mobileWidthBreakpoint

	const thinMobileDisplayBreakpoint = 330
	const isThinMobileDisplay = () => windowWidth <= thinMobileDisplayBreakpoint

	const getDisplayType = () => {
		if (isDesktopDisplay()) {
			return DisplayType.DESKTOP_DISPLAY
		} else if (isTabletDisplay()) {
			return DisplayType.TABLET_DISPLAY
		} else if (isMobileDisplay()) {
			return DisplayType.MOBILE_DISPLAY
		} else {
			return DisplayType.THIN_MOBILE_DISPLAY
		}
	}

	// SECTION: isFirstVisit localStorage access
	const getIsFirstVisit = () => {
		const isFirstVisitResponse = getLocalStorage()?.getItem(`typelit-isfirstvisit`)
		const isFirstVisit = isFirstVisitResponse === null || isFirstVisitResponse === 'true' ? true : false // key wont exist (be null) if user's first visit

		return isFirstVisit
	}

	const setIsFirstVisitFalse = () => {
		getLocalStorage()?.setItem(`typelit-isfirstvisit`, 'false')
	}

	// SECTION: Misc
	const isConsolePage = () =>
		router.pathname === '/typing-console/[bookTitle]/[chapterIndex]/[consolePage_maxChapterIndex]'

	const isPolicyPage = () => {
		const policyPagePathnames = [
			'/policies/cookie-policy',
			'/policies/privacy-policy',
			'/policies/terms-and-conditions'
		]

		return policyPagePathnames.includes(router.pathname)
	}

	// NOTE: useMemo doesn't reduce renders + may mess up routing unless windowWidth included
	return (
		<UtilContext.Provider
			value={{
				windowWidth,
				windowHeight,
				userId,
				userAccountType,
				currentUrl,
				baseUrl,
				currentPathname,
				setWindowWidth,
				setWindowHeight,
				getIsFirstVisit,
				setIsFirstVisitFalse,
				isDesktopDisplay,
				isMobileDisplay,
				isMobileWindowPortrait,
				isThinMobileDisplay,
				getDisplayType,
				isConsolePage,
				isPolicyPage
			}}>
			{props.children}
			{renderLog('UTIL CONTEXT')}
		</UtilContext.Provider>
	)
}
