import { Typography, Button } from '@mui/material'
import { useContext } from 'react'
import { UserLayoutStateContext } from '../../contexts/UserLayoutStateContext'
import { UserSettingsContext } from '../../contexts/UserSettingsContext'
import { Palette, PaletteTheme } from '../../util/palette/Palette'
import { makeStyles } from 'makeStyles'
import { UtilContext } from '../../contexts/UtilContext'
import Link from 'next/link'
import { renderLog } from '../../util/utilFunctions'

// SECTION: Styles
const useStyles = (palette: PaletteTheme) => {
	const { text1, background10, border2, link2, link4, linkHoverTransition } = palette

	return makeStyles()({
		root: {
			display: 'flex',
			position: 'fixed',
			left: 0,
			bottom: 0,
			justifyContent: 'center',
			alignItems: 'center',
			width: '100vw',
			height: '3rem',
			backgroundColor: background10,
			borderTop: `solid 1px ${border2}`,
			padding: '0 15rem',
			['@media (max-width:1279px)']: {
				padding: '0 5rem'
			},
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				padding: '0 1.3rem'
			}
		},
		content: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			flexGrow: 1
		},
		text: {
			color: text1
		},
		link: {
			textDecoration: 'none',
			transition: linkHoverTransition,
			fontWeight: 'bold',
			color: link2,
			'&:hover': {
				color: link4
			}
		},
		buttonContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			width: '12rem',
			paddingTop: '0.3rem',
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				width: '10rem'
			}
		},
		button: {
			color: text1
		}
	})
}

// data-nosnippet prevents banner contents from showing up google search results
const CookiesBanner = () => {
	// SECTION: Hooks
	const { userSettingsObj } = useContext(UserSettingsContext) // Note: Keep to detect if dark mode
	const { getShowCookiesBanner, setShowCookiesBanner, setAllowAnalytics } = useContext(UserLayoutStateContext)
	const { isConsolePage, isMobileDisplay } = useContext(UtilContext)

	const { classes } = useStyles(Palette.getPalette())()

	// SECTION: Handlers
	const handleAcceptAnalytics = () => {
		setAllowAnalytics('true') // should be true by default, but here just in case
		setShowCookiesBanner(false)
	}

	const handleRejectAnalytics = () => {
		setAllowAnalytics('false')
		setShowCookiesBanner(false)
		window.location.reload()
	}

	// SECTION: Render UI
	return (
		<>
			{renderLog('COOKIES BANNER')}
			{getShowCookiesBanner() && !isConsolePage() && (
				<div className={classes.root} data-nosnippet>
					<div className={classes.content}>
						<Typography className={classes.text}>
							{!isMobileDisplay() && 'This site uses cookies to analyze site usage\u00a0\u00a0—\u00a0\u00a0'}
							<Link href={'/policies/cookie-policy'} className={classes.link} target='_blank' rel='nofollow'>
								Cookie Policy
							</Link>
						</Typography>
						<div className={classes.buttonContainer}>
							<Button className={classes.button} onClick={handleAcceptAnalytics}>
								Accept
							</Button>
							<Button className={classes.button} onClick={handleRejectAnalytics}>
								Decline
							</Button>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default CookiesBanner
