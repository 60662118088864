import React, { ElementType, useContext, useEffect, useState } from 'react'
import { Typography, Button } from '@mui/material'
import Link from 'next/link'
import { Palette, PaletteTheme } from '../../util/palette/Palette'
import { UserSettingsContext } from '../../contexts/UserSettingsContext'
import { NightsStayOutlined, WbSunnyOutlined } from '@mui/icons-material'
import { useSession } from 'next-auth/react'
import { devLog, getBaseUrl, renderLog } from '../../util/utilFunctions'
import ProfileDropdownMenu from './ProfileDropdownMenu'
import { UtilContext } from '../../contexts/UtilContext'
import { makeStyles } from 'makeStyles'
import DisabledFeatureTooltip from './tooltips/DisabledFeatureTooltip'
import { isImportAndFormatAccessable } from '../../util/featureFlags'
import { UserAccountType } from '../../types'
import { isIOS, isSafari } from 'react-device-detect'

// SECTION: Styles
const useStyles = (palette: PaletteTheme) => {
	const { background2, headerBarHeight, header1, font1, disabled1 } = palette

	return makeStyles()({
		root: {
			minHeight: headerBarHeight,
			maxHeight: headerBarHeight,
			paddingRight: 0,
			backgroundColor: background2,
			color: header1,
			boxShadow:
				'0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)' // Paper elevation 4
		},
		content: {
			display: 'flex',
			justifyContent: 'space-between',
			minHeight: '3rem',
			paddingLeft: '1.5rem',
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				paddingLeft: '1rem'
			}
		},
		logoContainer: {
			display: 'flex',
			alignItems: 'center'
		},
		logo: {
			fontFamily: font1,
			fontSize: 24,
			fontWeight: 'bold',
			cursor: 'pointer'
		},
		innerLink: {
			color: 'inherit', // standard white against headerbar
			textDecoration: 'none'
		},
		rightLinkContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			minWidth: '21rem', // Don't use minWidth here or Safari will break -- see _safariRightLinkContainer and _safariSignedOutAltStyling below
			paddingLeft: '2rem',
			paddingRight: '2rem',
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				minWidth: '7.5rem',
				paddingLeft: 0,
				paddingRight: '1rem'
			},
			['@media (max-width:300px)']: {
				minWidth: '6.5rem',
				paddingRight: '0.5rem'
			}
		},
		// NOTE: Because minWidth breaks safari
		// QUIRK: If these seem messed up in LambdaTest, it might be because it's slow and not properly calculating changes in window size
		_safariRightLinkContainer: {
			maxWidth: '21rem',
			width: '100%',
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				width: '11rem',
				paddingLeft: 0,
				paddingRight: '1rem'
			},
			['@media (max-width:300px)']: {
				width: '7rem',
				minWidth: '6.5rem',
				paddingRight: '0.5rem'
			}
		},
		_signedInAltStyling: {
			paddingRight: '3rem', //Fixed spacing when only 2 links in header
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				paddingRight: '1rem'
			}
		},
		_safariSignedOutAltStyling: {
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				width: '8rem'
			}
		},
		headerLink: {
			fontSize: 16,
			cursor: 'pointer',
			padding: '0.5rem'
		},
		icon: {
			color: header1,
			minWidth: '1.5rem',
			paddingLeft: '0.4rem',
			paddingRight: 0,
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				paddingLeft: 0
			}
		}
	})
}

const HeaderBar = () => {
	// SECTION: Hooks
	const {
		userAccountType,
		currentUrl,
		currentPathname,
		isMobileDisplay,
		isThinMobileDisplay,
		isConsolePage,
		isPolicyPage
	} = useContext(UtilContext)
	const { getDarkModeEnabled, toggleDarkModeEnabled } = useContext(UserSettingsContext)

	const [logoElementType, setLogoElementType] = useState<ElementType>('p')

	const { data: session, status } = useSession()

	const { classes, cx } = useStyles(Palette.getPalette())()

	// USE EFFECT: Logo (TypeLit.io) is an h1 when on the homepage -- and a span element on every other page
	useEffect(() => {
		currentPathname === '/' ? setLogoElementType('h1') : setLogoElementType('span')
	}, [currentPathname])

	// SECTION: Render UI
	return (
		<>
			{!isConsolePage() && !isPolicyPage() && (
				<header className={classes.root}>
					{renderLog('HEADER BAR')}
					<div className={classes.content}>
						<>
							<div className={classes.logoContainer}>
								<Link href={'/'} className={classes.innerLink}>
									<Typography
										className={classes.logo}
										component={logoElementType}
										// Refreshes page if logo pressed while on books page (resets book filters)
										onClick={currentPathname === '/' ? () => window.location.reload() : null}>
										TypeLit.io
									</Typography>
								</Link>
							</div>
							{status !== 'loading' && (
								<div
									className={cx(
										classes.rightLinkContainer,
										session !== null && classes._signedInAltStyling,
										(isIOS || isSafari) && classes._safariRightLinkContainer,
										session === null && (isIOS || isSafari) && classes._safariSignedOutAltStyling
									)}>
									{!isMobileDisplay() && (
										<DisabledFeatureTooltip placement='bottom' disabled={isImportAndFormatAccessable}>
											<Link
												href={
													currentPathname === '/users/account' && userAccountType === UserAccountType.FREE
														? '/premium'
														: '/bring-your-own-books/import'
												}
												className={classes.innerLink}>
												<Typography className={classes.headerLink}>Import</Typography>
											</Link>
										</DisabledFeatureTooltip>
									)}
									{/*Shows on all screen sizes -- except thin mobile*/}
									{!isThinMobileDisplay() ? (
										<Link href='/about' className={classes.innerLink}>
											<Typography className={classes.headerLink}>About</Typography>
										</Link>
									) : (
										<div />
									)}
									{!isMobileDisplay() && session === null ? (
										<>
											{/*DESKTOP - NO SESSION*/}
											{/* encodeURIComponent here in case params are needed on non-typing console pages (also, you need them for CustomBook titles) */}
											{/* Use regular link (which reloads page) to go to login page, otherwise callback isn't passed properly*/}
											<a
												className={classes.innerLink}
												href={`/api/auth/signin?callbackUrl=${
													currentUrl.includes('/auth/') ? getBaseUrl() : encodeURIComponent(currentUrl) // Use base url for callbackUrl if already on SignIn Page
												}`}>
												<Typography className={classes.headerLink}>
													<b>Sign In</b>
												</Typography>
											</a>

											<Button
												className={classes.icon}
												aria-label={'Dark Mode'}
												disableTouchRipple
												onClick={toggleDarkModeEnabled}>
												{getDarkModeEnabled() ? <NightsStayOutlined /> : <WbSunnyOutlined />}
											</Button>
										</>
									) : (
										<>
											{/* DESKTOP - SESSION + MOBILE - SESSION / NO SESSION */}
											<ProfileDropdownMenu />
										</>
									)}
								</div>
							)}
						</>
					</div>
				</header>
			)}
		</>
	)
}

export default HeaderBar
