import { handleErrorOnFrontend } from './../../util/errorHandlers'
import { loadStripe } from '@stripe/stripe-js'
import axios from 'axios'
import { Session } from 'next-auth'
import { NextRouter } from 'next/router'
import { SetErrorCb } from '../../util/errorHandlers'
import { apiLog, devLog } from '../../util/utilFunctions'

// SECTION: Loader
const loadStripeOnFrontend = async () => {
	let stripePromise

	if (process.env.NEXT_PUBLIC_STRIPE_LIVE_PUBLISHABLE_KEY) {
		stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_LIVE_PUBLISHABLE_KEY)
	} else if (process.env.NEXT_PUBLIC_STRIPE_TEST_PUBLISHABLE_KEY) {
		stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_TEST_PUBLISHABLE_KEY)
	}

	// return Stripe instance
	apiLog('stripeFrontend loadStripeOnFrontend')
	return await stripePromise
}

// SECTION: Handlers
export const stripeSubscribe = async (
	session: Session | null,
	router: NextRouter,
	currentUrl: string,
	setErrorCb: SetErrorCb
) => {
	// Ensure user logged in -- more for the BYOB info page than the Accounts Page
	if (!session) {
		router.push(`/api/auth/signin?callbackUrl=${encodeURIComponent(currentUrl)}`)
		return
	}

	// Create payment session
	let checkoutSessionId: string | undefined
	try {
		const response = await axios.get('/api/payments/stripeCreateSubscribeSession')
		checkoutSessionId = response.data.checkoutSessionId
	} catch (err) {
		handleErrorOnFrontend(err, 'Something went wrong -- could not subscribe.', setErrorCb)
		return
	}

	try {
		// Active subscription already exists or was re-enabled => No new checkout session created
		if (!checkoutSessionId) {
			router.push('/users/account')
			return
		} else {
			// No active subscription => Redirect to stripe checkout
			const stripe = await loadStripeOnFrontend()

			apiLog('stripeFrontend redirectToCheckout')
			await stripe.redirectToCheckout({
				sessionId: checkoutSessionId
			})
		}
	} catch (err) {
		// NOTE: Will catch here if someone tries to unsub or resub after Subscription object already removed from stripe
		handleErrorOnFrontend(err, 'Something went wrong -- could not subscribe.', setErrorCb)
	}
}

export const stripeResubscribe = async (setErrorCb: SetErrorCb) => {
	try {
		await axios.get('/api/payments/stripeResubscribe')
		window.location.reload()
	} catch (err) {
		handleErrorOnFrontend(err, 'Something went wrong -- could not resubscribe.', setErrorCb)
	}
}

export const stripeUnsubscribe = async (setErrorCb: SetErrorCb) => {
	try {
		await axios.get('/api/payments/stripeUnsubscribe')
		window.location.reload()
	} catch (err) {
		handleErrorOnFrontend(
			err,
			'Something went wrong -- could not unsubscribe. Please email contact@typelit.io for support.',
			setErrorCb
		)
	}
}

export const stripeValidateSubscription = async (setErrorCb: SetErrorCb) => {
	try {
		devLog('FE VALIDATING SUBSCRIPTION')
		const result = await axios.get('/api/payments/stripeValidateSubscription')

		if (result.data.changeOccurred) {
			window.location.reload() // to update UI
		}
	} catch (err) {
		// NOTE: Displays error but doesn't do anything on error since it's only validation that failed. Site may still work fine. Critical error msg sent to Sentry.
		handleErrorOnFrontend(err, 'A subscription validation error occurred.', setErrorCb)
	}
}
