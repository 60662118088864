import { ObjectId } from 'mongodb'

// SECTION: General types used throughtout app
// ENUMS

// NOTE: If you want to check the status of a logged out user, use the "status" variable from next/auth session
export enum UserAccountType {
	FREE = 'FREE',
	PAID = 'PAID'
}

// TYPABLE JSX ELEMENT TYPES
export interface CookedPage {
	[key: string]: JSX.Element[]
}

// SECTION: Derived from DB
// RAW TEXT TYPES
export interface RawPage {
	lines: string[]
}

export interface RawChapter {
	_id: ObjectId
	chapterIndex: number
	chapterTitle: string
	pages: RawPage[]
}

export interface RawBook {
	_id: ObjectId
	bookTitle: string
	language: string | undefined
	chapters: RawChapter[]
}

// DETAILS TYPES
export interface BookDetails {
	_id: ObjectId
	rawBookId: ObjectId
	bookTitle: string
	displayTitle: string
	author: string
	language: string
	synopsis: string
	chapterCount: number
	pageCount: number
	avgWPS: number | null
	numUsersCompleted: number
	chapterDetails: ChapterDetails[]
}

export interface ChapterDetails {
	rawChapterId: ObjectId
	chapterTitle: string
	pageCount: number
	avgWPS: number | null
}

export interface DbCustomBookDetails {
	bookTitle: string
	displayTitle: string
	chapterCount: number
	pageCount: number
	hidden: boolean
}

// SECTION: CUSTOM DERIVED FROM BYOB
export interface CustomRawBook {
	bookTitle: string // Needed for upload typelit file to know what to call custom book idb entry
	displayTitle: string
	author: string
	language: string
	synopsis: string
	chapterCount: number
	pageCount: number
	chapters: CustomRawChapter[]
}

export interface CustomRawChapter {
	chapterIndex: number
	chapterTitle: string
	pageCount: number
	pages: CustomRawPage[]
}

export interface CustomRawPage {
	lines: string[]
}

// SECTION: CUSTOM AS FOUND IN IDB
export interface IdbCustomBook {
	'book-details': {
		bookTitle: string // Needed for upload typelit file to know what to call custom book idb entry
		displayTitle: string
		author: string
		language: string
		synopsis: string
		chapterCount: number
		pageCount: number
		coverName: string
		customCoverFile?: File
		customCoverBuffer?: any // UploadedFile imported from express-fileupload back when we had it // used in custom book encryption -- never actually saved to idb
	}
	'chapter-titles': {
		[chapterIndex: number]: string
	}
	chapters: {
		[chapterIndex: number]: {
			chapterIndex: number
			pageCount: number
			pages: CustomRawPage[]
		}
	}
}
