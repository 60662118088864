import { ButtonBase, Typography } from '@mui/material'
import { Palette, PaletteTheme } from '../../util/palette/Palette'
import { makeStyles } from 'makeStyles'
import { renderLog } from '../../util/utilFunctions'
import { memo, useContext } from 'react'
import { UserSettingsContext } from '../../contexts/UserSettingsContext'

// SECTION: Styles
const useStyles = (palette: PaletteTheme) => {
	const { mainBackgroundColor, text1, tabHover1, cardHoverTransition } = palette

	return makeStyles()({
		tabBase: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			minWidth: '6rem',
			cursor: 'pointer',
			color: text1,
			borderRadius: '4px 4px 0 0',
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				minWidth: '4.5rem',
				'& > p': {
					fontSize: 13
				}
			}
		},
		_activeTab: {
			borderTop: `1px solid ${text1}`,
			borderRight: `1px solid ${text1}`,
			borderLeft: `1px solid ${text1}`
		},
		_inactiveTab: {
			borderTop: 'none',
			borderRight: 'none',
			borderBottom: `1px solid ${text1}`,
			'&:hover': {
				backgroundColor: tabHover1,
				transition: cardHoverTransition
			}
		},
		tabFillerLeft: {
			width: '0.5rem',
			borderBottom: `1px solid ${text1}`,
			marginLeft: '0.1rem',
			['@media (max-width:300px)']: {
				width: 0
			}
		},

		tabFillerRight: {
			display: 'flex',
			alignItems: 'flex-end',
			width: '100%',
			maxWidth: '12rem',
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				maxWidth: '2rem'
			},
			['@media (max-width:300px)']: {
				width: 0
			}
		},
		gradientTabBorder: {
			width: '100%',
			height: '1px',
			backgroundImage: `linear-gradient(to right, ${text1} 10%, ${mainBackgroundColor})`
		}
	})
}

// SECTION: Props
export enum BookDisplayTab {
	CLASSICS = 'CLASSICS',
	CUSTOM = 'CUSTOM'
}

interface BookDisplayTabsProps {
	activeTab: BookDisplayTab | null
	setActiveTabCb: Function
}

const BooksDisplayTabs = memo(({ activeTab, setActiveTabCb }: BookDisplayTabsProps) => {
	// SECTION: Hooks
	const { userSettingsObj } = useContext(UserSettingsContext)
	const { classes, cx } = useStyles(Palette.getPalette())()

	// SECTION: Render UI
	return (
		<>
			{renderLog('BOOKS DISPLAY TABS')}
			<div className={classes.tabFillerLeft} />
			<ButtonBase
				className={cx(
					classes.tabBase,
					activeTab === BookDisplayTab.CLASSICS ? classes._activeTab : classes._inactiveTab
				)}
				focusRipple={true}
				onClick={() => setActiveTabCb(BookDisplayTab.CLASSICS)}>
				<Typography>Classics</Typography>
			</ButtonBase>
			<ButtonBase
				className={cx(classes.tabBase, activeTab === BookDisplayTab.CUSTOM ? classes._activeTab : classes._inactiveTab)}
				focusRipple={true}
				onClick={() => setActiveTabCb(BookDisplayTab.CUSTOM)}>
				<Typography>Custom</Typography>
			</ButtonBase>
			<div className={classes.tabFillerRight}>
				<div className={classes.gradientTabBorder} />
			</div>
		</>
	)
})

export default BooksDisplayTabs
