import { useSession } from 'next-auth/react'
import { createContext, useContext, useEffect, useState } from 'react'
import { devLog, getLocalStorage, renderLog, _useEffectLog } from '../util/utilFunctions'
import { UtilContext } from './UtilContext'

// SECTION: Types
interface BookmarksObj {
	[bookTitle: string]: {
		[chapterIndex: number]: {
			pageIndex: number // only one page can be bookmaked per chapter at a time
		}
	}
}

// SECTION: Context
export const UserBookmarksContext = createContext({
	bookmarksObj: {} as BookmarksObj,
	loadingBookmarks: true,
	setBookmark: (bookTitle: string, chapterIndex: number, currentPageIndex: number) => {},
	setNextChapterBookmarked: (bookTitle: string, chapterIndex: number) => {},
	removeBookmarkFromChapter: (bookTitle: string, chapterIndex: number): any => {},
	isChapterBookmarked: (bookTitle: string, chapterIndex: number): any => {}, //need to specify return type when getting from context, otherwise typescript assumes void
	getBookmarkedPageIndex: (bookTitle: string, chapterIndex: number): any => {}
})

// SECTION: Provider
export const UserBookmarksProvider = (props: any) => {
	// SECTION: Hooks
	const [bookmarksObj, setBookmarksObj] = useState({} as BookmarksObj)
	const [loadingBookmarks, setLoadingBookmarks] = useState(true)

	const { userId } = useContext(UtilContext)

	const { data: session, status } = useSession()

	// USE EFFECT: Loads bookmarks from local storage
	useEffect(() => {
		_useEffectLog('USER BOOKMARKS CONTEXT useEffect 1')
		// NOTE: Don't wait for loading to do data stuff (it'll just mess things up) -- do that on the UI layer
		if (userId) {
			// Loads User Bookmarks if userId found in SettingsContext
			//NOTE: you could trigger popup with UserBookmarkError, but it might be annoying + bookmarks will load and save publically anyway (so don't do it)
			setBookmarksObj(fetchBookmarksFromLocalStorage(userId)) //if userId null, will load public bookmarks
		} else if (session === null) {
			// Loads Public bookmarks if done loading (session is null vs undefined) and no bookmark found
			// Not checking var loading because it was true even when session was null
			setBookmarksObj(fetchBookmarksFromLocalStorage())
		}
	}, [session, userId])

	// USE EFFECT: Flips setLodingBookmarks flag to false if some bookmarks have been loaded (bookmarkInit added in fetchBookmarksFromLocalStorage to ensure trigger if no previous bookmarks created)
	useEffect(() => {
		_useEffectLog('USER BOOKMARKS CONTEXT useEffect 2')
		Object.keys(bookmarksObj).length > 0 && setLoadingBookmarks(false)
	}, [bookmarksObj])

	// SECTION: Local Storage Getters/Setters
	const fetchBookmarksFromLocalStorage = (userId?: string) => {
		let bookmarks

		if (userId) {
			// Loads user bookmarks
			bookmarks = getLocalStorage()?.getItem(`typelit-userbookmarks-${userId}`)
		} else {
			// Loads public bookmarks
			bookmarks = getLocalStorage()?.getItem('typelit-userbookmarks')
		}

		// If no bookmarks found, create empty object (bookmarksInit added to ensure setLoadingBookmarks triggers in useEffect 2)
		return bookmarks && Object.keys(JSON.parse(bookmarks)).length > 0
			? JSON.parse(bookmarks)
			: ({ bookmarksInit: {} } as BookmarksObj)
	}

	const saveBookmarksToLocalStorage = () => {
		if (userId) {
			getLocalStorage()?.setItem(`typelit-userbookmarks-${userId}`, JSON.stringify(bookmarksObj))
		} else {
			getLocalStorage()?.setItem('typelit-userbookmarks', JSON.stringify(bookmarksObj))
		}
	}

	// SECTION: Local Object Getters/Setters
	const setBookmark = (bookTitle: string, chapterIndex: number, pageIndex: number) => {
		// Creates a bookTitle key if none exists
		if (!bookmarksObj[bookTitle]) {
			bookmarksObj[bookTitle] = {}
		}

		bookmarksObj[bookTitle][chapterIndex] = { pageIndex } // Don't have to wait for rerender to save to local storage if changing directly

		// removes the init object if there's anything else in the object
		// NOTE: Because of this bookmarksInit will never actually end up in localStorage
		if (bookmarksObj.bookmarksInit) {
			delete bookmarksObj.bookmarksInit
		}

		saveBookmarksToLocalStorage()
	}

	const setNextChapterBookmarked = (bookTitle: string, chapterIndex: number) => {
		const nextChapterIndex = +chapterIndex + 1
		const firstPageIndex = 0
		setBookmark(bookTitle, nextChapterIndex, firstPageIndex)
	}

	const removeBookmarkFromChapter = (bookTitle: string, chapterIndex: number) => {
		if (bookmarksObj?.[bookTitle]?.[chapterIndex]) {
			delete bookmarksObj[bookTitle][chapterIndex]

			if (Object.keys(bookmarksObj[bookTitle])?.length === 0) {
				delete bookmarksObj[bookTitle]
			}

			// Removes bookmark from localStorage if empty (since useEffect 2 requires bookmarkObj to have at least one key)
			if (Object.keys(bookmarksObj)?.length === 0) {
				getLocalStorage()?.removeItem(userId ? `typelit-userbookmarks-${userId}` : 'typelit-userbookmarks')
			} else {
				saveBookmarksToLocalStorage()
			}
		}
	}

	const isChapterBookmarked = (bookTitle: string, chapterIndex: number) => {
		return bookmarksObj?.[bookTitle]?.[chapterIndex] ? true : false
	}

	const getBookmarkedPageIndex = (bookTitle: string, chapterIndex: number) => {
		return bookmarksObj?.[bookTitle]?.[chapterIndex]?.pageIndex
	}

	return (
		<UserBookmarksContext.Provider
			value={{
				bookmarksObj,
				loadingBookmarks,
				setBookmark,
				setNextChapterBookmarked,
				removeBookmarkFromChapter,
				isChapterBookmarked,
				getBookmarkedPageIndex
			}}>
			{props.children}
			{renderLog('BOOKMARK CONTEXT')}
		</UserBookmarksContext.Provider>
	)
}
