import { Button, TextField } from '@mui/material'
import { makeStyles } from '../../../makeStyles'
import { Palette, PaletteTheme } from '../../../util/palette/Palette'
import { MutableRefObject, useContext, useEffect, useRef, useState } from 'react'
import { UserSettingsContext } from '../../../contexts/UserSettingsContext'
import { _useEffectLog, devLog, renderLog } from '../../../util/utilFunctions'
import { UserThemesAndFontsContext } from '../../../contexts/UserThemesAndFontsContext'
import { HexColorPicker } from 'react-colorful'
import { ArrowLeftRounded, ReplayRounded } from '@mui/icons-material'
import { UtilContext } from '../../../contexts/UtilContext'
import { validateCustomColorInput, validateTextInput } from '../../../functions/sharedFunctions/sharedInputValidators'
import DefaultTooltip from '../../sharedComponents/tooltips/DefaultTooltip'
import { FocusTrap } from '@mui/base/FocusTrap'

// SECTION: Styles
const useStyles = (palette: PaletteTheme) => {
	const { text1 } = palette

	return makeStyles()({
		root: {
			display: 'flex',
			flexDirection: 'column',
			width: 200,
			'& .MuiFormLabel-root': {
				color: text1,
				'&.Mui-focused': {
					color: text1,
					fontWeight: 'bold'
				}
			},
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: `${text1} !important`
			},
			'& .MuiInputBase-input': {
				color: text1,
				paddingRight: 42
			}
		},
		inputContainer: {
			'> :not(:last-child)': {
				marginBottom: 10
			}
		},
		buttonTooltipContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			paddingRight: 4
		},
		backButton: {
			color: text1,
			alignSelf: 'flex-start',
			marginBottom: 4,
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				alignSelf: 'flex-end'
			}
		},
		toolTip: {
			'& svg': {
				width: 28,
				height: 28,
				color: text1,
				marginBottom: 4,
				padding: 4
			}
		},
		backArrowIcon: {
			width: 20,
			height: 25,
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				display: 'none'
			}
		},
		colorInputContainer: {
			display: 'flex',
			position: 'relative'
		},
		resetDefaultsButton: {
			position: 'absolute',
			right: 0,
			minWidth: 36,
			height: 56,
			color: text1
		},
		colorPicker: {
			// @ts-ignore // Libary complaining about !important
			position: 'fixed !important',
			'&.react-colorful': {
				width: 200,
				height: 200,
				['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
					width: 160,
					height: 160
				}
			},
			'& .react-colorful__saturation-pointer': {
				width: 10,
				height: 10,
				borderRadius: 2
			},
			'& .react-colorful__hue-pointer': {
				width: 12,
				height: '100%',
				borderRadius: 1
			}
		}
	})
}

// SECTION: Types
export enum CssVarName {
	UNTYPED = '--untyped',
	TYPED = '--typed',
	CURSOR = '--cursor',
	ERROR = '--error'
}

export enum JsVarName {
	CUSTOM_LABEL = 'customLabel',
	CONSOLE_MAIN = 'consoleMain',
	UNTYPED = 'untyped',
	TYPED = 'typed',
	CURSOR = 'cursor',
	ERROR = 'error',
	MENU_MAIN = 'menuMain',
	MENU_TEXT = 'menuText'
}

interface ThemeEditorProps {
	setThemeEditorOpenCb: (value: boolean) => void
}

const ThemeEditor = ({ setThemeEditorOpenCb }: ThemeEditorProps) => {
	// SECTION: Hooks
	const { userSettingsObj, getConsoleTheme } = useContext(UserSettingsContext)
	const {
		customThemesObj,
		initialColorsObj,
		updateCustomThemePropertyInStateAndLocalStorage,
		removeCustomThemePropertyFromStateAndLocalStorage,
		getCustomStyleValueOfCurrentTheme,
		updateAndOrApplyEditedCssThemeClasses,
		updateAndApplyEditedJsThemeRule,
		getPremiumStyleDefaultLabel
	} = useContext(UserThemesAndFontsContext)
	const { isMobileDisplay } = useContext(UtilContext)

	const [colorPickerOpen, setColorPickerOpen] = useState(false)
	const [activeInput, setActiveInput] = useState(null as MutableRefObject<any> | null)
	const [pendingColor, setPendingColor] = useState(null as string | null) // Only here to make state update on color picker mouse drag
	const [lastValidColor, setLastValidColor] = useState(null as string | null)
	const [isFirstRender, setIsFirstRender] = useState(true)
	const [mouseDown, setMouseDown] = useState(false)
	const [reRenderVar, setReRenderVar] = useState(false)

	const customThemeNameInputRef = useRef<any>(null)
	const backgroundInputRef = useRef<any>(null)
	const untypedInputRef = useRef<any>(null)
	const typedInputRef = useRef<any>(null)
	const cursorInputRef = useRef<any>(null)
	const errorInputRef = useRef<any>(null)
	const menuBackgroundInputRef = useRef<any>(null)
	const menuTextInputRef = useRef<any>(null)

	const { cx, classes } = useStyles(Palette.getConsolePalette(getConsoleTheme()))()

	// USE EFFECT: Set prev value on input refs + Force input value prop to update now that refs are loaded
	useEffect(() => {
		_useEffectLog('THEME EDITOR useEffect 1')
		colorInputs.forEach((inputEntry) => {
			const { inputRef } = inputEntry
			if (inputRef?.current) inputRef.current.prev = inputRef.current.value
		})

		setIsFirstRender(false)
	}, [])

	// SECTION: Functionality
	// Allow onMouseUp to trigger outside the color picker
	const onColorPickerMouseDown = () => {
		if (colorPickerOpen) {
			document.addEventListener('mouseup', onColorPickerMouseUp)
		}
	}

	const onColorPickerMouseUp = () => {
		document.removeEventListener('mouseup', onColorPickerMouseUp)
		if (activeInput && colorPickerOpen) {
			const newColor = activeInput.current.value

			updateAndApplyCustomThemeUiAndStateAndLocalStorage(newColor)
			setPendingColor(null)
		}
	}

	const updateAndApplyCustomThemeUiAndStateAndLocalStorage = (newColor: string) => {
		const { jsVarName, cssVarName } = getJsAndCssVarNamesFromActiveInput()
		updateAndApplyEditedJsThemeRule(jsVarName, newColor)
		if (cssVarName) updateAndOrApplyEditedCssThemeClasses(customThemesObj, cssVarName, newColor)
		updateCustomThemePropertyInStateAndLocalStorage(jsVarName, newColor)
	}

	const inputHasFocus = (inputRef: MutableRefObject<any> | null) => {
		return (
			inputRef?.current?.labels[0].textContent ===
			(document.activeElement as HTMLInputElement)?.labels?.[0]?.textContent
		)
	}

	// SECTION: Getter Maps
	const {
		initialConsoleMain,
		initialUntypedColor,
		initialTypedColor,
		initialCursorColor,
		initialErrorColor,
		initialMenuMain,
		initialMenuText
	} = initialColorsObj[getConsoleTheme()]

	const labelInitialColorMap: { [label: string]: string | undefined } = {
		Background: initialConsoleMain,
		Untyped: initialUntypedColor,
		Typed: initialTypedColor,
		Cursor: initialCursorColor,
		Error: initialErrorColor,
		'Menu Background': initialMenuMain,
		'Menu Text': initialMenuText
	}

	const inputLabelToJsVarName: { [label: string]: JsVarName } = {
		Background: JsVarName.CONSOLE_MAIN,
		Untyped: JsVarName.UNTYPED,
		Typed: JsVarName.TYPED,
		Cursor: JsVarName.CURSOR,
		Error: JsVarName.ERROR,
		'Menu Background': JsVarName.MENU_MAIN,
		'Menu Text': JsVarName.MENU_TEXT
	}

	const inputLabelToCssVarName: { [label: string]: CssVarName } = {
		Untyped: CssVarName.UNTYPED,
		Typed: CssVarName.TYPED,
		Cursor: CssVarName.CURSOR,
		Error: CssVarName.ERROR
	}

	// SECTION: Getters
	const getInitalColorFromInputRef = (inputRef: MutableRefObject<any> | null) => {
		return labelInitialColorMap[getInputLabelFromRef(inputRef)]
	}

	const getInputLabelFromRef = (inputRef: MutableRefObject<any> | null) => {
		return inputRef?.current?.labels[0].textContent
	}

	const getJsAndCssVarNamesFromActiveInput = () => {
		const activeInputLabel = getInputLabelFromRef(activeInput)
		return { jsVarName: inputLabelToJsVarName[activeInputLabel], cssVarName: inputLabelToCssVarName[activeInputLabel] }
	}

	const getJsAndCssVarNamesFromInputLabel = (inputLabel: string) => {
		return { jsVarName: inputLabelToJsVarName[inputLabel], cssVarName: inputLabelToCssVarName[inputLabel] }
	}

	// SECTION: UI Getters
	const getColorPicker = () => (
		<HexColorPicker
			className={classes.colorPicker}
			style={{
				left: activeInput?.current.getBoundingClientRect()?.left - (isMobileDisplay() ? 171 : 225),
				top: activeInput?.current.getBoundingClientRect()?.top - 25
			}}
			color={
				activeInput?.current.value ??
				getCustomStyleValueOfCurrentTheme(getJsAndCssVarNamesFromActiveInput().jsVarName) ??
				getInitalColorFromInputRef(activeInput)
			}
			onMouseDown={() => {
				onColorPickerMouseDown()
			}}
			onChange={(newColor) => {
				if (activeInput) {
					activeInput.current.value = newColor
					setPendingColor(newColor)
				}
			}}
		/>
	)

	// SECTION: Color Input Handlers
	const onColorInputTabFocus = (inputRef: MutableRefObject<any> | null) => {
		setColorPickerOpen(true)
		setActiveInput(inputRef)
	}

	const onColorInputMouseDown = (inputRef: MutableRefObject<any> | null) => {
		inputHasFocus(inputRef) ? setColorPickerOpen(!colorPickerOpen) : setColorPickerOpen(true)
		setActiveInput(inputRef)
	}

	const onColorInputChange = () => {
		if (activeInput) {
			validateCustomColorInput(activeInput)
			let newColor = activeInput.current.value

			const invalidColorLengths = [1, 2, 3, 6, 8] // Includes the # sign

			if (invalidColorLengths.includes(newColor.length)) {
				newColor = lastValidColor ?? activeInput.current.value
			} else {
				setLastValidColor(activeInput.current.value)
			}

			updateAndApplyCustomThemeUiAndStateAndLocalStorage(newColor)
		}
	}

	const getInputValueConditional = (inputRef: MutableRefObject<any> | null) => {
		const inputLabel = getInputLabelFromRef(inputRef)
		const jsVarName = getJsAndCssVarNamesFromInputLabel(inputLabel).jsVarName

		return (
			inputRef?.current?.value ||
			getCustomStyleValueOfCurrentTheme(jsVarName) ||
			getInitalColorFromInputRef(inputRef) ||
			'' // Keep this or you'll get a controlled/uncontrolled input error in browser
		)
	}

	const resetDefaultColor = (inputRef: MutableRefObject<any> | null) => {
		if (inputRef?.current) {
			const defaultColor = getInitalColorFromInputRef(inputRef)
			inputRef.current.value = defaultColor

			const { jsVarName, cssVarName } = getJsAndCssVarNamesFromInputLabel(getInputLabelFromRef(inputRef))

			removeCustomThemePropertyFromStateAndLocalStorage(jsVarName)
			updateAndApplyEditedJsThemeRule(jsVarName, defaultColor)
			updateAndOrApplyEditedCssThemeClasses(customThemesObj, cssVarName, defaultColor)
		}
	}

	// SECTION: Render UI
	const colorInputs = [
		{ label: 'Background', inputRef: backgroundInputRef },
		{ label: 'Untyped', inputRef: untypedInputRef },
		{ label: 'Typed', inputRef: typedInputRef },
		{ label: 'Cursor', inputRef: cursorInputRef },
		{ label: 'Error', inputRef: errorInputRef },
		{ label: 'Menu Background', inputRef: menuBackgroundInputRef },
		{ label: 'Menu Text', inputRef: menuTextInputRef }
	]

	return (
		<FocusTrap open>
			<div className={classes.root} aria-label='theme editor' tabIndex={-1}>
				{renderLog('SETTINGS EDITOR')}
				<div className={classes.buttonTooltipContainer}>
					<Button className={classes.backButton} onClick={() => setThemeEditorOpenCb(false)}>
						<ArrowLeftRounded className={classes.backArrowIcon} viewBox='8 6 12 12' />
						Back
					</Button>
					{!isMobileDisplay() && (
						<DefaultTooltip
							label={'You can reset all themes back to their defaults from the Account Page.'}
							className={classes.toolTip}
							iconName={'InfoOutlined'}
							placement='top'
						/>
					)}
				</div>

				<div className={classes.inputContainer}>
					<div className={classes.colorInputContainer}>
						<TextField
							label={'Theme Name'}
							name={'customThemeName'}
							autoComplete='off'
							value={
								customThemeNameInputRef.current?.value ??
								getCustomStyleValueOfCurrentTheme(JsVarName.CUSTOM_LABEL) ??
								getPremiumStyleDefaultLabel(getConsoleTheme())?.toUpperCase()
							}
							onChange={() => {
								validateTextInput(customThemeNameInputRef)
								updateCustomThemePropertyInStateAndLocalStorage(
									JsVarName.CUSTOM_LABEL,
									customThemeNameInputRef.current.value
								)
							}}
							inputRef={customThemeNameInputRef}
						/>
						<Button
							className={classes.resetDefaultsButton}
							onClick={() => {
								customThemeNameInputRef.current.value = getPremiumStyleDefaultLabel(getConsoleTheme())?.toUpperCase()
								removeCustomThemePropertyFromStateAndLocalStorage(JsVarName.CUSTOM_LABEL)
								setReRenderVar(!reRenderVar)
							}}
							aria-label='reset to default'>
							<ReplayRounded />
						</Button>
					</div>
					{colorInputs.map(({ label, inputRef }) => (
						<div className={classes.colorInputContainer} key={label}>
							<TextField
								label={label}
								name='customColorInput' // name only used by input validators
								value={getInputValueConditional(inputRef)}
								autoComplete='off'
								onMouseDown={() => {
									setMouseDown(true)
									onColorInputMouseDown(inputRef)
								}}
								onMouseUp={() => setMouseDown(false)}
								onFocus={() => {
									if (mouseDown) return
									onColorInputTabFocus(inputRef)
								}}
								onChange={onColorInputChange}
								inputRef={inputRef}
							/>
							<Button
								className={classes.resetDefaultsButton}
								onClick={() => resetDefaultColor(inputRef)}
								aria-label='reset to default'>
								<ReplayRounded />
							</Button>
						</div>
					))}
				</div>
				{colorPickerOpen && getColorPicker()}
			</div>
		</FocusTrap>
	)
}

export default ThemeEditor
