import { makeStyles } from 'makeStyles'
import { PaletteTheme } from '../../../util/palette/Palette'

//NOTE: Styles for both BookOptionsModule, FormatOptionsModule, and their options
const useOptionsStyles = (palette: PaletteTheme) => {
	const {
		text1,
		switch1,
		disabled1,
		background4,
		border2,
		divider1,
		mainBackgroundColor,
		buttonBorderFlash,
		buttonHoverTransition
	} = palette

	return makeStyles()({
		formatOptionsModuleRoot: {
			minWidth: '18rem',
			maxWidth: '18rem',
			overflowY: 'auto',
			backgroundColor: background4,
			borderTop: `1px solid ${mainBackgroundColor}`,
			borderLeft: `1px solid ${border2}`,
			boxShadow: '0px -10px 15px -12px',
			// NOTE: Putting a custom scrollbar here doesn't do anything here since it's the entire page that scrolls
			'& .Mui-disabled': {
				color: `${disabled1} !important`,
				WebkitTextFillColor: 'initial !important', // prevents this value from overriding disabled color (otherwise disabled input numbers are too dark in dark mode)
				transition: '0.2s'
			},
			'& .MuiSwitch-colorPrimary.Mui-checked': {
				color: switch1
			},
			'& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
				backgroundColor: switch1
			},
			'& .MuiSwitch-colorPrimary.Mui-disabled': {
				color: disabled1
			},
			'& .MuiListItem-root': {
				paddingTop: '0.2rem',
				paddingBottom: '0.2rem'
			},
			'& .MuiRadio-root': {
				color: text1
			},
			'& .MuiRadio-colorPrimary.Mui-checked': {
				color: switch1
			}
		},
		_mobileOverlay: {
			position: 'absolute',
			zIndex: 4,
			minWidth: 'initial',
			maxWidth: 'initial',
			width: '100vw',
			minHeight: '100vh',
			border: 'none'
		},
		outerButtonContainer: {
			padding: '0.7rem 0.5rem 0.6rem 0.5rem'
		},
		howToAndFinishButtonContainer: {
			display: 'flex',
			justifyContent: 'space-between'
		},
		resetDefaultsButtonContainer: {
			display: 'flex',
			justifyContent: 'center',
			marginTop: '0.7rem',
			marginBottom: '1.2rem'
		},
		// for triggering format button flash
		finishButtonContainer: {
			width: '100%'
		},
		button: {
			width: '100%',
			minHeight: 'fit-content', //ensures the button label is visible on Safari
			backgroundColor: mainBackgroundColor,
			color: text1,
			border: `1px solid ${text1}`,
			transition: buttonHoverTransition,
			'&:hover': {
				backgroundColor: background4
			}
		},
		_disabledButton: {
			color: disabled1,
			border: `1px solid ${disabled1}`,
			transition: '0.2s'
		},
		_resetDefaultsButton: {
			width: '9rem',
			paddingTop: '0.4rem'
		},
		_flashReformatButton: {
			animation: buttonBorderFlash
		},
		horizontalButtonSpacer: {
			minHeight: '0.3rem',
			maxHeight: '0.3rem'
		},
		verticalButtonSpacer: {
			minWidth: '0.3rem',
			maxWidth: '0.3rem'
		},
		formatOptionsHeader: {
			fontSize: 18,
			fontWeight: 'bold',
			color: text1,
			marginLeft: '1rem'
		},
		divider: {
			backgroundColor: divider1,
			marginTop: '0.5rem',
			marginBottom: '0.5rem'
		},
		sectionHeader: {
			color: text1,
			fontWeight: 'bold',
			marginTop: '0.3rem',
			marginBottom: '0.3rem',
			'&.Mui-focused': {
				color: text1
			}
		},
		optionLabel: {
			marginRight: 0,
			lineHeight: '3rem',
			color: text1
		},
		// used for non-embedded inputs
		inputOptionLabel: {
			display: 'flex',
			alignItems: 'baseline', //center doesn't work, but this does,
			color: text1
		},
		embeddedInputContainer: {
			display: 'flex',
			flexDirection: 'row',
			lineHeight: '3rem'
		},
		embeddedInputLabelContainer: {
			display: 'flex',
			flexDirection: 'column'
		},
		_multiLineInputLabelContainer: {
			lineHeight: '1.5rem'
		},
		input: {
			maxWidth: '4rem',
			margin: '0.3rem 0.5rem 0.3rem 0.5rem',
			// Removes arrows from type number input
			'& input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button': {
				WebkitAppearance: 'none',
				margin: 0
			},
			'& input[type=number]': {
				MozAppearance: 'textfield'
			},
			'& .MuiOutlinedInput-root': {
				color: text1, // Input text
				'& .MuiOutlinedInput-notchedOutline': {
					border: `1px solid ${text1}` // Input when not focused
				},
				'&:hover fieldset': {
					border: `1px solid ${text1}` // Input when hovering
				},
				'&.Mui-focused fieldset': {
					border: `2px solid ${text1}` // Input when focused
				}
			},
			'& .MuiFormLabel-root': {
				color: text1,
				'&.Mui-focused': {
					color: text1
				}
			},
			'& .MuiFormHelperText-root': {
				color: text1,
				minWidth: '7rem',
				marginLeft: 0,
				marginRight: 0
			}
		},
		displayTitleInputContainer: {
			padding: '0 0.2rem 0.5rem 0.2rem',
			'& .MuiFormControl-root': {
				width: '100%',
				'& .MuiFormControlLabel-root': {
					marginRight: '0.3rem'
				}
			}
		},
		// Full width is maintained by style on the bookTitle List Item component
		displayTitleInputExtra: {
			minWidth: '100%',
			maxWidth: 'initial'
		},
		labelWithTooltip: {
			display: 'flex'
		}
	})
}

export default useOptionsStyles
