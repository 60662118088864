import { useContext } from 'react'
import { UserNotesContext } from '../../../contexts/UserNotesContext'
import { UtilContext } from '../../../contexts/UtilContext'
import { renderLog } from '../../../util/utilFunctions'
import DefaultAlert from './DefaultAlert'
import { UserSettingsContext } from '../../../contexts/UserSettingsContext'
import { ConsoleTheme } from '../../../util/palette/premiumConsoleThemesMapsAndTypes'

const SignInSuccessAlert = () => {
	const { signInSuccessMessage, setSignInSuccessMessage } = useContext(UserNotesContext)
	const { isMobileDisplay, isPolicyPage, isConsolePage } = useContext(UtilContext)
	const { getConsoleTheme } = useContext(UserSettingsContext)

	return (
		signInSuccessMessage &&
		!isMobileDisplay() &&
		!isPolicyPage() && (
			<>
				{renderLog('SIGN-IN SUCCESS ALERT')}
				<DefaultAlert
					autoHide={true}
					withIcon={
						isConsolePage() && ![ConsoleTheme.DARK, ConsoleTheme.LIGHT].includes(getConsoleTheme()) ? false : true
					}
					severity='success'
					text={signInSuccessMessage}
					onCloseCb={() => setSignInSuccessMessage(null)}
				/>
			</>
		)
	)
}

export default SignInSuccessAlert
