import { ChangeEvent, ReactElement, useContext, useEffect, useState } from 'react'
import { FormControlLabel, FormControl, FormGroup, Switch } from '@mui/material'
import { Palette } from '../../../util/palette/Palette'
import { devLog, renderLog, _useEffectLog } from '../../../util/utilFunctions'
import { FormatOptionsSection, OrigFileType } from './FormatOptionsModule'
import { FormatCustomBookContext, SwitchRadioInputOptionsProps } from '../../../contexts/FormatCustomBookContext'
import { BookOptionsSection } from './BookOptionsModule'
import useOptionsStyles from './OptionsStyles'
import DefaultTooltip from '../../sharedComponents/tooltips/DefaultTooltip'

// SECTION: Switch Options Props
interface SwitchOptionsProps {
	label: string | ReactElement
	name: string
	checked: boolean
	disabled: boolean
	display: boolean
}

const SwitchOptions = ({ optionsSection, origFileType }: SwitchRadioInputOptionsProps) => {
	// SECTION: Hooks
	const { formatOptionsObj, setFormatOption, setMaxLineLengthOptionDisabled } = useContext(FormatCustomBookContext)

	const [renderVar, setRenderVar] = useState(false)

	const { classes } = useOptionsStyles(Palette.getPalette())()

	// USE EFFECT: Enables/Disables maxLineLength Option
	useEffect(() => {
		_useEffectLog('SWITCH OPTIONS useEffect 0')
		setMaxLineLengthOptionDisabled(formatOptionsObj.altEpubFormatting)
	}, [formatOptionsObj.altEpubFormatting])

	// SECTION: Get Option Props
	const getSwitchOptionsProps = (optionsSection: FormatOptionsSection | BookOptionsSection) => {
		const props: { [key: string]: SwitchOptionsProps } = {
			SWITCH_SPACE_BETWEEN_PARAGRAPHS: {
				label: 'Space Between Paragraphs',
				name: 'spaceBetweenParagraphs',
				checked: formatOptionsObj.spaceBetweenParagraphs,
				disabled: false,
				display: true
			},
			SWITCH_REMOVE_LINE_INDENTS: {
				label: 'Remove Line Indents',
				name: 'removeLineIndents',
				checked: formatOptionsObj.removeLineIndents,
				disabled: false,
				display: true
			},
			SWITCH_REMOVE_PAGE_NUMBERS: {
				label: 'Remove Page Numbers',
				name: 'removePageNumbers',
				checked: formatOptionsObj.removePageNumbers,
				disabled: false,
				display: true
			},
			SWITCH_ALT_EPUB_FORMATTING: {
				label: (
					<div className={classes.labelWithTooltip}>
						Alt Epub Formatting&nbsp;
						<DefaultTooltip
							label={
								<>
									Only <strong>enable</strong> if experiencing formatting errors. Details in the How-To.
								</>
							}
						/>
					</div>
				),
				name: 'altEpubFormatting',
				checked: formatOptionsObj.altEpubFormatting,
				disabled: false,
				display: origFileType === OrigFileType.EPUB
			}
		}
		return props[optionsSection]
	}

	// SECTION: Functionality
	const reRender = () => {
		setRenderVar(!renderVar)
	}

	const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
		// Use setters to change individual options rather than replacing the whole formatOptionsObj to avoid all other settings UI from rerendering.
		// Changes aren't saved to local storage until rerender, finalization, or another option replaced formatOptionsObj
		setFormatOption(event.target.name, event.target.checked)
		reRender()
	}

	let switchProps = getSwitchOptionsProps(optionsSection)

	// SECTION: Render UI
	return (
		<>
			{switchProps.display && switchProps?.checked !== undefined ? (
				<FormControl component='fieldset'>
					{renderLog('SWITCH OPTIONS')}
					<FormGroup>
						<FormControlLabel
							className={classes.optionLabel}
							control={
								<Switch
									color={'primary'}
									checked={switchProps.checked}
									onChange={handleSwitchChange}
									name={switchProps.name}
								/>
							}
							disabled={switchProps.disabled}
							label={switchProps.label}
							key={switchProps.name}
						/>
					</FormGroup>
				</FormControl>
			) : null}
		</>
	)
}

export default SwitchOptions
