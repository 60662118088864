import { CustomRawBook, CustomRawPage, IdbCustomBook } from '../../types'
import { dbLog, devLog } from '../../util/utilFunctions'
import { openDB, IDBPDatabase } from 'idb'
import { handleFrontendPrivateOr500Error } from '../../util/errorHandlers'

// SECTION: GET IDB -- If none found, CREATE it along with associated object stores
export const getDb = (dbName: string) => {
	// Protect against accidently creating a db called undefined
	if (!dbName) {
		devLog('TRIED TO CREATE UNDEFINED DB')
		handleFrontendPrivateOr500Error(Error('getDb tried to create undefined db'), '', () => {})
		return
	}

	// Returns an IDB, or creates a new IDB and returns it if dbName doesn't already exist
	return openDB(dbName, 1, {
		// Only fires if db didn't previously exist, or db needs upgrading
		upgrade(db, oldVersion, newVersion, transaction) {
			if (dbName === 'db-lookups') {
				// Created automatically on any page getAllCustomBookDetailsFromIdb used, or when user first creates custom material
				db.createObjectStore('custom-book-dbs', { autoIncrement: true })
			} else {
				createIdbObjectStores(db)
			}
		},
		blocked() {
			devLog('IDB BLOCKED')
		},
		blocking() {
			devLog('IDB BLOCKING')
		},
		terminated() {
			devLog('IDB TERMINATED')
		}
	})
}

// SECTION: Object Stores
const createIdbObjectStores = (db: IDBPDatabase) => {
	const bookDetailsStore = db.createObjectStore('book-details', { autoIncrement: true })
	const chapterStore = db.createObjectStore('chapter-titles', { autoIncrement: true })
	const chapterTitleStore = db.createObjectStore('chapters', { autoIncrement: true })

	return { bookDetailsStore, chapterStore, chapterTitleStore }
}

const getIdbObjectStores = (db: IDBPDatabase) => {
	const bookDetailsStore = db.transaction('book-details', 'readwrite').objectStore('book-details')
	const chapterStore = db.transaction('chapters', 'readwrite').objectStore('chapters')
	const chapterTitleStore = db.transaction('chapter-titles', 'readwrite').objectStore('chapter-titles')

	return { bookDetailsStore, chapterStore, chapterTitleStore }
}

// SECTION: Save New Custom Book
export const saveCustomBookToIdb = async (customBook: CustomRawBook) => {
	dbLog('idb: saveCustomBookToIdb')
	const bookTitle = customBook.bookTitle

	const db = await getDb(bookTitle) // NOTE: Creates a new db if doesn't exist (which it shouldn't if you're running this)
	if (!db) throw Error(`saveCustomChapterTitleToIdb: getting idb ${bookTitle} returned undefined`)

	populateCustomBookIdb(db, customBook)

	let dbLookups = await getDb('db-lookups')

	if (!dbLookups) throw Error('saveCustomBookToIdb: db-lookups was undefined')

	const customBookIdbLookupsStore = dbLookups.transaction('custom-book-dbs', 'readwrite').objectStore('custom-book-dbs')
	customBookIdbLookupsStore.put(bookTitle, bookTitle)
}

// NOTE: createObjectStore can only be run from within getDb
const populateCustomBookIdb = (db: IDBPDatabase, customBook: CustomRawBook) => {
	dbLog('idb: populateCustomBookIdb')
	const { bookDetailsStore, chapterStore, chapterTitleStore } = getIdbObjectStores(db)

	// Populates book-details store
	for (let [key, value] of Object.entries(customBook)) {
		if (key !== 'chapters') {
			bookDetailsStore.put(value, key) // NOTE: Values first -- then the key
		}
	}

	// Populates chapters Store (includes chapterIndex, pageCount, and pages)
	customBook.chapters.forEach((chapter, index) => {
		chapterStore.put({ chapterIndex: chapter.chapterIndex, pageCount: chapter.pageCount, pages: chapter.pages }, index)
	})

	// Populates chapter-titles store -- // NOTE: seperate from chapterStore so users can updated them without replacing the whole chapter object in idb
	customBook.chapters.forEach((chapter, index) => {
		chapterTitleStore.put(chapter.chapterTitle, index)
	})
}

// SECTION: Save New Typelit-File-Derived Custom Book
// NOTE: This won't overwrite an existing db, but below WILL insert a missing db-lookup entry. If the user wants to replace a corrupted custom book, they can then delete it from the chapter select page and then run this again
export const saveTypelitFileDerivedCustomBookToIdb = async (customBook: IdbCustomBook) => {
	const bookTitle = customBook['book-details'].bookTitle

	dbLog('idb: saveCustomBookToIdb')
	const db = await getDb(bookTitle) // NOTE: Will just return the old db if it exists
	if (!db) throw Error(`saveCustomChapterTitleToIdb: getting idb ${bookTitle} returned undefined`)

	populateTypelitFileDerivedCustomBookIdb(db, customBook)

	let dbLookups = await getDb('db-lookups')
	if (!dbLookups) throw Error('saveCustomBookToIdb: db-lookups was undefined')

	const customBookIdbLookupsStore = dbLookups.transaction('custom-book-dbs', 'readwrite').objectStore('custom-book-dbs')
	customBookIdbLookupsStore.put(bookTitle, bookTitle)
}

const populateTypelitFileDerivedCustomBookIdb = (db: IDBPDatabase, customBook: IdbCustomBook) => {
	dbLog('idb: populateTypelitFileDerivedCustomBookIdb')

	const { bookDetailsStore, chapterStore, chapterTitleStore } =
		db.objectStoreNames.length > 0 ? getIdbObjectStores(db) : createIdbObjectStores(db)

	// Populates book-details store
	Object.entries(customBook['book-details']).forEach(([key, value]) => {
		bookDetailsStore.put(value, key)
	})

	// Populates chapters Store (includes chapterIndex, pageCount, and pages)
	Object.entries(customBook.chapters).forEach(([index, chapter]) => {
		chapterStore.put(chapter, +index)
	})

	// Populates chapter-titles store
	Object.entries(customBook['chapter-titles']).forEach(([index, chapterTitle]) => {
		chapterTitleStore.put(chapterTitle, +index)
	})
}

// SECTION: Save Quick Convert Text
export const saveQuickConvertTextToIdb = async (customBook: CustomRawBook) => {
	dbLog('idb: saveQuickConvertTextToIdb')
	const { bookTitle } = customBook

	// Create or update Quick Convert Db
	let db = await getDb(bookTitle)
	if (!db) throw Error(`setCustomBookDetail: getting idb ${bookTitle} returned undefined`)

	// Create or overwrite old properties
	await populateQuickConvertTextProperties(db, customBook)

	// Update the db-lookup if no quick convert db exists
	let dbLookups = await getDb('db-lookups')
	if (!dbLookups) throw Error('saveCustomBookToIdb: db-lookups was undefined')

	const customBookIdbLookupsStore = dbLookups.transaction('custom-book-dbs', 'readwrite').objectStore('custom-book-dbs')
	customBookIdbLookupsStore.put(bookTitle, bookTitle)
}

const populateQuickConvertTextProperties = async (db: IDBPDatabase, customBook: CustomRawBook) => {
	const { bookTitle, language, pageCount, chapterCount, chapters } = customBook

	// Update book-details pageCount -- the only prop that changes between quickConvertTexts
	const bookDetailsStore = db.transaction('book-details', 'readwrite').objectStore('book-details')
	await bookDetailsStore.put(bookTitle, 'bookTitle') // Will always be quick-convert-text-{user-id} -- required
	await bookDetailsStore.put(language, 'language') // Unused currently. May be used if allowing complex languages
	await bookDetailsStore.put('Quick Convert Text', 'displayTitle')
	await bookDetailsStore.put(chapterCount, 'chapterCount') // Unused, but repairIdbData creates this, so might as well be consistent
	await bookDetailsStore.put(pageCount, 'pageCount')

	// Update the chapter text
	const chapterStore = db.transaction('chapters', 'readwrite').objectStore('chapters')
	await chapterStore.put(
		{
			chapterIndex: 0,
			pageCount,
			pages: chapters[0].pages as CustomRawPage[]
		},
		0 // Only one chapter means index is always zero
	)

	// Update the single chapter chapterTitle -- not currently used, but updated anyway since it would be more trouble to omit it entirely
	const chapterTitlesStore = db.transaction('chapter-titles', 'readwrite').objectStore('chapter-titles')
	await chapterTitlesStore.put(chapters[0].chapterTitle, 0)
}
