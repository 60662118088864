import { makeStyles } from 'makeStyles'
import { PaletteTheme } from '../../../util/palette/Palette'

// NOTE: These styles are also used by FormatterHowToDialog and QuickConvertHowToDialog
const useStyles = (palette: PaletteTheme) => {
	const {
		mainBackgroundColor,
		background5,
		border1,
		border2,
		text1,
		divider1,
		divider2,
		scrollbarTrack,
		scrollbarThumb,
		link2,
		link4,
		linkHoverTransition
	} = palette

	// Absolute dialog on fixed background -- must be side by side in react code (not embedding eachother) -- clicking bg triggers close but not clicking dialog
	return makeStyles()({
		dialogContainer: {
			position: 'fixed',
			top: 0,
			left: 0,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100vw',
			height: '100vh',
			zIndex: 20
		},
		dialogBackground: {
			width: '100%',
			height: '100%',
			backgroundColor: background5
		},
		dialog: {
			position: 'absolute',
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: mainBackgroundColor,
			width: '85%',
			height: '93%',
			zIndex: 21,
			border: `1px ${border2} solid`, // only used on dark mode
			borderRadius: 2,
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				minWidth: '100vw',
				height: '100%',
				border: 'none'
			}
		},
		titleBarContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			paddingLeft: '1rem',
			padding: '1rem 1rem 0.5rem 1rem',
			borderBottom: `1px solid ${divider2 ? divider2 : border1}`, // diveder 2 === darkmode / border1 === lightmode
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				paddingLeft: '0.7rem',
				paddingRight: '0.7rem'
			},
			['@media (max-width:300px)']: {
				padding: '0.5rem 1.2rem 0.5rem 0.5rem'
			}
		},
		title: {
			color: text1,
			fontSize: 28,
			fontWeight: 'bold',
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				fontSize: 22
			}
		},
		closeButton: {
			color: text1,
			['@media (max-width:550px)']: {
				minWidth: '2rem'
			}
		},
		dialogContent: {
			color: text1,
			overflowY: 'auto',
			padding: '0 1.5rem 1rem 1.5rem',

			// Firefox custom scrollbar
			scrollbarWidth: 'thin',
			scrollbarColor: `${scrollbarThumb} ${scrollbarTrack}`,

			// Other browsers
			'&::-webkit-scrollbar': {
				width: '0.8em'
			},
			'&::-webkit-scrollbar-track': {
				backgroundColor: scrollbarTrack,
				borderRadius: '2px'
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: scrollbarThumb,
				borderRadius: '2px'
			}
		},
		contentSection: {
			display: 'flex',
			flexDirection: 'column',
			marginTop: '1rem',
			marginBottom: '1rem'
		},
		sectionBreakContainer: {
			display: 'flex',
			justifyContent: 'center',
			padding: '0.5rem'
		},
		sectionBreakText: {
			fontWeight: 'bold'
		},
		divider1: {
			width: '100%',
			borderBottom: `solid 1px ${divider1}`,
			marginTop: '0.8rem',
			marginBottom: '0.3rem'
		},
		divider2: {
			width: '100%',
			borderBottom: `solid 1px ${divider2}`,
			marginTop: '0.8rem',
			marginBottom: '0.3rem'
		},
		// Should match header text in PremiumInfoPage
		header: {
			fontSize: 22,
			fontWeight: 'bold',
			['@media (max-width:550px)']: {
				fontSize: 19.5
			}
		},
		headerInDialog: {
			fontSize: 24,
			fontWeight: 'bold',
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				fontSize: 20
			}
		},
		descriptionContainer: {
			marginBottom: '0.7rem',
			paddingLeft: '1rem'
		},
		_radioGroupHeaderContainer: {
			marginBottom: 0
		},
		_radioGroupHeader: {
			fontSize: 20,
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				fontSize: 18
			}
		},
		description: {
			fontSize: 17,
			marginTop: '0.7rem',
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				fontSize: 16,
				marginTop: '0.5rem'
			}
		},
		_descriptionNoMargin: {
			marginTop: 0
		},
		// Used by SharedCustomBookFormattingRules
		list: {
			listStyleType: 'none',
			paddingLeft: '2.5rem',
			margin: '0.4rem 0',
			'& li': {
				fontSize: 17,
				['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
					fontSize: 16
				}
			}
		},
		// Used by SharedCustomBookCopyrightDisclaimer
		listWithSpacing: {
			listStyleType: 'none',
			paddingLeft: '2.5rem',
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				paddingLeft: '1.3rem'
			},
			'& li': {
				fontSize: 17,
				marginTop: '1rem'
			}
		},
		_withBullets: {
			listStyleType: 'square'
		},
		link: {
			textDecoration: 'none',
			transition: linkHoverTransition,
			color: link2,
			'&:hover': {
				color: link4
			}
		}
	})
}

export default useStyles
