import { Fade, Snackbar, Alert } from '@mui/material'
import { AlertProps } from '@mui/material/Alert'
import { ReactNode, SyntheticEvent, useContext, useEffect, useRef, useState } from 'react'
import { Palette, PaletteTheme } from '../../../util/palette/Palette'
import { UserSettingsContext } from '../../../contexts/UserSettingsContext'
import { devLog, renderLog, _useEffectLog } from '../../../util/utilFunctions'
import { makeStyles } from 'makeStyles'
import { UtilContext } from '../../../contexts/UtilContext'
import { ConsoleTheme } from '../../../util/palette/premiumConsoleThemesMapsAndTypes'

// SECTION: Styles
const useStyles = (palette: PaletteTheme) => {
	const { text1, background3, border11 } = palette

	return makeStyles()({
		snackbarDarkOrConsolePremium: {
			zIndex: 2500,
			'& .MuiAlert-root': {
				color: text1,
				backgroundColor: background3,
				borderRadius: '2px'
			},
			'& .MuiAlert-standardSuccess': {
				border: `1px solid ${border11}`
			},
			'& .MuiAlert-standardWarning': {
				border: `1px solid orange`
			},
			'& .MuiAlert-standardError': {
				border: `1px solid red`
			}
		}
	})
}

// SECTION: Props
interface DefaultAlertProps {
	autoHide: number | boolean
	severity: AlertProps['severity']
	withIcon?: boolean
	text?: ReactNode
	reOpenableCb?: Function
	onCloseCb?: Function
}

const defaultAutoHideDuration = 3000

const DefaultAlert = ({ autoHide, severity, withIcon, text, reOpenableCb, onCloseCb }: DefaultAlertProps) => {
	// SECTION: Hooks
	const { isConsolePage } = useContext(UtilContext)
	const { getDarkModeEnabled, getConsoleTheme } = useContext(UserSettingsContext)

	const [alertOpen, setAlertOpen] = useState(true)

	const isMounted = useRef(false)

	const { classes } = useStyles(isConsolePage() ? Palette.getConsolePalette(getConsoleTheme()) : Palette.getPalette())()

	// USE EFFECT: Tracks whether component is mounted
	useEffect(() => {
		_useEffectLog('DEFAULT ALERT useEffect 1')
		isMounted.current = true
		return () => {
			isMounted.current = false
		}
	})

	// SECTION: Functionality
	const handleClose = (event: Event | SyntheticEvent<any, Event>, reason?: string) => {
		if (onCloseCb) {
			onCloseCb()
		}

		if (!autoHide && reason === 'clickaway') {
			// Enables clickaway to close Alert when autoHide true
			return
		}

		// If you want the alert to be shown again sometime after close, use callback to hide it in parent component
		if (reOpenableCb) {
			reOpenableCb('')
		} else {
			// Only change state if component is still mounted
			if (isMounted.current) {
				setAlertOpen(false)
			}
		}
	}

	// NOTE: Render log handled by ErrorAlert and SignInSuccessAlert
	return (
		<Fade in={alertOpen}>
			<Snackbar // Alert alone would just stretch alert across the top, rather than floating it
				className={
					(isConsolePage() && getConsoleTheme() === ConsoleTheme.LIGHT) || (!isConsolePage() && !getDarkModeEnabled())
						? ''
						: classes.snackbarDarkOrConsolePremium
				}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				open={true}
				// Accepts milliseconds until autohide, or boolean true to use defaultAutoHideDuration
				autoHideDuration={autoHide ? (typeof autoHide === 'number' ? autoHide : defaultAutoHideDuration) : null}
				onClose={handleClose}>
				{autoHide ? (
					<Alert icon={withIcon === false ? false : undefined} severity={severity} elevation={5}>
						{text ? text : 'An error occurred.'}
					</Alert>
				) : (
					// Adds x button if autoHide off
					<Alert
						icon={withIcon === false ? false : undefined}
						severity={severity}
						elevation={5}
						tabIndex={0}
						onClose={handleClose}>
						{text ? text : 'An error occurred.'}
					</Alert>
				)}
			</Snackbar>
		</Fade>
	)
}

export default DefaultAlert
