// ABOUT: Shows if isSiteAccessable feature flag if false. Not a true page (it renders in place of the actual page) so there's no file in the pages directory.
// ABOUT: See featureFlags.ts for how to get around it

import { Typography } from '@mui/material'
import Head from 'next/head'
import SemipolarLoading from '../../util/react-loadingg/SemipolarLoading'
import { makeStyles } from '../../makeStyles'
import { lightModePalette, PaletteTheme } from '../../util/palette/Palette'
import { renderLog } from '../../util/utilFunctions'

// SECTION: Styles
const useStyles = (lightModePalette: PaletteTheme) => {
	const { mainBackgroundColor } = lightModePalette

	return makeStyles()({
		pageRoot: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100vh',
			backgroundColor: mainBackgroundColor
		},
		siteTitle: {
			fontSize: 22,
			fontWeight: 'bold'
		},
		spinnerContainer: {
			marginTop: '1rem',
			marginBottom: '1rem'
		},
		subtext: {
			fontSize: 12
		}
	})
}

const MaintenancePage = () => {
	// SECTION: Hooks
	const { classes } = useStyles(lightModePalette)()

	// SECTION: Render UI
	return (
		<main className={classes.pageRoot} data-nosnippet>
			<Head>
				<title>Under Maintenance | TypeLit.io</title>
				<meta name='description' content='Under Maintenance' />
				<meta name='robots' content='noindex, nofollow' />
			</Head>

			{renderLog('MAINTENANCE PAGE')}
			<Typography className={classes.siteTitle}>TypeLit.io</Typography>
			<div className={classes.spinnerContainer}>
				<SemipolarLoading style={{}} size={'large'} color={'#333333'} /> {/* loadingOverlayText */}
			</div>
			<Typography>Updating the Site</Typography>
			<Typography>Please come back later</Typography>
			<Typography className={classes.subtext}>
				(this might take a while -- we'll hopefully be back in a few days!)
			</Typography>
		</main>
	)
}

export default MaintenancePage
