import { useContext, useState } from 'react'
import { Divider, ListItem, Button, Typography } from '@mui/material'
import { Palette } from '../../../util/palette/Palette'
import { devLog, renderLog } from '../../../util/utilFunctions'
import SwitchOptions from './SwitchOptions'
import { InputOptions } from './InputOptions'
import { FormatCustomBookContext } from '../../../contexts/FormatCustomBookContext'
import RadioOptions from './RadioOptions'
import useOptionsStyles from './OptionsStyles'
import DefaultWarningDialog from '../../sharedComponents/DefaultWarningDialog'
import { UserNotesContext } from '../../../contexts/UserNotesContext'
import FormatterHowToDialog from '../FormatterHowToDialog'

// SECTION: Props
export enum OrigFileType {
	EPUB = 'EPUB',
	PDF = 'PDF',
	TXT = 'TXT',
	TYPELIT = 'TYPELIT',
	QUICK_CONVERT = 'QUICK_CONVERT'
}

export enum FormatOptionsSection {
	SWITCH_REMOVE_LINE_INDENTS = 'SWITCH_REMOVE_LINE_INDENTS',
	SWITCH_SPACE_BETWEEN_PARAGRAPHS = 'SWITCH_SPACE_BETWEEN_PARAGRAPHS',
	SWITCH_REMOVE_PAGE_NUMBERS = 'SWITCH_REMOVE_PAGE_NUMBERS',
	SWITCH_ALT_EPUB_FORMATTING = 'SWITCH_ALT_EPUB_FORMATTING',
	INPUT_MAX_LINE_LENGTH = 'INPUT_MAX_LINE_LENGTH',
	RADIO_SHORT_CHAPTERS_STRATEGY = 'RADIO_SHORT_CHAPTERS_STRATEGY',
	RADIO_DETECT_NEW_CHAPTER_ON = 'RADIO_DETECT_NEW_CHAPTER_ON'
}

interface FormatOptionsModuleProps {
	origFileType: OrigFileType
	origLongestLineLength: number | null
	mobileOverlay?: boolean
	setMobileFormatModuleOpenCb?: Function
}

const FormatOptionsModule = ({
	origFileType,
	origLongestLineLength,
	mobileOverlay,
	setMobileFormatModuleOpenCb
}: FormatOptionsModuleProps) => {
	// SECTION: Hooks
	const {
		formatOptionsObj,
		previews,
		finishButtonDisabled,
		resetDefaultFormatOptions,
		noChaptersSelected,
		reformatTxt,
		convertTxtToRawBook
	} = useContext(FormatCustomBookContext)
	const { getShowFinishedFormattingWarning, setShowFinishedFormattingWarning } = useContext(UserNotesContext)

	const [confirmFinishDialogOpen, setConfirmFinishDialogOpen] = useState(false)
	const [howToDialogOpen, setHowToDialogOpen] = useState(false)
	const [flashReformatButton, setFlashReformatButton] = useState(false)

	const { classes, cx } = useOptionsStyles(Palette.getPalette())() // NOTE: Styles are from OptionsStyles file

	// SECTION: Functionality
	// Only handles display of entire option sections. Controle of individual options need to be handled in specific option files (like EmbeddedInputRadioOptions)
	const displayOptionSection = (formatOptionName: FormatOptionsSection) => {
		const lookups: { [origFileType: string]: { [formatOptionName: string]: boolean } } = {
			EPUB: { RADIO_SHORT_CHAPTERS_STRATEGY: true, RADIO_DETECT_NEW_CHAPTER_ON: true },
			PDF: { RADIO_DETECT_NEW_CHAPTER_ON: true },
			TXT: { RADIO_SHORT_CHAPTERS_STRATEGY: true, RADIO_DETECT_NEW_CHAPTER_ON: true }
		}

		return lookups[origFileType]?.[formatOptionName]
	}

	const handleConfirmFinishedFormatting = (boxChecked: boolean | null) => {
		if (boxChecked) setShowFinishedFormattingWarning(false)
		setConfirmFinishDialogOpen(false)
		convertTxtToRawBook()
	}

	// SECTION: Render UI
	return (
		<div className={cx(classes.formatOptionsModuleRoot, mobileOverlay && classes._mobileOverlay)}>
			{renderLog('FORMAT OPTIONS MODULE')}
			{howToDialogOpen ? <FormatterHowToDialog dialogOpenCb={setHowToDialogOpen} /> : null}
			{confirmFinishDialogOpen && (
				<DefaultWarningDialog
					dialogOpenCb={setConfirmFinishDialogOpen}
					onClickConfirmCb={(boxChecked: boolean) => handleConfirmFinishedFormatting(boxChecked)}
					title={'Done Formatting?'}
					checkboxLabel={"Don't ask me next time"}
					checkboxDefault={false}
					preventConfirmOnCheckboxEmpty={false}
				/>
			)}
			<div className={classes.outerButtonContainer}>
				{mobileOverlay && (
					<Button
						className={classes.button}
						onClick={() => setMobileFormatModuleOpenCb && setMobileFormatModuleOpenCb(false)}>
						Back
					</Button>
				)}
				<div className={classes.horizontalButtonSpacer} />
				<div className={classes.howToAndFinishButtonContainer}>
					<Button className={classes.button} onClick={() => setHowToDialogOpen(true)}>
						How-to
					</Button>
					<div className={classes.verticalButtonSpacer} />
					<div
						className={classes.finishButtonContainer}
						onClick={() => {
							finishButtonDisabled && !noChaptersSelected() && setFlashReformatButton(true)
						}}>
						<Button
							className={cx(classes.button, finishButtonDisabled && classes._disabledButton)}
							disabled={finishButtonDisabled}
							onClick={() =>
								getShowFinishedFormattingWarning()
									? setConfirmFinishDialogOpen(true)
									: handleConfirmFinishedFormatting(null)
							}>
							Finish
						</Button>
					</div>
				</div>
				<div className={classes.horizontalButtonSpacer} />
				<Button
					className={cx(
						classes.button,
						(!finishButtonDisabled || noChaptersSelected()) && classes._disabledButton,
						flashReformatButton &&
							formatOptionsObj.omitChapterList.size < previews.length &&
							classes._flashReformatButton
					)}
					disabled={!finishButtonDisabled || noChaptersSelected()}
					onAnimationEnd={() => setFlashReformatButton(false)}
					onClick={reformatTxt}>
					Reformat Text
				</Button>
			</div>
			<Typography className={classes.formatOptionsHeader} component='h4'>
				Format Options
			</Typography>
			<ListItem>
				<SwitchOptions
					optionsSection={FormatOptionsSection.SWITCH_SPACE_BETWEEN_PARAGRAPHS}
					origFileType={origFileType}
				/>
			</ListItem>
			<ListItem>
				<SwitchOptions optionsSection={FormatOptionsSection.SWITCH_REMOVE_LINE_INDENTS} origFileType={origFileType} />
			</ListItem>
			<ListItem>
				<SwitchOptions optionsSection={FormatOptionsSection.SWITCH_REMOVE_PAGE_NUMBERS} origFileType={origFileType} />
			</ListItem>
			<ListItem>
				<SwitchOptions optionsSection={FormatOptionsSection.SWITCH_ALT_EPUB_FORMATTING} origFileType={origFileType} />
			</ListItem>
			<ListItem>
				<InputOptions
					optionsSection={FormatOptionsSection.INPUT_MAX_LINE_LENGTH}
					origFileType={origFileType}
					origLongestLineLength={origLongestLineLength}
				/>
			</ListItem>
			{displayOptionSection(FormatOptionsSection.RADIO_SHORT_CHAPTERS_STRATEGY) && (
				<>
					<Divider className={classes.divider} />
					<ListItem>
						<RadioOptions
							optionsSection={FormatOptionsSection.RADIO_SHORT_CHAPTERS_STRATEGY}
							origFileType={origFileType}
						/>
					</ListItem>
				</>
			)}
			{displayOptionSection(FormatOptionsSection.RADIO_DETECT_NEW_CHAPTER_ON) && (
				<>
					<Divider className={classes.divider} />
					<ListItem>
						<RadioOptions
							optionsSection={FormatOptionsSection.RADIO_DETECT_NEW_CHAPTER_ON}
							origFileType={origFileType}
						/>
					</ListItem>
				</>
			)}
			<div className={classes.resetDefaultsButtonContainer}>
				<Button className={cx(classes.button, classes._resetDefaultsButton)} onClick={resetDefaultFormatOptions}>
					Reset Defaults
				</Button>
			</div>
		</div>
	)
}

export default FormatOptionsModule
