import { Button, Checkbox, FormControlLabel, Paper, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { Palette, PaletteTheme } from '../../util/palette/Palette'
import { devLog, renderLog, _useEffectLog } from '../../util/utilFunctions'
import { makeStyles } from 'makeStyles'
import { FocusTrap } from '@mui/base/FocusTrap'
import { UtilContext } from '../../contexts/UtilContext'
import { UserSettingsContext } from '../../contexts/UserSettingsContext'

// SECTION: Styles
const useStyles = (palette: PaletteTheme) => {
	const {
		mainBackgroundColor,
		background5,
		border2,
		font1,
		text1,
		buttonHover0,
		iconHover0,
		disabled1,
		buttonHoverTransition,
		altMainBackgroundColor
	} = palette

	// Absolute dialog on fixed background -- must be side by side in react code (not embedding eachother) -- clicking bg triggers close but not clicking dialog
	return makeStyles()({
		dialogContainer: {
			position: 'fixed',
			top: 0,
			left: 0,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100vw',
			height: '100vh',
			zIndex: 20
		},
		dialogBackground: {
			width: '100%',
			height: '100%',
			backgroundColor: background5
		},
		dialog: {
			position: 'absolute',
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: altMainBackgroundColor || mainBackgroundColor,
			width: '24rem',
			zIndex: 21,
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				width: '100vw'
			}
		},
		content: {
			padding: '1rem 1.5rem 1rem 1.5rem',
			border: `1px ${border2} solid`, // only used on dark mode
			borderRadius: '5px',
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				borderLeft: 'none',
				borderRight: 'none',
				borderRadius: 0
			}
		},
		titleContainer: {
			width: '100%'
		},
		title: {
			color: text1,
			fontSize: 24,
			fontWeight: 'bold',
			margin: 0,
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				fontSize: 20
			}
		},
		subtitle: {
			color: text1,
			fontSize: 18,
			marginTop: '0.8rem',
			marginBottom: '0.9rem',
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				fontSize: 16,
				marginTop: '0.6rem',
				marginBottom: '0.6rem'
			}
		},
		disableWarningContainer: {
			marginTop: '0.8rem',
			marginBottom: '0.9rem',
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				marginTop: '0.6rem',
				marginBottom: '0.6rem'
			},
			'& .MuiCheckbox-root': {
				color: text1
			},
			'& .Mui-checked': {
				color: `${text1} !important`
			},
			'& .MuiFormControlLabel-label': {
				color: text1,
				fontWeight: 600
			}
		},
		disableWarningCheckbox: {
			'&:hover': {
				background: 'none'
			}
		},
		extraSpacing: {
			height: 24
		},
		buttonContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'flex-end',
			backgroundColor: altMainBackgroundColor || mainBackgroundColor,
			padding: '0 0.7rem 0 0.7rem',
			'& .MuiButtonBase-root': {
				fontFamily: font1,
				color: text1,
				fontSize: 15,
				backgroundColor: `${buttonHover0} !important`,
				'&:hover': {
					color: `${iconHover0} !important`,
					transition: buttonHoverTransition
				}
			},
			'& .Mui-disabled': {
				color: `${disabled1} !important`
			},
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				paddingLeft: '1.3rem',
				paddingRight: '1.3rem'
			}
		}
	})
}

// SECTION: Props
interface DefaultWarningDialogProps {
	dialogOpenCb: Function
	onClickConfirmCb: Function
	title: string
	subtitle?: string
	checkboxLabel?: string
	checkboxDefault?: boolean
	preventConfirmOnCheckboxEmpty?: boolean
}

const DefaultWarningDialog = ({
	dialogOpenCb,
	onClickConfirmCb,
	title,
	subtitle,
	checkboxLabel,
	checkboxDefault,
	preventConfirmOnCheckboxEmpty
}: DefaultWarningDialogProps) => {
	// SECTION: Hooks
	const [boxChecked, setBoxChecked] = useState(false)

	const { isConsolePage } = useContext(UtilContext)
	const { getConsoleTheme } = useContext(UserSettingsContext)

	const { classes } = useStyles(isConsolePage() ? Palette.getConsolePalette(getConsoleTheme()) : Palette.getPalette())()

	// USE EFFECT: Sets checkbox to default if checkboxDefault is true
	useEffect(() => {
		_useEffectLog('DEFAULT WARNING DIALOG useEffect 0')
		checkboxDefault && setBoxChecked(checkboxDefault) // if not using checkbox, state must still be true to press confirm button
	}, [])

	// SECTION: Render UI
	return (
		<div className={classes.dialogContainer}>
			{renderLog(`DEFAULT WARNING DIALOG: ${title}`)}
			<div className={classes.dialogBackground} onClick={() => dialogOpenCb(false)} />
			<FocusTrap open>
				<Paper
					className={classes.dialog}
					elevation={6}
					tabIndex={0} // Keep this or you'll get an error about not accepting tab index
					onKeyDown={(event) => (event.key === 'Esc' || event.key === 'Escape') && dialogOpenCb(false)}>
					<div className={classes.content}>
						<div className={classes.titleContainer}>
							<Typography className={classes.title}>{title}</Typography>
						</div>
						{subtitle && <Typography className={classes.subtitle}>{subtitle}</Typography>}
						{checkboxLabel && (
							<FormControlLabel
								className={classes.disableWarningContainer}
								control={
									<Checkbox
										className={classes.disableWarningCheckbox}
										checked={boxChecked}
										name='Confirmation Checkbox'
										onChange={(event) => setBoxChecked(event.target.checked)}
									/>
								}
								label={checkboxLabel}
							/>
						)}
						{!subtitle && !checkboxLabel && <div className={classes.extraSpacing} />}
						<div className={classes.buttonContainer}>
							<Button
								disabled={preventConfirmOnCheckboxEmpty && !boxChecked}
								onClick={() => onClickConfirmCb(boxChecked)}>
								Confirm
							</Button>
							<Button onClick={() => dialogOpenCb(false)}>Cancel</Button>
						</div>
					</div>
				</Paper>
			</FocusTrap>
		</div>
	)
}

export default DefaultWarningDialog
