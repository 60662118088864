// ABOUT: Allows dynamic swapping between UserDbStatsContext and UserLsStatsContext
import { Context, createContext } from 'react'
import { renderLog } from '../util/utilFunctions'
import { UserLsBookStats, UserLsStatsContext } from '../contexts/UserLsStatsContext'
import { UserDbBookStats, UserDbStatsContext } from '../contexts/UserDbStatsContext'
import { useSession } from 'next-auth/react'

export interface UserStatsContextTypes {
	userStatsObj: UserDbBookStats | UserLsBookStats
	loadingDbStats: boolean
	userStatsError: string
	updateUserLsDbStats: (
		bookTitle: string,
		chapterIndex: number,
		pageIndex: number,
		wpm: number,
		acc: number,
		wordsTyped: number,
		maxPageIndex: number,
		updateTotalWordsPagesTyped: boolean
	) => void
	getLevel: () => number
	getTotalWordsTyped: () => number
	getPrevPageWpm: (bookTitle: string, chapterIndex: number, pageIndex: number) => number | null
	getCurrentPageWpm: (bookTitle: string, chapterIndex: number, pageIndex: number) => number | null
	getPrevPageAcc: (bookTitle: string, chapterIndex: number, pageIndex: number) => number | null
	getCurrentPageAcc: (bookTitle: string, chapterIndex: number, pageIndex: number) => number | null
	getNumChapterPagesComplete: (bookTitle: string, chapterIndex: number) => number
	getChapterWpm: (bookTitle: string, chapterIndex: number) => number
	getChapterAcc: (bookTitle: string, chapterIndex: number) => number
	clearLocalStatsObj: () => void
	setUserStatsObj: (userStatsObj: any) => void
	setLoadingDbStats: (mode: boolean) => void
	fetchUserStatsFromLocalStorage: () => void
	saveUserStatsToLocalStorage: () => void
}

// SECTION: Context
export const UserMasterStatsContext = createContext({
	getUserStatsContext: (): Context<UserStatsContextTypes> => UserDbStatsContext
})

// SECTION: Provider
export const UserMasterStatsProvider = (props: any) => {
	const { data: session, status } = useSession()

	const getUserStatsContext = () => {
		return session ? UserDbStatsContext : UserLsStatsContext
	}

	return (
		<UserMasterStatsContext.Provider
			value={{
				getUserStatsContext: getUserStatsContext
			}}>
			{props.children}
			{renderLog('USER MASTER STATS CONTEXT')}
		</UserMasterStatsContext.Provider>
	)
}
