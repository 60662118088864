import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react'
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material'
import { Palette } from '../../../util/palette/Palette'
import { devLog, renderLog, _useEffectLog } from '../../../util/utilFunctions'
import { FormatOptionsSection, OrigFileType } from './FormatOptionsModule'
import {
	DetectNewChapterOn,
	FormatCustomBookContext,
	ShortChaptersStrategy
} from '../../../contexts/FormatCustomBookContext'
import useOptionsStyles from './OptionsStyles'
import { EmbeddedInputOptions } from './EmbeddedInput'
import DefaultTooltip from '../../sharedComponents/tooltips/DefaultTooltip'

// SECTION: Radio Options Props
interface RadioOptionsProps {
	optionsSection: FormatOptionsSection
	origFileType: OrigFileType
}

interface RadioOptionProps {
	header: string
	name: string
	curValue: DetectNewChapterOn | ShortChaptersStrategy
	disabled: boolean
	display: boolean
	options: {
		label: string | Function
		value: string
		disabled: boolean
		display: boolean
	}[]
}

const RadioOptions = ({ optionsSection, origFileType }: RadioOptionsProps) => {
	// SECTION: Hooks -- must be above formatOptionsObj check, or you'll get the 'different number of hooks rendered error
	const { formatOptionsObj, shortChaptersOptionsDisabled, setShortChaptersOptionsDisabled, setFormatOption } =
		useContext(FormatCustomBookContext)

	const [renderVar, setRenderVar] = useState(false)

	const reRender = () => {
		setRenderVar(!renderVar)
	}

	const maxPagesPerChapterInputRef = useRef<any>(null)

	// USE EFFECT: Enables/Disables Short Chapters options
	useEffect(() => {
		_useEffectLog('RADIO OPTIONS useEffect 0')
		setShortChaptersOptionsDisabled(
			formatOptionsObj.detectNewChapterOn !== DetectNewChapterOn.EPUB_DEFAULT &&
				formatOptionsObj.detectNewChapterOn !== DetectNewChapterOn.MANUAL_CHAPTER_BREAK
		)
	}, [formatOptionsObj.detectNewChapterOn])

	// USE EFFECT: Revert input ref values to default after reset defaults button pressed
	useEffect(() => {
		_useEffectLog('RADIO OPTIONS useEffect 1')
		if (maxPagesPerChapterInputRef.current) {
			maxPagesPerChapterInputRef.current.value = formatOptionsObj.maxPagesPerChapter
		}
	}, [formatOptionsObj])

	const { classes } = useOptionsStyles(Palette.getPalette())()

	// SECTION: Get Option Props
	const getRadioOptionProps = (optionsSection: FormatOptionsSection, formatOptionsObj: any) => {
		const props: { [key: string]: RadioOptionProps } = {
			RADIO_SHORT_CHAPTERS_STRATEGY: {
				header: 'Short Chapters:',
				name: 'shortChaptersStrategy',
				curValue: formatOptionsObj.shortChaptersStrategy,
				disabled: shortChaptersOptionsDisabled,
				display: origFileType !== OrigFileType.PDF,
				options: [
					{
						label: 'Remove Them',
						value: ShortChaptersStrategy.REMOVE,
						disabled: shortChaptersOptionsDisabled,
						display: true
					},
					{
						label: 'Merge Them',
						value: ShortChaptersStrategy.MERGE_WITH_NEXT_CHAPTER,
						disabled: shortChaptersOptionsDisabled,
						display: true
					}
				]
			},
			RADIO_DETECT_NEW_CHAPTER_ON: {
				header: 'Detect New Chapter On:',
				name: 'detectNewChapterOn',
				curValue: formatOptionsObj.detectNewChapterOn,
				disabled: false,
				display: true,
				options: [
					{
						label: 'Epub Default',
						value: DetectNewChapterOn.EPUB_DEFAULT,
						disabled: origFileType !== OrigFileType.EPUB,
						display: origFileType === OrigFileType.EPUB
					},
					{
						label: 'Manual Chapter Break',
						value: DetectNewChapterOn.MANUAL_CHAPTER_BREAK,
						disabled: origFileType !== OrigFileType.TXT,
						display: origFileType === OrigFileType.TXT
					},
					{
						// Default value here is handled by FormatCustomBookContext
						label: () => (
							<EmbeddedInputOptions
								name={'maxPagesPerChapter'}
								textPart1={'Every'}
								textPart2={
									<div className={classes.labelWithTooltip}>
										Pages&nbsp;
										<DefaultTooltip label={'Final page counts may differ in larger chapters.'} />
									</div>
								}
								helperText={'Range: 5 - 300'}
								defaultValue={30}
								inputRef={maxPagesPerChapterInputRef}
								linkedFormatOption={'detectNewChapterOn'}
								linkedFormatEnum={DetectNewChapterOn.EVERY_X_PAGES}
							/>
						),
						value: DetectNewChapterOn.EVERY_X_PAGES,
						disabled: false,
						display: true
					}
				]
			}
		}
		return props[optionsSection]
	}

	// SECTION: Functionality
	const handleRadioButtonChange = (event: ChangeEvent<HTMLInputElement>) => {
		setFormatOption(event.target.name, event.target.value)
		reRender()
	}

	// SECTION: Render UI
	const radioOptionProps = getRadioOptionProps(optionsSection, formatOptionsObj)

	return (
		<>
			{Object.keys(formatOptionsObj).length > 0 && radioOptionProps && Object.keys(radioOptionProps).length > 0 ? (
				<FormControl component='fieldset' disabled={radioOptionProps.disabled}>
					{renderLog('EMBEDDED RADIO OPTIONS')}
					<FormLabel className={classes.sectionHeader} component='legend'>
						{radioOptionProps.header}
					</FormLabel>
					<RadioGroup name={radioOptionProps.name} value={radioOptionProps.curValue} onChange={handleRadioButtonChange}>
						{radioOptionProps.options.map(({ label, value, disabled, display }, key) => {
							if (display) {
								return (
									<FormControlLabel
										className={classes.optionLabel}
										label={typeof label === 'function' ? label() : label}
										value={value}
										control={
											<Radio
												// If the label is a JSX.Element, it's an embedded input. Nudge the radio button up a little (className didn't work)
												// QUIRK: Non-selected radio buttons can't be tabbed through -- use arrow keys to change selection once group is selected
												style={typeof label === 'function' ? { marginBottom: '1.5rem' } : {}}
												color={'primary'}
												disabled={disabled}
											/>
										}
										key={`header-${key}`} // can't use label for this since some are EmbeddedLabels
									/>
								)
							}
						})}
					</RadioGroup>
				</FormControl>
			) : (
				<div />
			)}
		</>
	)
}

export default RadioOptions
