import { Button, Divider, Paper, Typography } from '@mui/material'
import { Palette } from '../../util/palette/Palette'
import { devLog, renderLog } from '../../util/utilFunctions'
import { Close } from '@mui/icons-material'
import useStyles from '../sharedComponents/sharedDialogContent/sharedDialogContentStyles'
import SharedCustomBookLimitations from '../sharedComponents/sharedDialogContent/CustomBookLimitations'
import SharedCustomBookFormattingRules from '../sharedComponents/sharedDialogContent/CustomBookFormattingRules'
import { FocusTrap } from '@mui/base/FocusTrap'
import {
	MAX_LINE_LENGTH,
	MIN_CHAPTER_LENGTH,
	MIN_LINE_LENGTH
} from '../../functions/sharedFunctions/sharedInputValidators'

interface FormatterHowToDialogProps {
	dialogOpenCb: Function
}

const FormatterHowToDialog = ({ dialogOpenCb }: FormatterHowToDialogProps) => {
	const { classes, cx } = useStyles(Palette.getPalette())()

	return (
		<aside className={classes.dialogContainer}>
			{renderLog('FORMATTER HOW-TO DIALOG')!}
			<div className={classes.dialogBackground} onClick={() => dialogOpenCb(false)} />
			<FocusTrap open>
				<Paper
					className={classes.dialog}
					elevation={6}
					tabIndex={0} // Keep this or you'll get an error about not accepting tab index
					onKeyDown={(event) => (event.key === 'Esc' || event.key === 'Escape') && dialogOpenCb(false)}>
					<div className={classes.titleBarContainer}>
						<Typography className={classes.title} component={'h2'}>
							Book Formatter Instructions
						</Typography>
						<Button className={classes.closeButton} onClick={() => dialogOpenCb(false)}>
							<Close />
						</Button>
					</div>
					<div className={classes.dialogContent} tabIndex={0}>
						{/* //SECTION: TLDR */}
						<div className={classes.contentSection}>
							<Typography className={classes.header} component={'h3'}>
								TLDR
							</Typography>
							<div>
								<div className={classes.descriptionContainer}>
									<Typography className={classes.description}>
										<strong>The Goal:</strong> Format your text to look its best.
									</Typography>
									<Typography className={classes.list} component={'ol'}>
										<Typography component={'li'}>
											<strong>Step 1:</strong> Check the expandable chapter previews. This is how your text will appear
											when you're typing it
										</Typography>
										<Typography component={'li'}>
											<strong>Step 2:</strong> If things don't look right, make changes in the "Format Options" panel
										</Typography>
										<Typography component={'li'}>
											<strong>Step 3:</strong> Click "Reformat Text" to apply your changes
										</Typography>
										<Typography component={'li'}>
											<strong>Step 4:</strong> Check the chapter previews -- do they look any better?
										</Typography>
										<Typography component={'li'}>
											<strong>Step 5:</strong> When you're satisfied click "Finish"
										</Typography>
									</Typography>
								</div>
							</div>
						</div>

						<Divider className={classes.divider2} />

						{/* //SECTION: Format Options */}
						<div className={classes.contentSection}>
							<Typography className={classes.header} component={'h3'}>
								Format Options
							</Typography>

							<div className={classes.descriptionContainer}>
								<Typography className={classes.description}>
									<strong>Space Between Paragraphs:</strong> Insert or remove the breaks between paragraphs.
								</Typography>
							</div>

							<div className={classes.descriptionContainer}>
								<Typography className={classes.description}>
									<strong>Remove Line Indents:</strong> Removes any whitespace preceding text.
								</Typography>
								<Typography className={cx(classes.list, classes._withBullets)} component={'ul'}>
									<Typography component={'li'}>
										Not recommended if converting code or other material where line formatting is important.
									</Typography>
								</Typography>
							</div>

							<div className={classes.descriptionContainer}>
								<Typography className={classes.description}>
									<strong>Remove Page Numbers:</strong> Attempts to find and remove page numbers (ie. lines which only
									contain a number, or number and period).
								</Typography>
								<Typography className={cx(classes.list, classes._withBullets)} component={'ul'}>
									<Typography component={'li'}>Recommended for PDF.</Typography>
									<Typography component={'li'}>Likely unnecessary for EPUB.</Typography>
									<Typography component={'li'}>
										Not recommended if converting code or other material where valid text could be mistaken for a page
										number.
									</Typography>
								</Typography>
							</div>

							<div className={classes.descriptionContainer}>
								<Typography className={classes.description}>
									<strong>Alt Epub Formatting (EPUB only):</strong> If you experience major sentence fragmentation or
									misplaced newlines, enabling this may fix it.
								</Typography>
								<Typography className={cx(classes.list, classes._withBullets)} component={'ul'}>
									<Typography component={'li'}>Locks max line length to 100 characters.</Typography>
									<Typography component={'li'}>Unnecessary in most cases.</Typography>
								</Typography>
							</div>

							<div className={classes.descriptionContainer}>
								<Typography className={classes.description}>
									<strong>Max Line Length:</strong> The max number of characters allowed on a line before a line break
									occurs.
								</Typography>
								<Typography className={cx(classes.list, classes._withBullets)} component={'ul'}>
									<Typography component={'li'}>Setting this too high may cut off text on smaller screens.</Typography>
									<Typography component={'li'}>
										PDF and TXT will initially have this set to the max line length found in their source files. If
										changed, formatting errors (incorrectly placed line breaks) may occur sporadically.
									</Typography>
									<Typography component={'li'}>
										Initial max line lengths that are below the range minimum ({MIN_LINE_LENGTH}) are allowed. However,
										any additional formatting must be within range ({MIN_LINE_LENGTH}-{MAX_LINE_LENGTH}). Initial max
										line lengths over {MAX_LINE_LENGTH} will be shortened to {MAX_LINE_LENGTH} automatically.
									</Typography>
									<Typography component={'li'}>
										Changing this setting is not recommended if importing code. If you'd like to change the line length
										of code, you should do so in an IDE before trying to import it here. Note that if any line is over{' '}
										{MAX_LINE_LENGTH} characters (indentation included), it will completely destroy the formatting.
									</Typography>
								</Typography>
							</div>

							<div className={classes.divider1} />

							<div className={cx(classes.descriptionContainer, classes._radioGroupHeaderContainer)}>
								<Typography className={cx(classes.description, classes._radioGroupHeader)}>
									<strong>Short Chapters:</strong>
								</Typography>
							</div>
							<div className={classes.descriptionContainer}>
								<Typography className={cx(classes.description, classes._descriptionNoMargin)}>
									<strong>Remove (EPUB/TXT only):</strong> A chapter must contain at least {MIN_CHAPTER_LENGTH}{' '}
									characters. If a chapter contains fewer, this option will remove it.
								</Typography>
								<Typography className={cx(classes.list, classes._withBullets)} component={'ul'}>
									<Typography component={'li'}>Recommended for EPUB.</Typography>
								</Typography>
							</div>

							<div className={classes.descriptionContainer}>
								<Typography className={classes.description}>
									<strong>Merge (EPUB/TXT only):</strong> Merges any short chapters encountered into the following
									chapter.
								</Typography>
								<Typography className={cx(classes.list, classes._withBullets)} component={'ul'}>
									<Typography component={'li'}>
										Recommended for TXT if using the Manual Chapter Breaks option.
									</Typography>
								</Typography>
							</div>

							<div className={classes.divider1} />

							<div className={cx(classes.descriptionContainer, classes._radioGroupHeaderContainer)}>
								<Typography className={cx(classes.description, classes._radioGroupHeader)}>
									<strong>Detect New Chapter On:</strong>
								</Typography>
							</div>
							<div className={classes.descriptionContainer}>
								<Typography className={cx(classes.description, classes._descriptionNoMargin)}>
									<strong>Epub Default (EPUB only):</strong> Uses the chapter breaks encoded in the file.
								</Typography>
							</div>

							<div className={classes.descriptionContainer}>
								<Typography className={classes.description}>
									<strong>Manual Chapter Break (TXT only):</strong>
									{` Begins a new chapter wherever <<{CB}>> appears on a line by itself.`}
								</Typography>
							</div>

							<div className={classes.descriptionContainer}>
								<Typography className={classes.description}>
									<strong>Every ____ Pages:</strong> Breaks chapters evenly by a specific number of pages. Final page
									counts may differ (see Page Counts in the next section).
								</Typography>
							</div>
						</div>
						<Divider className={classes.divider2} />

						{/* //SECTION: Things to be Aware of */}
						<div className={classes.contentSection}>
							<Typography className={classes.header} component={'h3'}>
								Things To Be Aware Of
							</Typography>

							<div className={classes.descriptionContainer}>
								<Typography className={classes.description}>
									<strong>Book Details:</strong> Once formatting is complete, the title, synopsis, cover image, and
									chapter titles can be edited at any time from the book's Chapter Select page.
								</Typography>
							</div>

							<div className={classes.descriptionContainer}>
								<Typography className={classes.description}>
									<strong>Format Details:</strong> Text alterations and inclusions/exclusions of chapters are permanent
									and cannot be changed once formatting is complete.
								</Typography>
							</div>

							<div className={classes.descriptionContainer}>
								<Typography className={classes.description}>
									<strong>Custom Book Storage:</strong> Custom books are stored on your browser. Because of this, they
									are inaccessible on other browsers. If the browser's IndexedDb storage is cleared for any reason, your
									books will be lost.
								</Typography>
							</div>

							{/* <div className={classes.descriptionContainer}>
								<Typography className={classes.description}>
									<strong>Custom Book Browser Space: </strong> The amount of space on your browser available to store
									custom books, and whether usage can even be tracked, varies between browser. In most cases these
									limits should be high enough that your won't need to worry about running out of space. Below are some
									current limit estimations:
								</Typography>
								<Typography className={classes.list} component={'ul'}>
									<Typography component={'li'}>
										<strong>Chrome:</strong> 60% of total hard drive storage
									</Typography>
									<Typography component={'li'}>
										<strong>Firefox:</strong> 2GB
									</Typography>
									<Typography component={'li'}>
										<strong>Safari:</strong> 1GB
									</Typography>
								</Typography>
							</div> */}

							<div className={classes.descriptionContainer}>
								<Typography className={classes.description}>
									<strong>Custom Book Stats: </strong> Custom Book stats are tracked the same as other books on the
									site. However, once a book is created, it's unique as far as stats tracking is concerned. Even if you
									import the same file again on a different browser, it won't be linked to the book's previous stats.
								</Typography>
							</div>

							<div className={classes.descriptionContainer}>
								<Typography className={classes.description}>
									<strong>Page Counts:</strong> Previewed page counts may differ slightly to the final page counts,
									especially if a chapter is approaching 100 or more pages.
								</Typography>
							</div>

							<div className={classes.descriptionContainer}>
								<Typography className={classes.description}>
									<strong>Gibberish Formatting:</strong> If a file produces gibberish characters, it was likely encoded
									in a non-standard way and there's nothing this site can do about it. The issue is most prevalent in
									PDFs which were converted from another format.
								</Typography>
							</div>
						</div>
						<Divider className={classes.divider2} />

						{/* // SECTION: SHARED CONTENT -- LIMITATIONS + FORMATTING RULES*/}
						<SharedCustomBookLimitations inDialog={true} />
						<Divider className={classes.divider2} />
						<SharedCustomBookFormattingRules />
					</div>
				</Paper>
			</FocusTrap>
		</aside>
	)
}

export default FormatterHowToDialog
