import {
	theme003,
	theme004,
	theme005,
	theme006,
	theme007,
	theme008,
	theme009,
	theme010,
	theme011,
	theme012,
	theme013,
	theme014,
	theme015,
	theme016,
	theme017
} from './premiumConsoleThemes'

// NOTE: Here so you can add to them without going to other files
export interface ThemeMenuProps {
	defaultLabel: string
	theme: ConsoleTheme
}

export enum ThemeMenuSection {
	CLASSIC_THEMES = 'CLASSIC_THEMES',
	PREMIUM_THEMES = 'PREMIUM_THEMES'
}

export const getThemeProps = (settingsSection: ThemeMenuSection) => {
	const props: { [key: string]: ThemeMenuProps[] } = {
		CLASSIC_THEMES: [
			{
				defaultLabel: 'Classic Dark',
				theme: ConsoleTheme.DARK
			},
			{
				defaultLabel: 'Classic Light',
				theme: ConsoleTheme.LIGHT
			}
		],
		PREMIUM_THEMES: [
			{
				defaultLabel: 'Timber',
				theme: ConsoleTheme.THEME003
			},
			{
				defaultLabel: 'Ocean',
				theme: ConsoleTheme.THEME007
			},
			{
				defaultLabel: 'Bubblegum',
				theme: ConsoleTheme.THEME005
			},
			{
				defaultLabel: 'Newsprint',
				theme: ConsoleTheme.THEME013
			},
			{
				defaultLabel: 'Smoothie',
				theme: ConsoleTheme.THEME004
			},
			{
				defaultLabel: 'Cinder',
				theme: ConsoleTheme.THEME006
			},
			{
				defaultLabel: 'Bumblebee',
				theme: ConsoleTheme.THEME008
			},
			{
				defaultLabel: 'Terracotta',
				theme: ConsoleTheme.THEME015
			},
			{
				defaultLabel: 'Canopy',
				theme: ConsoleTheme.THEME016
			},
			{
				defaultLabel: 'Lagoon',
				theme: ConsoleTheme.THEME010
			},
			{
				defaultLabel: 'Mellow',
				theme: ConsoleTheme.THEME011
			},

			{
				defaultLabel: 'Surf',
				theme: ConsoleTheme.THEME012
			},
			{
				defaultLabel: 'Platoon',
				theme: ConsoleTheme.THEME017
			},
			{
				defaultLabel: 'Beachside',
				theme: ConsoleTheme.THEME014
			},
			{
				defaultLabel: 'Pulse',
				theme: ConsoleTheme.THEME009
			}
		]
	}
	return props[settingsSection]
}

export enum ConsoleTheme {
	LIGHT = 'light',
	DARK = 'dark',
	THEME003 = 'theme003',
	THEME004 = 'theme004',
	THEME005 = 'theme005',
	THEME006 = 'theme006',
	THEME007 = 'theme007',
	THEME008 = 'theme008',
	THEME009 = 'theme009',
	THEME010 = 'theme010',
	THEME011 = 'theme011',
	THEME012 = 'theme012',
	THEME013 = 'theme013',
	THEME014 = 'theme014',
	THEME015 = 'theme015',
	THEME016 = 'theme016',
	THEME017 = 'theme017'
}

export const consoleThemeNameVarsMap = {
	[ConsoleTheme.THEME003]: theme003,
	[ConsoleTheme.THEME004]: theme004,
	[ConsoleTheme.THEME005]: theme005,
	[ConsoleTheme.THEME006]: theme006,
	[ConsoleTheme.THEME007]: theme007,
	[ConsoleTheme.THEME008]: theme008,
	[ConsoleTheme.THEME009]: theme009,
	[ConsoleTheme.THEME010]: theme010,
	[ConsoleTheme.THEME011]: theme011,
	[ConsoleTheme.THEME012]: theme012,
	[ConsoleTheme.THEME013]: theme013,
	[ConsoleTheme.THEME014]: theme014,
	[ConsoleTheme.THEME015]: theme015,
	[ConsoleTheme.THEME016]: theme016,
	[ConsoleTheme.THEME017]: theme017
}

export const classicConsoleThemes = [ConsoleTheme.DARK, ConsoleTheme.LIGHT]
