import { FileWithPath } from 'react-dropzone'
import { browserName, fullBrowserVersion } from 'react-device-detect'
import { handleErrorOnFrontend } from '../../util/errorHandlers'
import { JSZipLoadOptions } from 'jszip'

export const browserSupportsBlob = async () => {
	const JSZip = (await import('jszip')).default
	JSZip.support.blob
}

// NOTE: Only runs handleErrorOnFrontend -- Make sure you disable loading screen and return in calling function
export const handleBlobSupportError = (errorCallback: Function) => {
	handleErrorOnFrontend(
		`Browser doesn't support blob - Browser: ${browserName}, Version: ${fullBrowserVersion}`,
		"This feature doesn't support your browser version. Please update your browser or try a different one.",
		errorCallback
	)
}

export const zipIt = async (content: File | FileWithPath | string, contentName: string) => {
	const zip = (await import('jszip')).default()

	zip.file(contentName, content)
	const zipContent = await zip.generateAsync({
		type: 'blob', // NOTE: Should only need blob if zipping on the frontend
		compression: 'DEFLATE',
		compressionOptions: {
			level: 9
		}
	})
	return zipContent
}

export const unzipIt = async (
	zipContent: File | FileWithPath | string,
	contentName: string,
	options?: JSZipLoadOptions
) => {
	const zip = (await import('jszip')).default()
	const content = (await (await zip.loadAsync(zipContent, options)).file(contentName)?.async('text')) as string
	return content
}
