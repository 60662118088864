import { HelpOutline, InfoOutlined } from '@mui/icons-material'
import { Tooltip, Typography } from '@mui/material'

import { makeStyles } from 'makeStyles'
import { ReactElement, ReactNode } from 'react'
import { Palette, PaletteTheme } from '../../../util/palette/Palette'

// NOTE: Tooltips next to labels in format page require a div wrapper with class similar to labelWithTooltip (see OptionsStyles.ts)
const useOptionsStyles = (palette: PaletteTheme) => {
	return makeStyles()({
		tooltipContainer: {
			display: 'flex',
			alignItems: 'center'
		},
		toolTipIcon: {
			fontSize: 16,
			'&:hover': {
				cursor: 'default'
			}
		},
		tooltipText: {
			fontSize: 12
		}
	})
}

interface DefaultTooltipProps {
	label: string | ReactElement | ReactNode
	className?: string
	iconName?: string
	iconText?: string
	placement?:
		| 'left-end'
		| 'bottom-end'
		| 'bottom-start'
		| 'bottom'
		| 'left-start'
		| 'left'
		| 'right-end'
		| 'right-start'
		| 'right'
		| 'top-end'
		| 'top-start'
		| 'top'
	disabled?: boolean
}

const DefaultTooltip = ({ label, className, iconName, iconText, placement, disabled }: DefaultTooltipProps) => {
	const { classes, cx } = useOptionsStyles(Palette.getPalette())()

	const iconNameMap: { [key: string]: ReactElement } = {
		InfoOutlined: <InfoOutlined className={classes.toolTipIcon} />
	}

	return (
		<div className={cx(classes.tooltipContainer, className)}>
			<Tooltip
				title={<Typography className={classes.tooltipText}>{label}</Typography>}
				PopperProps={{ style: { zIndex: 2500 } }}
				placement={placement ?? 'left-end'}
				tabIndex={disabled ? -1 : 0}
				disableFocusListener={disabled}
				disableHoverListener={disabled}
				disableTouchListener={disabled}
				aria-label='tooltip'>
				{iconText ? (
					<Typography>{iconText}</Typography>
				) : (
					iconNameMap[iconName ?? ''] ?? <HelpOutline className={classes.toolTipIcon} />
				)}
			</Tooltip>
		</div>
	)
}

export default DefaultTooltip
