import ReactGA from 'react-ga4'
import { devLog } from './utilFunctions'

// SECTION: Setup hood for Google Analytics -- used in _app.tsx
interface GAEvent {
	action: string
	category: string
	label?: string
}

export const getAnalytics = () => {
	return {
		init: (trackingId: string) => {
			ReactGA.initialize(trackingId)
		},
		trackPageViewed: () => {
			ReactGA.send('pageview')
		},
		trackEvent: ({ action, category, label }: GAEvent) => {
			ReactGA.event({ action, category, label })
		}
	}
}
