import { createContext, useState, useEffect, useContext } from 'react'
import { isIOS, isMobile, isSafari } from 'react-device-detect'
import { getLocalStorage, renderLog, _useEffectLog } from '../util/utilFunctions'
import { Palette } from '../util/palette/Palette'
import { UtilContext } from './UtilContext'
import { ConsoleTheme, classicConsoleThemes } from '../util/palette/premiumConsoleThemesMapsAndTypes'
import { UserAccountType } from '../types'
import { useSession } from 'next-auth/react'
import { classicFontNames } from '../util/fonts/fontNames'

// SECTION: Types
// NOTE: Keep this (and anything that enables needed fast access to variables) as a context. Faster than fetching from localStorage every time.
// QUIRK: Settings enums are lowercase I guess
export enum WpmAccDisplaySetting {
	word = 'word',
	line = 'line',
	page = 'page'
}

export enum CursorStyle {
	box = 'box',
	line = 'line',
	none = 'none'
}

interface UserSettings {
	darkModeEnabled: boolean
	readingModeEnabled: boolean
	consoleTheme: ConsoleTheme
	consoleFont: string
	virtualKeyboardEnabled: boolean
	internationalModeEnabled: boolean
	ignoreCapitalizationEnabled: boolean
	skipPunctuationEnabled: boolean
	stopCursorAfterMistypeEnabled: boolean
	showLiteralMistypesEnabled: boolean
	autoScrollEnabled: boolean
	wpmAccDisplaySetting: WpmAccDisplaySetting
	cursorStyle: CursorStyle
}

// SECTION: Context
export const UserSettingsContext = createContext({
	userSettingsObj: {} as UserSettings,
	lastOptionChanged: '',
	setUserSettingsObj: (userSettingsObj: UserSettings) => {},
	getDarkModeEnabled: (): boolean => true,
	getConsoleTheme: (): ConsoleTheme => ConsoleTheme.DARK,
	getConsoleFont: (): string => '',
	toggleDarkModeEnabled: () => {},
	getInternationalModeEnabled: (): boolean => false,
	getVirtualKeyboardEnabled: (): boolean => false,
	getReadingModeEnabled: (): boolean => false,
	getIgnoreCapitalizationEnabled: (): boolean => false,
	getSkipPunctuationEnabled: (): boolean => false,
	getStopCursorAfterMistypeEnabled: (): boolean => false,
	getShowLiteralMistypesEnabled: (): boolean => true,
	getAutoScrollEnabled: (): boolean => true,
	getWpmAccDisplaySetting: (): WpmAccDisplaySetting => WpmAccDisplaySetting.word,
	getCursorStyle: (): CursorStyle => CursorStyle.box,
	resetDefaultSettings: () => {},
	setLastOptionChanged: (optionName: string) => {},
	reRender: () => {}
})

// SECTION: Provider
export const UserSettingsProvider = (props: any) => {
	// SECTION: Hooks and Default Settings Obj
	const [userSettingsObj, setUserSettingsObj] = useState({} as UserSettings)
	const [reRenderVar, setReRenderVar] = useState(false)
	const [lastOptionChanged, setLastOptionChanged] = useState('')
	const [loadingSettings, setLoadingSettings] = useState(true)

	// NOTE: Make sure you add default settings here if adding a new option!!
	const defaultSettingsObj = {
		darkModeEnabled: true,
		consoleTheme: ConsoleTheme.DARK,
		consoleFont: 'Literata',
		readingModeEnabled: false,
		internationalModeEnabled: false,
		// virtualKeyboardEnabled: false, // QUIRK: Turn this on instead of the below if working on native keyboard
		virtualKeyboardEnabled: isMobile ? true : false,
		ignoreCapitalizationEnabled: false,
		skipPunctuationEnabled: false,
		stopCursorAfterMistypeEnabled: false,
		showLiteralMistypesEnabled: true,
		autoScrollEnabled: true,
		wpmAccDisplaySetting: WpmAccDisplaySetting.word,
		cursorStyle: isIOS || isSafari ? CursorStyle.line : CursorStyle.box // QUIRK: box cursor styles dissappear reappear on Safari (desktop) or any browser on iOS (mobile)
	} as UserSettings

	const { userAccountType, userId } = useContext(UtilContext)

	const { data: session, status } = useSession()

	// USE EFFECT: Ensure the correct theme is rendered
	useEffect(() => {
		_useEffectLog('USER SETTINGS CONTEXT useEffect 1')

		Palette.setDarkModeEnabled(getDarkModeEnabled())
		reRender()
	}, [userSettingsObj.darkModeEnabled])

	// USE EFFECT: Loads settings from local storage OR saves settings to local storage whenever they change
	useEffect(() => {
		_useEffectLog('USER SETTINGS CONTEXT useEffect 2')

		// Don't run if settings have already been loaded
		if (Object.keys(userSettingsObj).length > 0) return

		// Don't run until session is loaded and userId is available (if authenticated)
		if (status === 'loading' || (status === 'authenticated' && !userId)) return

		let newUserSettingsObj = fetchUserSettingsFromLocalStorage()

		if (Object.keys(newUserSettingsObj).length > 0) {
			// Include any missing keys, setting vals to default
			newUserSettingsObj = addMissingSettings(newUserSettingsObj)
			setUserSettingsObj(newUserSettingsObj)
		} else {
			// sets a new settings obj with default values for all keys
			setUserSettingsObj(defaultSettingsObj)
		}

		setLoadingSettings(false)
	}, [session, userId])

	// HELPERS
	// Sets userSettingsObj to that in local storage + adds any missing keys, setting vals to default
	const fetchUserSettingsFromLocalStorage = () => {
		let newUserSettingsObj

		if (status === 'unauthenticated') {
			newUserSettingsObj = getLocalStorage()?.getItem(`typelit-usersettings`)
		} else if (status === 'authenticated' && userId) {
			newUserSettingsObj = getLocalStorage()?.getItem(`typelit-usersettings-${userId}`)
		}

		newUserSettingsObj = newUserSettingsObj ? JSON.parse(newUserSettingsObj) : ({} as UserSettings)

		return newUserSettingsObj
	}

	const addMissingSettings = (newUserSettingsObj: UserSettings) => {
		for (const [key, setting] of Object.entries(defaultSettingsObj)) {
			if (!(key in newUserSettingsObj)) {
				newUserSettingsObj[key] = setting
			}
		}

		return newUserSettingsObj
	}

	// USE EFFECT: Saves settings to local storage whenever they change
	useEffect(() => {
		_useEffectLog('USER SETTINGS CONTEXT useEffect 2.5')

		if (Object.keys(userSettingsObj).length > 0) {
			saveUserSettingToLocalStorage()
		}
	}, [userSettingsObj, loadingSettings])

	//HELPERS
	const saveUserSettingToLocalStorage = () => {
		if (userId) {
			getLocalStorage()?.setItem(`typelit-usersettings-${userId}`, JSON.stringify(userSettingsObj))
		} else {
			getLocalStorage()?.setItem(`typelit-usersettings`, JSON.stringify(userSettingsObj))
		}
	}

	// USE EFFECT: Resets console theme and font if not subscribed
	useEffect(() => {
		_useEffectLog('USER SETTINGS CONTEXT useEffect 3')

		// Don't run until settings are loaded
		if (loadingSettings) return

		// Don't run if using both classic font and theme
		if (classicConsoleThemes.includes(getConsoleTheme()) && classicFontNames.includes(getConsoleFont())) return

		if (status === 'unauthenticated' || (status === 'authenticated' && userAccountType === UserAccountType.FREE)) {
			setUserSettingsObj({
				...userSettingsObj,
				darkModeEnabled: getDarkModeEnabled(),
				consoleTheme: getDarkModeEnabled() ? ConsoleTheme.DARK : ConsoleTheme.LIGHT,
				consoleFont: classicFontNames.includes(getConsoleFont()) ? getConsoleFont() : 'Literata'
			})
		}
	}, [status, userAccountType, loadingSettings])

	// SECTION: Local Obj Getters / Setters / Toggles
	// Note: Most setters triggered from options menus
	const getDarkModeEnabled = () => {
		return userSettingsObj.darkModeEnabled
	}

	const toggleDarkModeEnabled = () => {
		const handlePossibleConsoleThemeChange = () => {
			if (userSettingsObj.consoleTheme === ConsoleTheme.DARK) {
				return ConsoleTheme.LIGHT
			} else if (userSettingsObj.consoleTheme === ConsoleTheme.LIGHT) {
				return ConsoleTheme.DARK
			} else {
				return userSettingsObj.consoleTheme
			}
		}

		setUserSettingsObj({
			...userSettingsObj,
			darkModeEnabled: !userSettingsObj.darkModeEnabled,
			consoleTheme: handlePossibleConsoleThemeChange()
		})
	}

	const getConsoleTheme = () => {
		return userSettingsObj.consoleTheme
	}

	const getConsoleFont = () => {
		return userSettingsObj.consoleFont
	}

	const getReadingModeEnabled = () => {
		return userSettingsObj.readingModeEnabled
	}

	const getInternationalModeEnabled = () => {
		return userSettingsObj.internationalModeEnabled
	}

	const getVirtualKeyboardEnabled = () => {
		return userSettingsObj.virtualKeyboardEnabled
	}

	const getIgnoreCapitalizationEnabled = () => {
		return userSettingsObj.ignoreCapitalizationEnabled
	}

	const getSkipPunctuationEnabled = () => {
		return userSettingsObj.skipPunctuationEnabled
	}

	const getStopCursorAfterMistypeEnabled = () => {
		return userSettingsObj.stopCursorAfterMistypeEnabled
	}

	const getShowLiteralMistypesEnabled = () => {
		return userSettingsObj.showLiteralMistypesEnabled
	}

	const getAutoScrollEnabled = () => {
		return userSettingsObj.autoScrollEnabled
	}

	const getWpmAccDisplaySetting = () => {
		return userSettingsObj.wpmAccDisplaySetting
	}

	const getCursorStyle = () => {
		return userSettingsObj.cursorStyle
	}

	// SECTION: Misc
	const resetDefaultSettings = () => {
		// Dark Mode and Console Theme options not affected by reset to defaults
		const newDefaultSettings = defaultSettingsObj
		newDefaultSettings.darkModeEnabled = getDarkModeEnabled()
		newDefaultSettings.consoleTheme = getConsoleTheme()

		setUserSettingsObj(newDefaultSettings)
		setLastOptionChanged('resetDefaults')
	}

	const reRender = () => {
		setReRenderVar(!reRenderVar)
	}

	// NOTE: useMemo doesn't reduce renders + may mess up routing unless windowWidth included
	return (
		<UserSettingsContext.Provider
			value={{
				userSettingsObj,
				lastOptionChanged,
				setUserSettingsObj,
				getDarkModeEnabled,
				toggleDarkModeEnabled,
				getConsoleTheme,
				getConsoleFont,
				getVirtualKeyboardEnabled,
				getInternationalModeEnabled,
				getReadingModeEnabled,
				getIgnoreCapitalizationEnabled,
				getSkipPunctuationEnabled,
				getStopCursorAfterMistypeEnabled,
				getShowLiteralMistypesEnabled,
				getAutoScrollEnabled,
				getWpmAccDisplaySetting,
				getCursorStyle,
				resetDefaultSettings,
				setLastOptionChanged,
				reRender
			}}>
			{props.children}
			{renderLog('USER SETTINGS CONTEXT')}
		</UserSettingsContext.Provider>
	)
}
