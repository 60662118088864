import { Typography } from '@mui/material'
import { Palette } from '../../../util/palette/Palette'
import { renderLog } from '../../../util/utilFunctions'
import useStyles from './sharedDialogContentStyles'

// NOTE: Page specific How-to content is in their respective page component folders
interface SharedCustomBookHowToContentProps {
	inDialog?: boolean
	inAccordion?: boolean
}

const SharedCustomBookLimitations = ({ inDialog, inAccordion }: SharedCustomBookHowToContentProps) => {
	const { classes } = useStyles(Palette.getPalette())()

	return (
		<div className={inDialog ? classes.contentSection : ''}>
			{renderLog('SHARED CUSTOM BOOK LIMITATIONS')}
			{!inAccordion && (
				<Typography className={inDialog ? classes.headerInDialog : classes.header} component={'h3'}>
					Limitations
				</Typography>
			)}

			<div className={inDialog ? classes.descriptionContainer : ''}>
				<Typography className={classes.description}>
					<strong>Supported File Formats:</strong> Non-encrypted EPUB, PDF, and TXT files only. Must be utf-8 encoded
					(most things are).
				</Typography>
				<Typography className={classes.description}>
					<strong>Unsupported File Formats:</strong> MOBI, AZW, AZW3 (ie, any ebook from Amazon) and all other
					proprietary file formats are <strong>not supported.</strong>
				</Typography>
				<Typography className={classes.description}>
					<strong>Encryption and DRM:</strong> Encrypted or DRM protected files <strong>will not work</strong> with this
					site. If you're buying an ebook with the intention of using it with this site, please make sure it's not
					encrypted and is DRM-free beforehand.
				</Typography>

				<div className={classes.divider1} />

				<Typography className={classes.description}>
					<strong>Supported Languages:</strong> Any left-to-right languages where one character can be typed with one
					keystroke, or with modifier/dead keys like those on the US International Keyboard layout, <i>should</i> work
					(but no guarantees).
				</Typography>
				<Typography className={classes.description}>
					<strong>Unsupported Languages:</strong> Any languages where complex characters are "built" from multiple
					keystrokes (Mandarin, Japanese, etc) are <strong>not supported</strong> at this time. Languages typed
					right-to-left (Arabic, Hebrew, etc) are also <strong>not currently supported.</strong>
				</Typography>

				<div className={classes.divider1} />

				<Typography className={classes.description}>
					<strong>Accents and Apple:</strong> Limited support for typing accented characters on Apple devices when using
					US International Keyboard (or similar keyboard layouts).
				</Typography>
				<Typography className={classes.description}>
					<strong>Embedded Images:</strong> Ignored completely. Text that is part of an image will be removed.
				</Typography>
				<Typography className={classes.description}>
					<strong>Every File is Different:</strong> There's no guarantee this service will work with every EPUB, PDF, or
					TXT file even if the above guidelines are followed.
				</Typography>
			</div>
		</div>
	)
}

export default SharedCustomBookLimitations
