import { devLog, isReactInDevelopmentMode } from '../utilFunctions'
import { isMobile, mobileVendor } from 'react-device-detect'
import { keyframes } from 'tss-react'
import { mulishFont } from '../fonts/baseNextFontLoader'
import { ConsoleTheme, consoleThemeNameVarsMap } from './premiumConsoleThemesMapsAndTypes'
import { assembleConsolePalette } from './premiumConsoleThemes'

// NOTE If it's mobile apple, the color is 5 steps darker (if light color) or lighter (if dark dark color) then normal
// NOTE That's steps on this site: https://www.hexcolortool.com/

export interface PaletteTheme {
	[key: string]: string
}

// SECTION: SHARED VALUES -- merged into Light/DarkModePalettes
const sharedValues = {
	// SUBSECTION: Layout
	headerBarHeight: '48px',
	footerBarHeight: '36px',
	fullHeightMinusHeaderAndFooter: `calc(100vh - 48px - 78px)`,
	fullHeightMinusHeaderAndMobileFooter: `calc(100vh - 48px - 47px)`,
	fullHeightMinusHeaderAndBreadcrumbs: `calc(100vh - 48px - 26px)`,
	fullHeightMinusHeader: `calc(100vh - 48px)`,

	// SUBSECTION: Hover Transitions
	linkHoverTransition: '0.1s',
	cardHoverTransition: '0.1s',
	buttonHoverTransition: '0.15s !important',

	// SUBSECTION: Animations
	// NOTE: Be careful to use raw CSS syntax for animations
	checkboxFlash: `${keyframes`
				0% {
					color: #27c25b;
				}
			`} 1s ease-out`,
	buttonBorderFlash: `${keyframes`
				0% {
					border-color: #27c25b;
				}
			`} 1s ease-out`,
	inputHintFlash: `${keyframes`
				0% {
					color: #ee5363;
				}
			`} 1s ease-out`,
	inputUnderlineFlash: `${keyframes`
				0% {
					border-bottom: 1px solid #ee5363;
				}
			`} 1s ease-out`
}

// SECTION: LIGHT MODE
export const lightModePalette: PaletteTheme = {
	...sharedValues,
	...{
		mainBackgroundColor: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#d9dedc' : '#eef1f0', //this will show the orig bg in dev mode (even when on CrossBrowserTesting website) (orig BG: #EFF2F1)
		background1: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#ededed' : '#f7f7f7', // book/chapter card backgrounds -- lightens to background 4 when hovered over
		background2: '#323432', // header bar background
		background3: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#ededed' : '#f7f7f7', // secondary bg in about page and payment pages/modules
		background4: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#e3e3e3' : '#fafafa', // secondary background
		background5: '#00000077', // dialog transparant background
		background6: '', // blends dialog with background in dark mode (may be tablet only)
		background7: '#ffffff77', // transparant loading screen bg
		background8: '', // Add New Custom Book card
		background9: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#e6e6e6' : '#f1f1f1', // quick convert img container bg
		background10: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#e3e3e3' : '#fafafa', // Cookies Banner
		background11: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#d9dedc' : '#eef1f0', // Disabled Preview (same as mainBackgroundColor on Light Mode)

		tabHover1: '#eef1f044',

		bookDetailsModuleInputBackground:
			isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#ededed' : '#f7f7f7', // DarkMode doesn't use this

		imageHoverOverlayBackground:
			isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#d9dedc' : '#eef1f0',

		scrollbarTrack: '#33333311',
		scrollbarThumb: '#333333',

		virtualKeyboardShadow: '0px 5px 9px #333333', // light mode only -- dark uses a border instead
		virtualKeyboardButton: '#fcfcfc',
		virtualKeyboardButtonOnPress: '#efefef',

		border1: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#c6d2ce' : '#DBE2E0', //Book and chapter card border
		border2: 'none', // Timer paused/reset page warning border (darkmode only)
		border3: '#506fc5', // editModeInputBorder
		border4: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#85ffa3' : '#b5ffc8', // News container border (colored so people know to look -- but not so in dark mode)
		border5: '#3f51b5', // selected book cover
		border6: '',
		border7: '#27c25b',
		border8: '#0535fa', // focus on chapter page inputs
		border9: '#ee5363', // remove DeadBook and other error/deletion type actions
		border10: '',
		border11: '#00000000', // login success alert border

		divider1: '#EFF2F1', // News container divider, stats page section dividers
		divider2: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#c9d4d1' : '#d7dfdd', // Divider on mainBackgroundColor

		header1: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#d9dedc' : '#EFF2F1', // Header Bar Contents

		font0: '"Roboto", "Helvetica", "Arial", sans-serif',
		font1: `${mulishFont}, "Helvetica", "Arial", sans-serif`,
		// font2: literataFont, // NOTE: Access Literata via 'font-family: "Literata"
		text1: '#333333', // Primary black/white text
		text2: '#787878', // Used by breadcrumbs to show saved stats before typing
		text3: '', // Colors omited because they look dumb against light mode bg
		text4: '',
		text5: '#333333', // breadcrumb text -- same as text1, unless using premium theme
		readingModeText: '#424242',
		disabled1: '#999999',

		link1: '#333333', // Link color change on hover
		link2: '#3b5fed', // Regular link before hover over
		link3: '#79817f', // Icons and footer Links before being hovered over
		link4: '#2a4acb', // Link color change on hover (link2 => link 4)

		amazonAffiliateLink: '#FF9900',
		amazonAffiliateLinkHover: '#d68100',
		ebayAffiliateLink: '#F5AF02',
		ebayAffiliateLinkHover: '#ca9102',

		loadingOverlayText: '#333333',

		noSessionIcon: '#ee5363',
		invalidInput: '#ee5363',

		dropzoneAcceptBorder: '#27c25b',
		dropzoneRejectBorder: '#ee5363',

		dropdownBackground1: '', //Leave blank to get the default styles from Material UI
		dropdownSelected1: '#00000014',
		dropdownHover1: '',
		dropdownBackground2: '', //Leave blank to get the default styles from Material UI
		dropdownHover2: '',

		consoleNavArrows: '#808080',
		buttonHover0: '',
		iconHover0: '',
		buttonSelected0: '',

		paymentBackground1: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#e3e3e3' : '#fafafa',
		buttonHover1: '#2750c211',
		buttonHover2: '#a8821911',
		buttonHover3: '#27c25b22',
		buttonHover4: '#ee536322',
		buttonHoverText1: 'black', // Used for on hover on transparant background buttons
		buttonRipple1: '#2750c2',
		buttonRipple2: '#a88219',
		buttonRipple3: '#27c25b',
		buttonRipple4: '#ee5363',
		paperShadow1: '#2750c2', // Paypal Container
		paperShadow2: '#a88219', // Crypto Container

		cardRipple: '#909090',

		// NOTE: Used in SettingsItems.tsx to display diff cursor styles. Actual character styling handles by css files in charObjThemeStyles
		charUntyped: '#808080',
		charTyped: ' #333333',
		charLocked: '#333333',
		charActiveBoxText: ' #808080',
		charActiveBoxBg: '#6ffb90 ',
		charActiveLineText: '  #808080',
		charActiveLineBoxShadow: '#333333',
		charActiveNone: '#808080',
		charMistyped: '#ee5363',
		charSpaceMistypedText: '#494949',
		charSpaceMistypedBg: '#fb6f7d',
		charFirstActveBlinkFill: '#808080'
	}
}

// SECTION: DARK MODE
export const darkModePalette: PaletteTheme = {
	...sharedValues,
	...{
		mainBackgroundColor: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#1f1f1f' : '#111111', //this will show the orig bg in dev mode (even when on CrossBrowserTesting website)
		background1: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#2e2e2e' : '#212121', // book/chapter card backgrounds -- darkens to background 4 when hovered over
		background2: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#242424' : '#171717', // header bar background
		background3: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#262626' : '#1a1a1a', // secondary bg in about page and payment pages/modules
		background4: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#262626' : '#1a1a1a', // secondary background
		background5: '#00000077', // dialog transparant background
		background6: '#00000077', // blends dialog with background in dark mode (may be tablet only)
		background7: '#00000080', // transparant loading screen bg -- 80 because otherwise you get a weird transparent box appearing
		background8: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#1f1f1f' : '#111111', // Add New Custom Book card -- same as bg color. Dark mode only
		background9: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#1a1a1a' : '#0e0e0e', // quick convert img container bg
		background10: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#1a1a1a' : '#0e0e0e', // Cookies Banner
		background11: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#212121' : '#131313', // Disabled preview

		tabHover1: '#ffffff04',

		bookDetailsModuleInputBackground: '',

		imageHoverOverlayBackground:
			isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#d9dedc' : '#eef1f0',

		scrollbarTrack: '#dedede11',
		scrollbarThumb: '#dedede',

		virtualKeyboardShadow: '',
		virtualKeyboardButton: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#1f1f1f' : '#111111',
		virtualKeyboardButtonOnPress:
			isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#1a1a1a' : '#0d0d0d',

		border1: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#1a1a1a' : '#0d0d0d', //Book and chapter card border
		border2: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#b3b3b3' : '#bfbfbf', // Timer paused border (darkmode only)
		border3: '#526598', // editModeInputBorder
		border4: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#1a1a1a' : '#0d0d0d', // News container border (colored so people know to look -- but not so in dark mode)
		border5: '#3f51b5', // selected book cover
		border6: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#333333' : '#272727', // disabled custom book card -- dark mode only
		border7: '#27c25b',
		border8: '#405cc9', // focus on chapter page inputs
		border9: '#ee5363', // remove DeadBook and other error/deletion type actions
		border10:
			isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '1px solid #b3b3b3' : '1px solid #bfbfbf', // Virtual Keyboard top border (dark mode mode only)
		border11: 'green', // login success alert border

		divider1: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#1f1f1f' : '#111111', // News container divider
		divider2: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#454545' : '#383838', // Divider on mainBackgroundColor

		header1: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#cfcfcf' : '#dedede',

		font0: '"Roboto", "Helvetica", "Arial", sans-serif',
		font1: `${mulishFont}, "Helvetica", "Arial", sans-serif`,
		// font2: literataFont, // NOTE: Access Literata via 'font-family: "Literata"
		text1: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#cfcfcf' : '#dedede', // Primary black/white text
		text2: '#919191', // Used by breadcrumbs to show saved stats before typing
		text3: '#27c25b',
		text4: '#f68041',
		text5: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#cfcfcf' : '#dedede', // breadcrumb text -- same as text1, unless using premium theme
		readingModeText: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#cfcfcf' : '#dbdbdb',

		loadingOverlayText: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#cfcfcf' : '#dedede', // text1 here -- different on light mode

		switch1: '#3f51b5', // on
		switch2: '#fafafa', // off
		disabled1: '#999999',

		link1: '#fafafa', // Link color change on hover (link3 => link 1)
		link2: '#5273f4', // Regular link before hover over
		link3: '#c0c4c3', // Icons and footer Links before being hovered over
		link4: '#6181ff', // Link color change on hover (link2 => link 4)

		amazonAffiliateLink: '#FF9900',
		amazonAffiliateLinkHover: '#fafafa',
		ebayAffiliateLink: '#F5AF02',
		ebayAffiliateLinkHover: '#fafafa',

		noSessionIcon: '#ee5363',
		invalidInput: '#ee5363',

		dropzoneAcceptBorder: '#38b261',
		dropzoneRejectBorder: '#ee5363',

		dropdownBackground1: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#1f1f1f' : '#111111',
		dropdownSelected1: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#262626' : '#1a1a1a',
		dropdownHover1: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#242424' : '#171717',

		// Profile dropdown in header
		dropdownBackground2: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#242424' : '#171717',
		dropdownHover2: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#1f1f1f' : '#111111',

		consoleNavArrows: '#808080',
		buttonHover0: 'transparent',
		iconHover0: '#ebebeb',
		buttonSelected0: '#2e2e2e',

		paymentBackground1: isMobile && mobileVendor === 'Apple' && !isReactInDevelopmentMode() ? '#242424' : '#171717',
		buttonHover1: '#2750c211',
		buttonHover2: '#a8821911',
		buttonHover3: '#27c25b22',
		buttonHover4: '#ee536322',
		buttonHoverText1: 'white', // Used for on hover on transparant background buttons
		buttonRipple1: '#2750c2',
		buttonRipple2: '#a88219',
		buttonRipple3: '#27c25b',
		buttonRipple4: '#ee5363',
		paperShadow1: '#435ea8',
		paperShadow2: '#907731',

		cardRipple: '#ffffff',

		// NOTE: Used in SettingsItems.tsx to display diff cursor styles. Actual character styling handles by css files in charObjThemeStyles
		charUntyped: '#ababab',
		charTyped: ' #dbdbdb',
		charLocked: '#dbdbdb',
		charActiveBoxText: '#333333',
		charActiveBoxBg: ' #a2f1b5',
		charActiveLineText: '#ababab',
		charActiveLineBoxShadow: '#dbdbdb',
		charActiveNone: '#ababab',
		charMistyped: '#fa7582',
		charSpaceMistypedText: '#333333',
		charSpaceMistypedBg: '#fc6e7c',
		charFirstActveBlinkFill: '#ababab'
	}
}

// SECTION: PALETTE CLASS
export class Palette {
	static darkModeEnabled: boolean | undefined = undefined

	static setDarkModeEnabled = (darkModeEnabled: boolean) => {
		Palette.darkModeEnabled = darkModeEnabled
	}

	static getPalette = (consolePalette?: any) => {
		return Palette.darkModeEnabled
			? { ...darkModePalette, ...(consolePalette ? consolePalette : {}) }
			: { ...lightModePalette, ...(consolePalette ? consolePalette : {}) }
	}

	static getConsolePalette = (consoleTheme: ConsoleTheme) => {
		return Palette.getPalette(
			!consoleTheme || [ConsoleTheme.DARK, ConsoleTheme.LIGHT].some((coreThemes) => consoleTheme?.includes(coreThemes))
				? undefined
				: assembleConsolePalette(consoleThemeNameVarsMap[consoleTheme])
		)
	}
}

// SECTION: DEFAULT PALETTE (for static rendering on server start)
export const materialUIGlobalThemePalette = (() => {
	return Palette.getPalette()
})()
