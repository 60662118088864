import { Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, Button } from '@mui/material'
import { signOut, useSession } from 'next-auth/react'
import React, { memo, useContext, useRef, useState } from 'react'
import { UserSettingsContext } from '../../contexts/UserSettingsContext'
import { PaletteTheme, Palette } from '../../util/palette/Palette'
import { devLog, getBaseUrl, renderLog } from '../../util/utilFunctions'
import { AccountCircleOutlined, Menu } from '@mui/icons-material'
import Link from 'next/link'
import { UserLayoutStateContext } from '../../contexts/UserLayoutStateContext'
import { BookDisplayTab } from '../BookSelectPage/BooksDisplayTabs'
import { UtilContext } from '../../contexts/UtilContext'
import { makeStyles } from 'makeStyles'
import { UserAccountType } from '../../types'

// SECTION: Styles
const useStyles = (palette: PaletteTheme) => {
	const { font1, text1, text5, dropdownHover2, dropdownBackground2, noSessionIcon } = palette

	return makeStyles()({
		openerText: {
			display: 'flex',
			justifyContent: 'space-between',
			alignContent: 'center',
			color: 'inherit', // standard white against headerbar
			minWidth: '5.7rem',
			cursor: 'pointer',
			fontFamily: font1,
			fontSize: 16, // applies to font only -- icon size specified in component
			fontWeight: 'bold',
			textTransform: 'none',
			padding: '0.5rem',
			marginRight: '0 !important' // NOTE: Safari adds margin when opening/closing menu for some reason
		},
		openerTextIconOnly: {
			minWidth: 0,
			cursor: 'pointer',
			textTransform: 'none',
			padding: '0.4rem' // if you change this make sure you change the "settingsButton" style in SettingsDrawer too
		},
		_inheritColor: {
			color: 'inherit' // standard white against headerbar
		},
		menuPopper: {
			zIndex: 5,
			minWidth: '7.3rem' // prevents menu from changing size when toggling dark/light mode
		},
		userStatsMenu: {
			color: text5,
			backgroundColor: dropdownBackground2,
			'& ul': {
				backgroundColor: dropdownBackground2,
				padding: 0
			},
			'& li': {
				backgroundColor: dropdownBackground2,
				['@media (max-width:300px)']: {
					fontSize: '0.9rem'
				}
			},
			'& li:focus': {
				backgroundColor: dropdownHover2
			},
			'& li:hover': {
				backgroundColor: dropdownHover2
			}
		},
		rightClickableLink: {
			color: `${text1} !important`,
			textDecoration: 'none'
		},
		button: {
			color: text1
		},
		nonConsolePageIcon: {
			fontSize: 20
		},
		accountCircleConsolePageIcon: {
			fontSize: 32,
			color: text5
		},
		_noSession: {
			color: noSessionIcon
		}
	})
}

// NOTE: React.memo prevents rerenders if dom didn't change after parent render -- (as opposed to useMemo which watches context (and other) variables)
const ProfileDropdownMenu = memo(() => {
	// SECTION: Hooks
	const { userAccountType, currentUrl, baseUrl, currentPathname, isMobileDisplay, isThinMobileDisplay, isConsolePage } =
		useContext(UtilContext)
	const { getDarkModeEnabled, toggleDarkModeEnabled, getConsoleTheme } = useContext(UserSettingsContext)
	const { setActiveBookDisplayTab } = useContext(UserLayoutStateContext)

	const [open, setOpen] = useState(false)
	const anchorRef = useRef<HTMLButtonElement>(null)

	const { data: session, status } = useSession()

	const { classes, cx } = useStyles(
		isConsolePage() ? Palette.getConsolePalette(getConsoleTheme()) : Palette.getPalette()
	)()

	// SECTION: Functionality
	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen)
	}

	// Probebly for something involving input on the the Console Page
	const handleClose = (
		event: React.MouseEvent<EventTarget> | MouseEvent | TouchEvent | React.KeyboardEvent<HTMLDivElement>
	) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return
		}

		setOpen(false)
	}

	// SECTION: UI Getters
	// SUBSECTION: Icon Getters
	const getIconButton = (content: any, className?: string) => {
		return (
			<Button
				className={className || ''}
				ref={anchorRef}
				aria-label={'Profile'}
				onClick={handleToggle}
				onKeyDown={(event) => (event.key === 'Esc' || event.key === 'Escape') && setOpen(false)}>
				{content}
			</Button>
		)
	}

	const getSessionIcon = () => {
		return isConsolePage()
			? getIconButton(
					<AccountCircleOutlined className={classes.accountCircleConsolePageIcon} />,
					classes.openerTextIconOnly
			  )
			: getIconButton(
					<>
						<AccountCircleOutlined className={classes.nonConsolePageIcon} />
						Profile
					</>,
					classes.openerText
			  )
	}

	const getNoSessionIcon = () => {
		return isConsolePage()
			? getIconButton(
					<AccountCircleOutlined className={cx(classes.accountCircleConsolePageIcon, classes._noSession)} />,
					classes.openerTextIconOnly
			  )
			: getIconButton(
					<Menu className={classes.nonConsolePageIcon} />,
					cx(classes.openerTextIconOnly, classes._inheritColor)
			  )
	}

	// SUBSECTION: Menu Getters
	const getStatsItem = () => (
		<Link
			href={isConsolePage() ? `/users/stats?callbackUrl=${currentUrl}` : '/users/stats'}
			className={classes.rightClickableLink}
			onClick={(event) => {
				handleClose(event)
			}}>
			<MenuItem>Stats</MenuItem>
		</Link>
	)

	const getAccountItem = () => (
		<Link
			href='/users/account'
			className={classes.rightClickableLink}
			onClick={(event) => {
				handleClose(event)
			}}>
			<MenuItem>Account</MenuItem>
		</Link>
	)

	const getImportItem = () => (
		<Link
			href={
				currentPathname === '/users/account' && userAccountType === UserAccountType.FREE
					? '/premium'
					: '/bring-your-own-books/import'
			}
			className={classes.rightClickableLink}
			onClick={(event) => {
				handleClose(event)
			}}>
			<MenuItem>Import</MenuItem>
		</Link>
	)

	const getAboutItem = () => (
		<Link
			href='/about'
			className={classes.rightClickableLink}
			onClick={(event) => {
				handleClose(event)
			}}>
			<MenuItem>About</MenuItem>
		</Link>
	)

	const getDarkModeToggleItem = () => (
		<MenuItem className={classes.button} tabIndex={0} focusRipple={true} onClick={() => toggleDarkModeEnabled()}>
			{getDarkModeEnabled() ? 'Light Mode' : 'Dark Mode'}
		</MenuItem>
	)

	const getSignOutItem = () => (
		<MenuItem
			className={classes.button}
			tabIndex={0}
			focusRipple={true}
			onClick={() => {
				setActiveBookDisplayTab(BookDisplayTab.CLASSICS)
				signOut({ callbackUrl: baseUrl })
			}}>
			Sign Out
		</MenuItem>
	)

	/* //NOTE: Use regular link (which reloads page) to go to login page, otherwise callback isn't passed properly*/
	const getSignInItem = () => (
		<a
			className={classes.rightClickableLink}
			href={`/api/auth/signin?callbackUrl=${
				currentUrl.includes('/auth/') ? getBaseUrl() : encodeURIComponent(currentUrl) // Use base url for callbackUrl if already on SignIn Page
			}`}>
			<MenuItem>Sign In</MenuItem>
		</a>
	)

	const getSessionMenu = () => (
		<MenuList>
			{getStatsItem()}
			{isConsolePage() && getImportItem()}
			{!isConsolePage() && isMobileDisplay() && getImportItem()}
			{!isConsolePage() && isThinMobileDisplay() && getAboutItem()}
			{getAccountItem()}
			{!isConsolePage() && getDarkModeToggleItem()}
			{getSignOutItem()}
		</MenuList>
	)

	const getNoSessionMenu = () => (
		<MenuList>
			{getSignInItem()}
			{!isConsolePage() && getImportItem()}
			{isThinMobileDisplay() && getAboutItem()}
			{!isConsolePage() && getDarkModeToggleItem()}
		</MenuList>
	)

	// SECTION: Render UI
	return (
		<>
			{renderLog('PROFILE DROPDOWN')}
			{session ? getSessionIcon() : getNoSessionIcon()}

			<Popper
				className={classes.menuPopper}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				popperOptions={{ placement: 'bottom' }}
				transition
				disablePortal>
				{({ TransitionProps, placement }) => (
					<Grow {...TransitionProps} style={{ marginTop: '0.15rem' }}>
						<Paper
							className={classes.userStatsMenu}
							onKeyDown={(event) => (event.key === 'Esc' || event.key === 'Escape') && handleClose(event)}>
							<ClickAwayListener onClickAway={handleClose}>
								{session ? getSessionMenu() : getNoSessionMenu()}
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	)
})

export default ProfileDropdownMenu
