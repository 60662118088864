// ABOUT: Providers for contexts used by more than one page

import { FormatCustomBookProvider } from './FormatCustomBookContext'
import { UserBookmarksProvider } from './UserBookmarksContext'
import { UserThemesAndFontsProvider } from './UserThemesAndFontsContext'
import { UserDbStatsProvider } from './UserDbStatsContext'
import { UserLayoutStateProvider } from './UserLayoutStateContext'
import { UserLsStatsProvider } from './UserLsStatsContext'
import { UserMasterStatsProvider } from './UserMasterStatsContext'
import { UserNotesProvider } from './UserNotesContext'
import { UserSettingsProvider } from './UserSettingsContext'
import { UtilProvider } from './UtilContext'

export const CoreContextProviders = (props: any) => {
	return (
		<UserNotesProvider>
			<UtilProvider>
				<UserSettingsProvider>
					<UserLayoutStateProvider>
						<UserBookmarksProvider>
							<UserDbStatsProvider>
								<UserLsStatsProvider>
									<UserMasterStatsProvider>
										<UserThemesAndFontsProvider>
											<FormatCustomBookProvider>{props.children}</FormatCustomBookProvider>
										</UserThemesAndFontsProvider>
									</UserMasterStatsProvider>
								</UserLsStatsProvider>
							</UserDbStatsProvider>
						</UserBookmarksProvider>
					</UserLayoutStateProvider>
				</UserSettingsProvider>
			</UtilProvider>
		</UserNotesProvider>
	)
}
