import { Typography } from '@mui/material'
import { Palette } from '../../../util/palette/Palette'
import { renderLog } from '../../../util/utilFunctions'
import useStyles from './sharedDialogContentStyles'

// NOTE: Page specific How-to content is in their respective page component folders
const SharedCustomBookFormattingRules = () => {
	const { classes, cx } = useStyles(Palette.getPalette())()

	return (
		<div className={classes.contentSection}>
			{renderLog('SHARED CUSTOM BOOK FORMATTING RULES')}
			<Typography className={classes.header} component={'h3'}>
				Formatting Rules
			</Typography>
			<div className={classes.descriptionContainer}>
				<Typography className={classes.description}>
					The below are generally corrected for during conversion and don't need to be manually abided by in the source
					text:
				</Typography>
				<Typography className={cx(classes.list, classes._withBullets)} component={'ul'}>
					<Typography component={'li'}>
						Maximum one blank line can appear in a row -- adjacent blank lines are collapsed.
					</Typography>
					<Typography component={'li'}>
						Any amount of whitespace can appear before text on the same line (indentation), but it won't be typeable.
					</Typography>
					<Typography component={'li'}>
						Spaces are the only whitespace allowed in the middle of a line -- tabs and similar will be converted to
						spaces.
					</Typography>
					<Typography component={'li'}>
						Maximum one space may appear in the middle of a line -- adjacent spaces are collapsed.
					</Typography>
				</Typography>
			</div>
		</div>
	)
}

export default SharedCustomBookFormattingRules
