import { MutableRefObject } from 'react'
import { devLog } from '../../util/utilFunctions'

// SUBSECTION: Input Boundries
// These constants also used elsewhere
// QUIRK: If you change these values, you must also change the one in the text converter
export const MIN_LINE_LENGTH = 50 // Length is in number characters
export const MAX_LINE_LENGTH = 150
export const MIN_CHAPTER_LENGTH = 300
export const MIN_PAGES_PER_CHAPTER = 5
export const MAX_PAGES_PER_CHAPTER = 300
export const MIN_QUICK_CONVERT_LENGTH = 300 // Used by Quick Conver Module to validate text on the frontend -- should be the same as MIN_CHAPTER_LENGTH
export const MAX_QUICK_CONVERT_LENGTH = 50000
export const MAX_QUICK_CONVERT_LINES = 100 * 30 // 100 pages per chapters + 30 lines per page (100 because it makes a nice round number in quick convert module)
export const MIN_DISPLAY_TITLE_LENGTH = 0 // Used by generateOrigDisplayTitle
export const MAX_DISPLAY_TITLE_LENGTH = 120

export const numberFieldValidators: { [name: string]: { min: number; max: number } } = {
	maxLineLength: { min: MIN_LINE_LENGTH, max: MAX_LINE_LENGTH },
	maxPagesPerChapter: { min: MIN_PAGES_PER_CHAPTER, max: MAX_PAGES_PER_CHAPTER }
}

export const textFieldValidators: {
	[name: string]: { maxLength: number; maxLines?: number; invalidChars: string[]; validCharsRegex?: RegExp }
} = {
	quickConvert: {
		maxLength: MAX_QUICK_CONVERT_LENGTH,
		maxLines: MAX_QUICK_CONVERT_LINES,
		invalidChars: []
	},
	displayTitle: { maxLength: MAX_DISPLAY_TITLE_LENGTH, invalidChars: ['%'] }, // Mongodb, URI encoding, or something else doesn't like %. Will be replaced with empty spaces on the way back from Text Converter if allowed in displayTitle on Format Page.
	author: { maxLength: 30, invalidChars: [] },
	// language: { maxLength: 30, invalidChars: [] },
	synopsis: { maxLength: 1000, maxLines: 10, invalidChars: [] },
	chapterTitle: { maxLength: 90, invalidChars: [] },
	customThemeName: { maxLength: 25, invalidChars: [] },
	customColorInput: { maxLength: 9, invalidChars: [] }
}

// SUBSECTION: Input validators
// Runs whenever user types something and on input blur
export const validateNumberInput = (inputRef: MutableRefObject<any>) => {
	const { name, value } = inputRef.current
	const { max } = numberFieldValidators[name]

	// QUIRK: For value === '0' dont try to parse and compare as number -- you won't be able to empty field
	if (+value > max || value === '0') {
		inputRef.current.value = inputRef.current.prev
	} else {
		inputRef.current.prev = value
	}
}

export const validateTextInput = (inputRef: MutableRefObject<any>) => {
	const { name, value } = inputRef.current
	const { maxLength, maxLines, invalidChars } = textFieldValidators[name]

	let filteredInputString = value

	// Check for and prevent percent char in display title (messes up URI encoding in mongo if it exists)
	if (invalidChars.length > 0) {
		filteredInputString = (value as string)
			.split('')
			.filter((char) => {
				return !invalidChars.includes(char)
			})
			.join('')
	}

	// Validates against max number of lines
	if (maxLines && (filteredInputString.match(/\n/g) || []).length >= maxLines) {
		filteredInputString = filteredInputString.split('\n').slice(0, maxLines).join('\n')
	}

	// Validates against maxLength and updates the ref -- empty input allowed
	inputRef.current.value = filteredInputString.slice(0, maxLength)
}

export const validateCustomColorInput = (inputRef: MutableRefObject<any>) => {
	const { value } = inputRef.current
	const hexRegex = /^#[0-9a-fA-F]*$/

	if (hexRegex.test(value)) {
		inputRef.current.prev = value
	} else {
		inputRef.current.value = inputRef.current.prev
		return
	}

	validateTextInput(inputRef)
}

// SUBSECTION: Blurred Input Validators
// Runs whenever user clicks away from input. This will run before whatever they click.
export const validateNumberBlurredInput = (inputRef: MutableRefObject<any>) => {
	const { name } = inputRef.current // avoid destructuring value here since you're using an updated version at the end of this function
	const { min } = numberFieldValidators[name]

	if (inputRef.current.value === '') {
		inputRef.current.value = inputRef.current.defaultValue
		inputRef.current.prev = inputRef.current.defaultValue
	} else if (inputRef.current.value < min) {
		inputRef.current.value = min
		inputRef.current.prev = min
	}
}

export const validateTextBlurredInput = (inputRef: MutableRefObject<any>) => {}
