const imgFileNameLookup = [
	'autumn',
	'cranes',
	'earth',
	'fabric',
	'gold',
	'goldfish',
	'landscape',
	'map',
	'marble',
	'meadow',
	'meteors',
	'mushrooms',
	'nebula',
	'neptune',
	'pink-sky',
	'snow',
	'swamp',
	'water',
	'willow'
]

export const getRandomCustomBookCoverName = () => {
	return imgFileNameLookup[Math.floor(Math.random() * imgFileNameLookup.length)]
}

export const getAllCustomBookCoverNames = () => {
	return imgFileNameLookup
}
