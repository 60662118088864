import { setUser, setTag, captureException, captureMessage, SeverityLevel } from '@sentry/nextjs'
import { devErr, devLog } from './utilFunctions'
// QUIRK: Scope is set in UserContext -- don't set it here

export type SetErrorCb = (error: string) => void

// SECTION: Redirect to Error Page -- only called during getServerSideProps
// NOTE: You have to return the function when you call it in ssp
export const redirectToErrorPage = (code: number) => {
	return {
		redirect: {
			permanent: false,
			destination: `/error?code=${code}`
		}
	}
}

// SECTION: Error occurred in Next Api or getServerSideProps
// NOTE: If error comes from Next API, pass the resulting error obj to handleErrorOnFrontEnd.
// NOTE: If error comes from getServerSideProps, public messaging is all handled there
export const handleErrorOnBackend = async (err: any, email: string | undefined | null) => {
	devErr(err.response?.data || err)
	setUser({ email: email || 'No Session' })
	setTag('email', email || '') // columns created for each tag in events view
	// setTag('userAccountType', userAccountType) // QUIRK: Really difficult to get on backend-- just fetch as part of your observer app
	captureException(err)
}

// SECTION: Front-end exception handlers
// NOTE: For silent errors, pass in defaultErrMessage as '' and errorCallback as () => {} -- they will go to handleCustomPrivateAnd500Errors and alert Sentry without giving users an error message
export const handleErrorOnFrontend = (err: any, defaultErrMessage: string, errorCallback: Function) => {
	devLog('Error Response Object...')
	devErr(err.response)

	if (err?.response?.status === 429) {
		handleRequestLimitError(err, errorCallback)
	} else if (err?.response) {
		handleNextApiOrConverterApiError(err, defaultErrMessage, errorCallback)
	} else {
		handleFrontendPrivateOr500Error(err, defaultErrMessage, errorCallback)
	}
}

// SECTION: Error was triggered by request limiter
// NOTE: err.response.data automatically populated with error message
// NOTE: 429 errors are sent to sentry from next back-end or converter api before getting here
const handleRequestLimitError = (err: any, errorCallback: Function) => {
	errorCallback(err.response.data)
}

// SECTION: Error occurred in Next Backend or Converter API
// NOTE: For Converter API -- 500 errors sent to sentry -- 400 errors not sent to sentry (from converter)
const handleNextApiOrConverterApiError = (err: any, defaultErrMessage: string, errorCallback: Function) => {
	const publicErrMessage = err.response.data?.publicErrMessage // exists if non-default (400) error returned from converter
	const sendToSentry = err.response.data?.sendToSentry

	devLog('SEND TO SENTRY?')
	devLog([publicErrMessage, sendToSentry])

	if (sendToSentry) {
		captureException(err)
		devErr('SENT TO SENTRY 1: ' + err)
	} else {
		devErr('NOT SENDING OR ALREADY SENT TO SENTRY: ' + err)
	}

	errorCallback(publicErrMessage ? publicErrMessage : defaultErrMessage)
}

// SECTION: Error occurred on Front-end, not during api call (ie. idb error) -- send to sentry -- display optional message
// NOTE: Good for when you want to silently send something to Sentry -- defaultErrMessage as '' and errorCallback as () => {}
// QUIRK: Remember: You don't need to manually add the user's email to frontend errors -- that's already handled in UtilContext
export const handleFrontendPrivateOr500Error = (err: any, defaultErrMessage: string, errorCallback: Function) => {
	if (err) captureException(err) // NOTE: Only send to sentry if an error exists (if omitted, it's likely a simple user error ())
	devErr('SENT TO SENTRY 2: ' + err)

	errorCallback(defaultErrMessage)
}

// SECTION: Critical Error Message
export const sendErrorMessageToSentry = (
	message: string,
	severity: SeverityLevel,
	email: string | null | undefined
) => {
	captureMessage(message, { level: severity, user: { email: email || '' } })
}
