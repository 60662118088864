export const classicFontNames = ['Literata', 'Hyperlegible', 'OpenDyslexic']
export const premiumFontNames = [
	'Alice',
	'Bitter',
	'ComicSans',
	'ConcertOne',
	'Courgette',
	'CourierPrime',
	'CrimsonPro',
	'DancingScript',
	'FiraCode',
	'HelveticaNeue',
	'Jost',
	'Kanit',
	'LibreBaskerville',
	'Lora',
	'Metropolis',
	'Munro',
	'Philosopher',
	'PlayfairDisplay',
	'Satisfy',
	'SpecialElite'
]

export const classicFontLabels = ['Literata', 'Hyperlegible', 'Open Dyslexic']
export const premiumFontLabels = [
	'Alice',
	'Bitter',
	'Comic Sans',
	'Concert One',
	'Courgette',
	'Courier Prime',
	'Crimson Pro',
	'Dancing Script',
	'Fira Code',
	'Helvetica Neue',
	'Jost',
	'Kanit',
	'Libre Baskerville',
	'Lora',
	'Metropolis',
	'Munro',
	'Philosopher',
	'Playfair Display',
	'Satisfy',
	'Special Elite'
]
