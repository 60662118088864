import { createElement } from 'react'

// SECTION: For debugging logs
// NOTE: Same as console.log, but only works in development mode
// QUIRK: React flags these in jsx if they return void -- so returning null instead
const devLogEnabled = true
const devErrEnabled = true
const _useEffectLogEnabled = false // QUIRK: disable reactStrictMode when testing this and renderLog -- it causes components to render twice (only in dev -- not prod)
const renderLogEnabled = false
const dbLogEnabled = false
const apiLogEnabled = false

export const devLog = (loggableValue: any) => {
	if (isReactInDevelopmentMode() && devLogEnabled) {
		console.log(loggableValue)
	}
	return null
}

export const devErr = (loggableValue: any) => {
	if (isReactInDevelopmentMode() && devErrEnabled) {
		console.error(loggableValue)
	}
	return null
}

// For tracking useEffects
// NOTE: Underscore because linting will think this is a hook since it starts with "use"
export const _useEffectLog = (loggableValue: any) => {
	if (isReactInDevelopmentMode() && _useEffectLogEnabled) {
		console.log(`%c${loggableValue}`, 'color: #00ad05')
	}
	return null
}

// For tracking rerenders
export const renderLog = (loggableValue: any) => {
	if (isReactInDevelopmentMode() && renderLogEnabled) {
		console.log(`%c${loggableValue}`, 'color: #4287f5') // Render logs blue in browser / white in console
	}
	return null
}

// For trackinig db and idb access (db only appears in vscode / idb only appears in browser)
// NOTE: 33 => yellow -- This format allows for colored logs in vsCode console. Some numbers don't look good in browser (ie, the 34 for blue)
export const dbLog = (loggableValue: any) => {
	if (isReactInDevelopmentMode() && dbLogEnabled) {
		console.log('\u001b[' + 33 + 'm' + loggableValue + '\u001b[0m')
	}
	return null
}

// For tracking requests to any external api (textConverter, stripe, etc)
export const apiLog = (loggableValue: any) => {
	if (isReactInDevelopmentMode() && apiLogEnabled) {
		console.log('\u001b[' + 35 + 'm' + loggableValue + '\u001b[0m') // 35 => purple
	}
	return null
}

// SECTION: Redirects or reloads page if destination is current url (error page redirect in errorHandlers.ts)
// NOTE: Return value of this function needs to itself be returned in getServerSideProps to work
export const redirectFromGetServerSideProps = (destination: string) => {
	return {
		redirect: {
			permanent: false,
			destination
		}
	}
}

// SECTION: Ensures https -- if http, user sent back to root page (unless on localhost)
export const verifyUrl = () => {
	if (windowExists() && location.protocol !== 'https:' && getBaseUrl() !== 'http://localhost:3000') {
		window.location.href = 'https://' + location.hostname + (location.port ? ':' + location.port : '')
	}
}

// SECTION: Returns the current domain
// For use on front-end
export const getBaseUrl = () => {
	return process.env.NEXT_PUBLIC_BASE_URL || ''
}

// For use on back-end
// Use instead of getBaseUrl above because NEXT_PUBLIC_BASE_URL is public.
// NOTE: Used for redirects -- use ProdDomain checkers below if you want to check environments
// QUIRK: req.get('Host') returns typelit.herokuapp.com if run on true typelit.io domain
export const getServerBaseUrl = (req: any) => {
	return req.protocol + '://' + `${isTrueProdDomain() ? process.env.PROD_DOMAIN : req.get('Host')}`
}

// To get the Text Converter Domain
export const getTextConverterBaseUrl = () => {
	return isReactInDevelopmentMode() ? 'http://localhost:3001' : 'https://typelit-text-converter.herokuapp.com'
}

// SECTION: Other path getters
export const getCurrentPagePathName = () => {
	return windowExists() ? location.pathname : ''
}

// SECTION: Checks if the environment is development mode
// NOTE: Development only refers to localhost -- if it's on a server, it's considered production
export const isReactInDevelopmentMode = () => {
	return '_self' in createElement('div')
}

export const isNodeInDevelopmentMode = () => {
	return process.env.NODE_ENV === 'development'
}

// SECTION: Checks current environment is live production
export const isTrueProdDomain = () => {
	return process.env.PROD_DOMAIN === 'www.typelit.io'
}

// Section: Checks if current environment is test environment
export const isTestDomain = (req: any) => {
	return req.get('Host') === 'typelit-test-env-2.herokuapp.com'
}

// SECTION: Checks if window exists (it won't if its just on the server)
export const windowExists = () => {
	return typeof window !== 'undefined'
}

// SECTION: Gets device window width or height. Users outer values if on Samsung Internet
// NOTE: Used by UserUtilContext to track screen size resizing
// NOTE: Can be used outside of React context and will return current window size
export const getWindowWidth = () => {
	return windowExists() ? window.innerWidth : 0
}

export const getWindowHeight = () => {
	return windowExists() ? window.innerHeight : 0
}

// SECTION: Wrapper to check if localStorage is accessable. Returns localStorage if so
export const getLocalStorage = () => {
	try {
		const key = 'typelit-storagetest'
		window.localStorage.setItem(key, 'true')
		window.localStorage.removeItem(key)
		return window.localStorage
	} catch (err) {
		devErr(err)
		return null
	}
}
