import { useEffect } from 'react'
import { AppProps } from 'next/app'
import { devLog, isNodeInDevelopmentMode, _useEffectLog, windowExists, getLocalStorage } from '../util/utilFunctions'
import { getAnalytics } from '../util/getAnalytics'
import { useRouter } from 'next/router'
import { SessionProvider } from 'next-auth/react'
import { globalMuiTheme, globalStyles } from '../globalTheme'
import { ThemeProvider, CssBaseline, GlobalStyles } from '@mui/material'
import 'react-simple-keyboard/build/css/index.css'
import HeaderBar from '../components/sharedComponents/HeaderBar'
import CookiesBanner from '../components/sharedComponents/CookiesBanner'
import SignInSuccessAlert from '../components/sharedComponents/alerts/SignInSuccessAlert'
import ErrorAlert from '../components/sharedComponents/alerts/ErrorAlert'
import { CoreContextProviders } from '../contexts/CoreContextProviders'
import { isSiteAccessable } from '../util/featureFlags'
import MaintenancePage from '../components/MaintenancePage/MaintenancePage'
import { createEmotionSsrAdvancedApproach } from 'tss-react/next'
import { Session } from 'next-auth'
import React from 'react'
import '../util/fonts/baseFontLoader.css' // Must be imported here
import '../util/fonts/consoleFontLoader.css' // Must be imported here
import '../util/fonts/consoleFontSubsetLoader.css' // Must be imported here

// SECTION: use useEffect instead of useLayoutEffect when on server
if (!windowExists()) {
	React.useLayoutEffect = React.useEffect
}

// SECTION: withEmotionCache -- used in _document.tsx
const { augmentDocumentWithEmotionCache, withAppEmotionCache } = createEmotionSsrAdvancedApproach({
	key: 'css',
	prepend: true
})
export { augmentDocumentWithEmotionCache }

function MyApp({ Component, pageProps }: AppProps<{ session: Session }>) {
	// SECTION: Hooks
	const router = useRouter()

	// SECTION: Enable 3rd Party libraries
	// USE EFFECT:: Enables Google Analytics to track client-side page changes using the react-ga package. Companion hook in utilFunctions.tsx
	// NOTE: setAnalyticsAllowed triggered from CookiesBanner if user rejects anylytics
	useEffect(() => {
		_useEffectLog('_app useEffect 0')
		setTimeout(() => {
			const devTestAnalytics = false

			if (!isNodeInDevelopmentMode() || devTestAnalytics) {
				const analyticsAllowed = getLocalStorage()?.getItem(`typelit-allowanalytics`) || 'false'

				if (analyticsAllowed !== 'false') {
					// Set up analytics
					const { init, trackPageViewed } = getAnalytics()

					// init('UA-174563745-1') // older GA3 property
					init('G-SEEEHZ84FX')

					// Initial visit (from offsite) or page is refeshed
					trackPageViewed()

					// Log page on each page change
					router.events.on('routeChangeComplete', () => {
						trackPageViewed()
					})
				}
			}
		}, 2000) // Defer GA to load after page content
	}, [])

	// SECTION: Render UI
	// NOTE: A provider must be nested within a parent provider to access the parent context's functionality -- this determines the nesting order here
	return (
		<ThemeProvider theme={globalMuiTheme}>
			<CssBaseline />
			<GlobalStyles styles={globalStyles} />
			{isSiteAccessable ? (
				<SessionProvider session={pageProps.session}>
					<CoreContextProviders>
						<HeaderBar />
						<SignInSuccessAlert />
						<ErrorAlert />
						<Component {...pageProps} />
						<CookiesBanner />
					</CoreContextProviders>
				</SessionProvider>
			) : (
				<MaintenancePage />
			)}
		</ThemeProvider>
	)
}

export default withAppEmotionCache(MyApp)
