// ABOUT: Disable parts of the site you don't want shown to the public.
// ABOUT: Can override for yourself by setting local storage value below (front-end logic only)

import { devLog, getLocalStorage, windowExists } from './utilFunctions'

// SECTION: Validators -- Show disabled features under certain circumstances
const validateFlag = (value: string | undefined) => {
	if (
		value !== 'false' || // As long as this value isn't explicitly false, allow access
		(windowExists() && getLocalStorage()?.getItem('typelit-enableallfeatures') === 'true') // Override disabled feature for yourself only
	) {
		return true
	} else {
		return false
	}
}

// SECTION: Flags
export const isSiteAccessable = validateFlag(process.env.NEXT_PUBLIC_FLAG_IS_SITE_ACCESSABLE)
export const isSubscribeAccessable = validateFlag(process.env.NEXT_PUBLIC_FLAG_IS_SUBSCRIBE_ACCESSABLE)
export const isImportAndFormatAccessable = validateFlag(process.env.NEXT_PUBLIC_FLAG_IS_IMPORT_AND_FORMAT_ACCESSABLE)
